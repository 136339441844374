import React from 'react';

export const SvgRocketOff: React.FC<{width?: string, height?: string, fill?: string}> = ({ width = 24, height = 24, fill = 'none' }) => {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill={fill} xmlns="http://www.w3.org/2000/svg">
      <g id="rocket-off" clipPath="url(#clip0_674_10663)">
        <path
          id="Vector"
          d="M7.74183 7.72908C7.6526 7.92689 7.57197 8.12847 7.50016 8.33325C6.65038 8.36132 5.82179
         8.60562 5.09272 9.04306C4.36364 9.48051 3.75815 10.0967 3.3335 10.8333C4.81941 11.0098 6.2027 11.6812 7.26079
          12.7393C8.31888 13.7974 8.99024 15.1807 9.16683 16.6666C9.90343 16.2419 10.5196 15.6364 10.957 14.9074C11.3945
           14.1783 11.6388 13.3497 11.6668 12.4999C11.8677 12.4291 12.0652 12.3499 12.2568 12.2641M14.2802 10.9224C15.7054
            9.59957 16.5611 7.77489 16.6668 5.83325C16.6668 5.17021 16.4034 4.53433 15.9346 4.06549C15.4658 3.59664 14.8299
             3.33325 14.1668 3.33325C12.2252 3.43899 10.4005 4.29471 9.07766 5.71992"
          stroke="#6C59E6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M5.83341 11.6667C4.9661 12.1564 4.26479 12.8939 3.81937 13.7847C3.37396 14.6756 3.20474 15.6791
         3.33341 16.6667C4.32104 16.7954 5.3246 16.6262 6.21543 16.1808C7.10626 15.7354 7.84377 15.0341 8.33341 14.1667"
          stroke="#6C59E6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M11.6665 7.50008C11.6665 7.7211 11.7543 7.93306 11.9106 8.08934C12.0669 8.24562 12.2788 8.33341
         12.4998 8.33341C12.7209 8.33341 12.9328 8.24562 13.0891 8.08934C13.2454 7.93306 13.3332 7.7211 13.3332 7.50008C13.3332
          7.27907 13.2454 7.06711 13.0891 6.91083C12.9328 6.75455 12.7209 6.66675 12.4998 6.66675C12.2788 6.66675 12.0669 6.75455
           11.9106 6.91083C11.7543 7.06711 11.6665 7.27907 11.6665 7.50008Z"
          stroke="#6C59E6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path id="Vector_4" d="M2.5 2.5L17.5 17.5" stroke="#6C59E6" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_674_10663">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>

  );
};
