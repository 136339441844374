import React from 'react';

export const SvgRocket: React.FC<{width?: string, height?: string, fill?: string}> = ({ width = 24, height = 24, fill = 'none' }) => {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill={fill} xmlns="http://www.w3.org/2000/svg">
      <rect width={width} height={height} fill="#F5F5F5" />
      <g id="Projects tile/ Description closed" clipPath="url(#clip0_670_3721)">
        <rect width="1440" height="3388" transform="translate(-1334 -255)" fill="white" />
        <g id="Frame 1" clipPath="url(#clip1_670_3721)">
          <g id="Frame 847">
            <g id="Frame 845">
              <g id="Frame 841">
                <g id="Frame 839">
                  <g id="Frame 838">
                    <g id="Frame 538">
                      <g id="Single Tabs">
                        <mask id="path-2-inside-1_670_3721" fill="white">
                          <path d="M-1098 -10H88V30H-1098V-10Z" />
                        </mask>
                        <path d="M-1098 -10H88V30H-1098V-10Z" fill="#F9E8FF" />
                        <path d="M-1098 -8H88V-12H-1098V-8Z" fill="#6C59E6" mask="url(#path-2-inside-1_670_3721)" />
                        <g id="Frame 527">
                          <g id="Frame 568">
                            <g id="Frame 568_2">
                              <g id="rocket" clipPath="url(#clip2_670_3721)">
                                <path
                                  id="Vector"
                                  d="M3.3335 10.8333C4.81941 11.0099 6.2027 11.6813 7.26079 12.7393C8.31888
                                 13.7974 8.99024 15.1807 9.16683 16.6666C9.90343 16.242 10.5196 15.6365 10.957 14.9074C11.3945
                                  14.1783 11.6388 13.3498 11.6668 12.5C13.0661 12.0077 14.288 11.1116 15.178 9.92499C16.0679 8.73834
                                   16.5861 7.31443 16.6668 5.83331C16.6668 5.17027 16.4034 4.53439 15.9346 4.06555C15.4658 3.59671
                                    14.8299 3.33331 14.1668 3.33331C12.6857 3.41407 11.2618 3.9322 10.0752 4.82219C8.8885 5.71218
                                     7.9924 6.93405 7.50016 8.33331C6.65038 8.36138 5.82179 8.60568 5.09272 9.04312C4.36364
                                     9.48057 3.75815 10.0967 3.3335 10.8333Z"
                                  stroke="#6C59E6"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  id="Vector_2"
                                  d="M5.83341 11.6667C4.9661 12.1563 4.26479 12.8938 3.81937 13.7847C3.37396
                                 14.6755 3.20474 15.6791 3.33341 16.6667C4.32104 16.7954 5.3246 16.6261 6.21543 16.1807C7.10626
                                  15.7353 7.84377 15.034 8.33341 14.1667"
                                  stroke="#6C59E6"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  id="Vector_3"
                                  d="M11.6665 7.50002C11.6665 7.72103 11.7543 7.933 11.9106 8.08928C12.0669
                                 8.24556 12.2788 8.33335 12.4998 8.33335C12.7209 8.33335 12.9328 8.24556 13.0891 8.08928C13.2454
                                  7.933 13.3332 7.72103 13.3332 7.50002C13.3332 7.27901 13.2454 7.06705 13.0891 6.91076C12.9328
                                   6.75448 12.7209 6.66669 12.4998 6.66669C12.2788 6.66669 12.0669 6.75448 11.9106 6.91076C11.7543
                                    7.06705 11.6665 7.27901 11.6665 7.50002Z"
                                  stroke="#6C59E6"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_670_3721">
          <rect width="1440" height="3388" fill="white" transform="translate(-1334 -255)" />
        </clipPath>
        <clipPath id="clip1_670_3721">
          <rect width="1184" height="6793" fill="white" transform="translate(-1098 -255)" />
        </clipPath>
        <clipPath id="clip2_670_3721">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>

  );
};
