import React from 'react';

import { IPlacementInfo } from 'src/components/ListOfLayoutApartments/types';
import { IFilterKeyInfo, ListOfKeysFilterAllData } from 'src/typings/filter';
import { useBuildType, UseNumberFormat, useTranslations } from 'src/hooks';
import { useGenerateShowingInfo } from '../../hooks';
import { useStyles } from './styles';

export const InfoKey: React.FC<IPlacementInfo> = ({
  itemData,
  filterConfigurator,
  displayedType,
}) => {
  const styles = useStyles({});
  const { getTranslations } = useTranslations();
  const renderItems: { [key: string]: IFilterKeyInfo | any } = useGenerateShowingInfo(filterConfigurator[displayedType]);
  const { numberSeparator, replaceFraction } = UseNumberFormat();
  const { isCrossTable } = useBuildType();
  const prices = [
    ListOfKeysFilterAllData.placementPricePerSqM,
    ListOfKeysFilterAllData.placementPricePerSqMAlternative,
    ListOfKeysFilterAllData.placementTotalPrice,
    ListOfKeysFilterAllData.placementTotalPriceAlternative,
  ];

  return (
    <div className={styles.apartmentKeyInfo}>
      {Object.keys(renderItems).map((renderItem) => {
        const renderData = renderItems[renderItem];
        const placementData = itemData[renderItem];

        if (!isCrossTable && itemData[ListOfKeysFilterAllData.placementStatusShowInModule] === 'hide' && prices.includes(renderItem as ListOfKeysFilterAllData)) {
          return (<></>);
        }
        if (itemData[renderItem]) {
          return (
            <div
              key={renderItem}
              className={styles.keyInfo}
            >
              {`${renderData['value-prefix'] ? renderData['value-prefix'] : ''} `}
              {renderData['use-name'] && `${getTranslations(renderItem)}:`}
              {` ${numberSeparator(replaceFraction(getTranslations(placementData)), renderItem)}`}
              {`${renderData['value-sufix'] ? renderData['value-sufix'] : ''}`}
            </div>
          );
        }

        return null;
      })}
    </div>
  );
};
