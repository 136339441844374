import { Provider } from 'react-redux';
import React, { lazy } from 'react';
import {
  QueryClientProvider,
} from '@tanstack/react-query';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { store } from 'src/store';
import { useBuildType } from 'src/hooks';
import { queryClient } from 'src/lib/queryClient';

const AppThemeLazy = lazy(async () => import('./App-theme'));
const AppThemeLazyMarketplace = lazy(() => import('./App-marketplace-theme'));

export const App: React.FC = () => {
  const { isMarketplace } = useBuildType();
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <React.Suspense fallback={<></>}>
          {!isMarketplace && (
          <AppThemeLazy />
          )}
          {isMarketplace && (
            <AppThemeLazyMarketplace />
          )}
        </React.Suspense>
        {/* <ReactQueryDevtools initialIsOpen /> */}
      </QueryClientProvider>
    </Provider>
  );
};
