import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import Slider from '@mui/material/Slider';
import { FilterActions } from 'src/components/Marketplace/components/FilterActions';
import Box from '@mui/material/Box';
import {
  IMarketplaceFilterRange,
} from 'src/components/profileComponents/Marketplace/types';
import { useMarketPlaceFilter } from 'src/components/Marketplace/hooks';
import _ from 'lodash';
import _debounce from 'lodash/debounce';
import { IMarketplaceFilterResponseValue } from 'src/typings/marketplace';
import { getMinAndMaxValues } from 'src/components/Marketplace/helpers';
import { useStyles } from './styles';

interface Props {
  isPopupOpen: null | boolean,
  selection?: IMarketplaceFilterRange,
  setIsPopupOpen: (value: null | boolean) => void,
  setValue?: (value: number[]) => void,
  closePopup?: () => void,
  labelFrom: string,
  labelTo: string,
  currencyEnabled?: boolean,
  currencyOptions?: string[],
  customClass?: string
  showActions?: boolean,
}
export const PriceFilterPopup: React.FC<Props> = ({
  selection,
  setValue,
  isPopupOpen,
  setIsPopupOpen,
  showActions = true,
  customClass,
  closePopup,
  labelFrom = 'від',
  labelTo = 'до',
  currencyEnabled = false,
  currencyOptions = ['грн', '$'],
}) => {
  const classes = useStyles();
  const popupRef = useRef(null);
  const [currency, setCurrency] = useState<string>(currencyOptions[0]);
  const {
    getMarketplaceInitialFilter,
    marketplaceInitialFilters,
    getFilterRangeActiveData,
    setWhereConditions,
    resetFilterWhereConditions,
    getMarketplaceFilter,
    marketplaceFilters,
  } = useMarketPlaceFilter();
  const [minValue, setMinValue] = useState<number | undefined>();
  const [maxValue, setMaxValue] = useState<number | undefined>();
  const [currentValue, setCurrentValue] = useState<number[]>([0, 0]);

  useEffect(() => {
    if (selection?.property?.systemName) {
      changeHandler(getMarketplaceInitialFilter(selection.property.systemName)?.values, true);
    }
  }, [selection, marketplaceInitialFilters]);

  useEffect(() => {
    if (selection?.property?.systemName) {
      changeHandler(getMarketplaceFilter(selection.property.systemName)?.values);
    }
  }, [selection, marketplaceFilters]);

  const changeHandler = (values: IMarketplaceFilterResponseValue[] | undefined, updateMinMax?: boolean) => {
    if (selection?.property?.systemName && values) {
      const { min, max } = getMinAndMaxValues(values);
      if (![Infinity, -Infinity].includes(min) && ![Infinity, -Infinity].includes(max)) {
        if (updateMinMax) {
          setMinValue(min);
          setMaxValue(max);
        }
        if (min !== undefined && max !== undefined) {
          setCurrentValue(getFilterRangeActiveData(selection?.property?.systemName) || [min, max]);
          if (setValue) {
            setValue([min, max]);
          }
        }
      }
    }
  };

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setCurrentValue(Array.isArray(newValue) ? newValue : [newValue]);
  };

  const handleSliderCommitted = (event: any, newValue: number | number[]) => {
    if (selection?.property?.systemName) {
      const _min = Number(_.isNumber(newValue) ? newValue : newValue[0]);
      const _max = Number(_.isNumber(newValue) ? newValue : newValue[1]);
      setWhereConditions([{
        propertyName: selection?.property?.systemName,
        oldSystemName: selection?.property.oldSystemName,
        min: _min,
        max: _max,
      }], selection.id);
      if (setValue) {
        setValue([_min, _max]);
      }
    }
  };

  const handleSliderCommittedDebounce = useCallback(_debounce(handleSliderCommitted, 1000), []);

  const handleCurrencyChange = (newCurrency: string) => {
    setCurrency(newCurrency);
  };

  const handleReset = () => {
    if (selection?.property?.systemName) {
      resetFilterWhereConditions([selection?.property.systemName]);
      if (minValue !== undefined && maxValue !== undefined) setCurrentValue([minValue, maxValue]);
    }
  };

  if (!isPopupOpen) {
    return null;
  }

  return (
    <Box
      className={`${classes.popup} ${customClass || ''}`}
      ref={popupRef}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={classes.header}>
        <div className={classes.sliderContainer}>
          <Slider
            value={currentValue}
            onChange={(event, newValue) => handleSliderChange(event, newValue)}
            onChangeCommitted={(event, newValue) => handleSliderCommitted(event, newValue)}
            valueLabelDisplay="auto"
            min={minValue}
            max={maxValue}
            className={classes.slider}
          />
        </div>

        {/* Добавляем переключатель валют */}
        {currencyEnabled && (
          <div className={classes.currencyToggle}>
            {currencyOptions.map((cur: string) => (
              <button
                key={cur}
                className={`${classes.currencyButton} ${
                  currency === cur
                    ? classes.activeCurrency
                    : ''
                }`}
                onClick={() => handleCurrencyChange(cur)}
                type="button"
              >
                {cur}
              </button>
            ))}
          </div>
        )}
      </div>

      <div className={classes.rangeWrapper}>
        <div className={classes.rangeItem}>
          <label htmlFor="minValue" className={classes.label}>
            {labelFrom}
          </label>
          <input
            id="minValue"
            type="text"
            className={classes.input}
            value={currentValue[0]}
            min={minValue}
            max={maxValue}
            onChange={(event) => {
              const newValue = [...currentValue];
              newValue[0] = parseInt(
                event.target.value.replace(/\D/g, ''),
                10,
              ) || 0;
              const { min, max } = event.target;
              newValue[0] = Math.max(Number(min), Math.min(Number(max), Number(newValue[0])));
              setCurrentValue(newValue);
              handleSliderCommittedDebounce(event, newValue);
            }}
          />
          {/* <span className={classes.unit}>{currency}</span> */}
        </div>
        <div className={classes.rangeItem}>
          <label htmlFor="maxValue" className={classes.label}>
            {labelTo}
          </label>
          <input
            id="maxValue"
            type="text"
            className={classes.input}
            value={currentValue[1]}
            min={minValue}
            max={maxValue}
            onChange={(event) => {
              const newValue = [...currentValue];
              newValue[1] = parseInt(
                event.target.value.replace(/\D/g, ''),
                10,
              ) || 0;
              const { min, max } = event.target;
              newValue[1] = Math.max(Number(min), Math.min(Number(max), Number(newValue[1])));
              setCurrentValue(newValue);
              handleSliderCommittedDebounce(event, newValue);
            }}
          />
          {/* <span className={classes.unit}>{currency}</span> */}
        </div>
      </div>

      {showActions && (
        <FilterActions
          onReset={handleReset}
          onApply={() => {
            handleSliderCommitted(null, currentValue);
            setIsPopupOpen(null);
            if (closePopup) closePopup();
          }}
        />
      )}
    </Box>
  );
};
