import { useQuery } from '@tanstack/react-query';
import { useSelector, useDispatch } from 'react-redux';
import { getComplex } from 'src/store/crosstable/selectors';
import { Actions } from 'src/store/crosstable/actions';
import { getComplexData } from 'src/api';
import { useGetAuthData } from 'src/hooks/use-get-auth-data';
import { IComplex } from '../typings/complexes';

export const useGetComplex = (id: string|undefined|null, saveToStore?: boolean) => {
  const savedComplex: IComplex|null = useSelector(getComplex);
  const dispatch = useDispatch();
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();

  const { data: complex, isLoading, refetch: fetchComplex } = useQuery({
    queryKey: ['complexes', id],
    queryFn: async (): Promise<IComplex | null> => {
      const response = await getComplexData(jwt, id!);
      const complexData = response.data;
      if (saveToStore === undefined || saveToStore) {
        dispatch(Actions.setComplex(complexData));
      }
      return response.data;
    },
    enabled: !!id && (!savedComplex || Number(id) !== Number(savedComplex.id)),
    initialData: savedComplex && Number(id) === Number(savedComplex.id)
      ? savedComplex
      : undefined,
    staleTime: 1000 * 60 * 5,
    cacheTime: 1000 * 60 * 10,
  });

  return {
    isLoading,
    complex: complex ?? null,
    fetchComplex,
  };
};
