import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import Box from '@mui/material/Box';
import { FilterActions } from 'src/components/Marketplace/components/FilterActions';
import {
  IMarketplaceFilterCheckboxAllValues,
  IMarketplaceFilterType,
} from 'src/components/profileComponents/Marketplace/types';
import { IMarketplaceFilterResponseValue } from 'src/typings/marketplace';
import { useMarketPlaceFilter } from 'src/components/Marketplace/hooks';
import classnames from 'classnames';
import { Actions } from 'src/store/marketplace/actions';
import { useDispatch } from 'react-redux';
import { useStyles } from './styles';

interface Props {
  selection?: IMarketplaceFilterCheckboxAllValues,
  // isPopupOpen: boolean,
  // setIsPopupOpen?: (value: boolean) => void,
  // selectedRooms: string[],
  setActiveRooms?: (value: any) => void,
  closePopup?: () => void,
  customClass?: string
  showActions?: boolean,
  onApply?: () => void,
  renderType?: 'checkbox' | 'button',
}

export const RoomsFilterPopup: React.FC<Props> = ({
  // isPopupOpen,
  selection,
  // setIsPopupOpen,
  // selectedRooms,
  setActiveRooms,
  onApply,
  closePopup,
  customClass,
  showActions = true,
  renderType = 'checkbox',
}) => {
  const styles = useStyles();
  const popupRef = useRef(null);
  const {
    marketplaceFilters,
    marketplaceInitialFilters,
    getMarketplaceInitialFilter,
    setWhereConditions,
    getFilterEqualsActiveData,
    resetFilterWhereConditions,
    getMarketplaceFilter,
    isActiveFilter,
  } = useMarketPlaceFilter();
  const [initialOptions, setInitialOptions] = useState<IMarketplaceFilterResponseValue[]>([]);
  const [availableOptions, setAvailableOptions] = useState<IMarketplaceFilterResponseValue[]>([]);
  const [selectedRooms, setSelectedRooms] = useState<string[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selection?.property?.systemName) {
      const value = getMarketplaceInitialFilter(selection.property.systemName)?.values || [];
      setInitialOptions(value);
      setAvailableOptions(value);
    }
  }, [selection, marketplaceInitialFilters]);

  useEffect(() => {
    if (selection?.property?.systemName) {
      const value = getFilterEqualsActiveData(selection?.property?.systemName);
      const filteredData = getMarketplaceFilter(selection.property.systemName)?.values;
      if (filteredData) {
        setAvailableOptions(filteredData);
      }
      setSelectedRooms(value);
      if (setActiveRooms) {
        setActiveRooms(value);
      }
    }
  }, [selection, marketplaceFilters]);

  useEffect(() => {
    return () => {
      dispatch(Actions.setMarketplaceActiveFilter(undefined));
    };
  }, []);

  const isDisabled = useCallback((value: IMarketplaceFilterResponseValue): boolean => {
    if (!initialOptions?.length && !availableOptions?.length) {
      return false;
    }
    return !availableOptions?.filter((option) => option.value === value.value).length;
  }, [selection, initialOptions, availableOptions]);

  const getUnitCount = useCallback((value: IMarketplaceFilterResponseValue): number => {
    const count = marketplaceFilters?.find((filterItem) => filterItem.propertyName === selection?.property?.systemName)?.values
      .find((val) => val.value === value.value);
    return isDisabled(value) ? 0 : (count?.unitCount || value.unitCount);
  }, [initialOptions, availableOptions]);

  const toggleRoomSelection = (room: IMarketplaceFilterResponseValue) => {
    if (selection?.property?.systemName) {
      setWhereConditions([{
        propertyName: selection?.property?.systemName,
        oldSystemName: selection?.property.oldSystemName,
        equals: room.value,
      }], selection.id);
    }
  };

  const handleReset = () => {
    if (selection?.property?.systemName) {
      if (setActiveRooms) {
        setActiveRooms([]);
      }
      resetFilterWhereConditions([selection?.property.systemName]);
    }
  };

  return (

    <Box
      className={`${styles.popup} ${customClass || ''}`}
      ref={popupRef}
      onClick={(e) => e.stopPropagation()}
    >
      <div
        className={`${styles.content} ${
          renderType === 'button'
            ? styles.contentWithButtons
            : ''
        }`}
      >
        {initialOptions && initialOptions.map((room) => (
          <div key={room.value} className={styles.checkboxItem}>
            {renderType === 'checkbox' ? (
              <>
                <input
                  type="checkbox"
                  id={room.value}
                  className={classnames('lCheckbox', { disabled: isDisabled(room) })}
                  checked={selectedRooms?.includes(room.value)}
                  onChange={() => toggleRoomSelection(room)}
                />
                <label htmlFor={room.value}>
                  { room.value }
                </label>
                <span className={styles.counter}>
                  (
                  { getUnitCount(room) }
                  )
                </span>
              </>
            ) : (
              <button
                type="button"
                className={classnames('lBtn', { active: selectedRooms?.includes(room.value) }, { disabled: isDisabled(room) })}
                onClick={() => toggleRoomSelection(room)}
              >
                { room.value }
                <span className={styles.counter}>
                  (
                  { getUnitCount(room) }
                  )
                </span>
              </button>
            ) }
          </div>
        )) }
      </div>

      { showActions && (
        <FilterActions
          onReset={handleReset}
          onApply={() => {
            if (onApply) onApply();
            if (closePopup) closePopup();
          }}
        />
      )}
    </Box>
  );
};
