import React, { useState, useRef, useMemo } from 'react';
import { NavigationTabs } from 'src/components/Marketplace/components/NavigationTabs';
import { CollapsibleFilter } from 'src/components/Marketplace/components/MarketplaceFilterOptions/CollapsibleFilter';
import Box from '@mui/material/Box';
import { FilterActions } from 'src/components/Marketplace/components/FilterActions';
import { LocationFilterPopup } from 'src/components/Marketplace/components/LocationFilter/LocationFilterPopup';
import { TypeFilterPopup } from 'src/components/Marketplace/components/TypeFilter/TypeFilterPopup';
import { PriceFilterPopup } from 'src/components/Marketplace/components/PriceFilter/PriceFilterPopup';
import { RoomsFilterPopup } from 'src/components/Marketplace/components/RoomsFilter/RoomsFilterPopup';
import { FilterOptionSlider } from 'src/components/Marketplace/components/MarketplaceFilterOptions/FilterOptionSlider';
import {
  IMarketplaceFilterCheckboxAllValues,
  IMarketplaceFilterLocation, IMarketplaceFilterRange,
  IMarketplaceFilterTagAllValues, IMarketplaceFilterTagSelectedValues,
  IMarketplaceFilterTagSelectedValueSelectedProperty, IMarketplaceFilterType, IViewTileType,
} from 'src/components/profileComponents/Marketplace/types';
import { useSelector } from 'react-redux';
import {
  getCurrentMarketplace, getMarketplaceComplexesTile,
  getMarketplaceLanguage,
  getMarketplacePrimarySectionTag,
} from 'src/store/marketplace/selectors';
import { useMarketPlaceFilter } from 'src/components/Marketplace/hooks';
import _ from 'lodash';
import { useStyles } from './styles';

import { ReactComponent as Filtre } from './icons/filtre.svg';

export const MarketplaceFilterOptions = () => {
  const styles = useStyles();
  const [isPopupOpen, setIsPopupOpen] = useState< null | boolean>(false);
  const [activeOptions, setActiveOptions] = useState<IMarketplaceFilterTagSelectedValueSelectedProperty[]>([]);

  const [activeStations, setActiveStations] = useState({});
  const [currency, setCurrency] = useState('UAH');

  const popupOverlayRef = useRef(null);
  const popupContentRef = useRef(null);

  const marketplace = useSelector(getCurrentMarketplace);
  const tag = useSelector(getMarketplacePrimarySectionTag);
  const lang = useSelector(getMarketplaceLanguage);
  const complexes = useSelector(getMarketplaceComplexesTile);
  const { setWhereConditions, whereConditions, resetAllFilterWhereConditions } = useMarketPlaceFilter();
  const { setActiveTab } = useMarketPlaceFilter();

  const togglePopup = () => setIsPopupOpen(!isPopupOpen);

  const appliedFiltersCount = useMemo(() => {
    let count = 0;
    if (whereConditions) {
      const propertyNames = whereConditions.map((item) => item.propertyName);
      count = _.uniq(propertyNames).length;
    }
    return count;
  }, [marketplace, whereConditions]);

  const handleOptionClick = (prop: IMarketplaceFilterTagSelectedValueSelectedProperty) => {
    if (marketplace) {
      setActiveOptions(
        activeOptions.includes(prop)
          ? activeOptions.filter((opt) => opt.value !== prop.value)
          : [...activeOptions, prop],
      );
      if (lang && prop.property) {
        setWhereConditions([{
          propertyName: prop.property?.systemName,
          oldSystemName: prop.property.oldSystemName,
          equals: prop.value[lang],
        }], tag?.id);
      }
    }
  };

  const handleTabClick = (tab: IViewTileType) => {
    setActiveTab(tab);
  };

  const handleCurrencyToggle = (newCurrency: string) => {
    setCurrency(newCurrency);
  };

  const onReset = () => {
    resetAllFilterWhereConditions();
  };

  // useGlobalStyles();

  // You can add class .showTabs for filterOptionsContainer

  return (
    <div className={styles.filterOptionsContainer}>
      <FilterOptionSlider
        selection={tag}
        activeOptions={activeOptions}
        onOptionClick={handleOptionClick}
        styles={styles}
      />

      <div className={styles.allFiltersButtonTitle}>
        Пошук усіх проєктів
      </div>

      <div
        className={['allFiltersTabsTop'].join(
          ' ',
        )}
      >
        <NavigationTabs
          onTabClick={handleTabClick}
        />
      </div>

      <button className={styles.allFiltersButton} onClick={togglePopup} type="button">
        <span className="icon">
          <Filtre />
        </span>
        <span className={styles.allFiltersButtonDesktop}>
          Всі фільтри
        </span>
        <span className={styles.allFiltersButtonMobile}>Фільтри</span>
        {appliedFiltersCount !== 0 && (
          <span className={styles.allFilters}>{appliedFiltersCount}</span>
        )}
      </button>

      {isPopupOpen && (
        <Box
          ref={popupOverlayRef}
          className={styles.popupOverlay}
          onClick={(e) => {
            if (e.target === popupOverlayRef.current) {
              setIsPopupOpen(false);
            }
          }}
        >
          <div ref={popupContentRef} className={styles.popupContent}>
            <h2 className={styles.popupContentTitle}>
              Всі фільтри
            </h2>

            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button
              className={styles.popupContentClose}
              onClick={(e) => {
                e.stopPropagation();
                setIsPopupOpen(false);
              }}
              type="button"
            />

            <div className={styles.popupContentInner}>

              {marketplace && lang && marketplace.frontConfiguration.module.filtersConfiguration.primarySection.selections
                .sort((a, b) => a.sortOrder - b.sortOrder)
                .map((filter) => (
                  <>
                    { filter.type === IMarketplaceFilterType.GroupingFilter && (
                      <CollapsibleFilter
                        title={filter.name?.[lang] || ''}
                        classes={styles}
                        accordionState={filter.accordionState}
                      >
                        <LocationFilterPopup
                          selection={filter as IMarketplaceFilterLocation}
                          isPopupOpen={isPopupOpen}
                          // setIsPopupOpen={setIsPopupOpen}
                          // activeTab={(filter as IMarketplaceFilterLocation).filters[0].property?.systemName}
                          // setActiveTab={setActiveTab}
                          // activeButtons={activeButtons}
                          // setActiveButtons={setActiveButtons}
                          showActions={false}
                          activeStations={activeStations}
                          setActiveStations={setActiveStations}
                          customClass={styles.customPopup}
                        />
                      </CollapsibleFilter>
                    ) }
                    { filter.type === IMarketplaceFilterType.AllValuesTag && (
                      <CollapsibleFilter
                        title={filter.name?.[lang] || ''}
                        classes={styles}
                        accordionState={filter.accordionState}
                      >
                        <TypeFilterPopup
                          selection={filter as IMarketplaceFilterTagAllValues}
                          // activeButtons={activeButtons}
                          // setActiveButtons={setActiveButtons}
                          onApply={() => setIsPopupOpen(false)}
                          customClass={styles.customPopup}
                          showActions={false}
                        />
                      </CollapsibleFilter>
                    ) }
                    { filter.type === IMarketplaceFilterType.AllValuesRange && (
                      <CollapsibleFilter
                        title={filter.name?.[lang] || ''}
                        classes={styles}
                        showSwitcher={false}
                        currency={currency}
                        onCurrencyToggle={handleCurrencyToggle}
                        accordionState={filter.accordionState}
                      >
                        <PriceFilterPopup
                          selection={filter as IMarketplaceFilterRange}
                          isPopupOpen={isPopupOpen}
                          setIsPopupOpen={setIsPopupOpen}
                          showActions={false}
                          customClass={styles.customPopup}
                          labelFrom={(filter as IMarketplaceFilterRange).fromName?.[lang] || ''}
                          labelTo={(filter as IMarketplaceFilterRange).toName?.[lang] || ''}
                        />
                      </CollapsibleFilter>
                    ) }
                    { filter.type === IMarketplaceFilterType.AllValuesCheckbox && (
                      <CollapsibleFilter
                        title={filter.name?.[lang] || ''}
                        classes={styles}
                        accordionState={filter.accordionState}
                      >
                        <RoomsFilterPopup
                          selection={filter as IMarketplaceFilterCheckboxAllValues}
                          // isPopupOpen={isPopupOpen}
                          // setIsPopupOpen={setIsPopupOpen}
                          // selectedRooms={selectedRooms}
                          // setSelectedRooms={setSelectedRooms}
                          renderType="checkbox"
                          showActions={false}
                          customClass={styles.customPopup}
                        />
                      </CollapsibleFilter>
                    ) }
                  </>
                ))}
              {marketplace && lang && marketplace.frontConfiguration.module.filtersConfiguration.secondaries
                .sort((a, b) => a.sortOrder - b.sortOrder)
                .map((filter) => (
                  <>
                    { filter.type === IMarketplaceFilterType.GroupingFilter && (
                      <CollapsibleFilter
                        title={filter.name?.[lang] || ''}
                        classes={styles}
                        accordionState={filter.accordionState}
                      >
                        <LocationFilterPopup
                          selection={filter as IMarketplaceFilterLocation}
                          isPopupOpen={isPopupOpen}
                          // setIsPopupOpen={setIsPopupOpen}
                          // activeTab={activeTab}
                          // setActiveTab={setActiveTab}
                          // activeButtons={activeButtons}
                          // setActiveButtons={setActiveButtons}
                          showActions={false}
                          activeStations={activeStations}
                          setActiveStations={setActiveStations}
                          customClass={styles.customPopup}
                        />
                      </CollapsibleFilter>
                    ) }
                    { filter.type === IMarketplaceFilterType.AllValuesTag && (
                      <CollapsibleFilter
                        title={filter.name?.[lang] || ''}
                        classes={styles}
                        accordionState={filter.accordionState}
                      >
                        <TypeFilterPopup
                          selection={filter as IMarketplaceFilterTagAllValues}
                          // activeButtons={activeButtons}
                          // setActiveButtons={setActiveButtons}
                          onApply={() => setIsPopupOpen(false)}
                          customClass={styles.customPopup}
                          showActions={false}
                        />
                      </CollapsibleFilter>
                    ) }
                    { filter.type === IMarketplaceFilterType.AllValuesRange && (
                      <CollapsibleFilter
                        title={filter.name?.[lang] || ''}
                        classes={styles}
                        showSwitcher={false}
                        currency={currency}
                        onCurrencyToggle={handleCurrencyToggle}
                        accordionState={filter.accordionState}
                      >
                        <PriceFilterPopup
                          selection={filter as IMarketplaceFilterRange}
                          isPopupOpen={isPopupOpen}
                          setIsPopupOpen={setIsPopupOpen}
                          showActions={false}
                          customClass={styles.customPopup}
                          labelFrom={(filter as IMarketplaceFilterRange).fromName?.[lang] || ''}
                          labelTo={(filter as IMarketplaceFilterRange).toName?.[lang] || ''}
                        />
                      </CollapsibleFilter>
                    ) }
                    { filter.type === IMarketplaceFilterType.AllValuesCheckbox && (
                      <CollapsibleFilter
                        title={filter.name?.[lang] || ''}
                        classes={styles}
                        accordionState={filter.accordionState}
                      >
                        <RoomsFilterPopup
                          selection={filter as IMarketplaceFilterCheckboxAllValues}
                          // isPopupOpen={isPopupOpen}
                          // setIsPopupOpen={setIsPopupOpen}
                          // selectedRooms={selectedRooms}
                          // setSelectedRooms={setSelectedRooms}
                          renderType="checkbox"
                          showActions={false}
                          customClass={styles.customPopup}
                        />
                      </CollapsibleFilter>
                    ) }
                    { filter.type === IMarketplaceFilterType.SelectedValuesTag && (
                      <CollapsibleFilter
                        title={filter.name?.[lang] || ''}
                        classes={styles}
                        accordionState={filter.accordionState}
                      >
                        <FilterOptionSlider
                          selection={filter as IMarketplaceFilterTagSelectedValues}
                          activeOptions={activeOptions}
                          onOptionClick={handleOptionClick}
                          styles={styles}
                          view="list"
                        />
                      </CollapsibleFilter>
                    ) }
                  </>
                ))}

              {marketplace && lang && tag?.selectedProperties && (
                <CollapsibleFilter
                  title={tag.name?.[lang] || ''}
                  classes={styles}
                  accordionState={tag.accordionState}
                >
                  <FilterOptionSlider
                    selection={tag}
                    activeOptions={activeOptions}
                    onOptionClick={handleOptionClick}
                    styles={styles}
                    view="list"
                  />
                </CollapsibleFilter>
              ) }
            </div>
            <FilterActions
              applyTitle={`Показати (${complexes?.length} проєкти)`}
              onApply={() => setIsPopupOpen(false)}
              onReset={() => onReset()}
            />
          </div>
        </Box>
      )}
    </div>
  );
};
