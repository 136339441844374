import QRCode, { QRCodeToDataURLOptions } from 'qrcode';
import { useBuildType } from 'src/hooks/_use_build_type';
import { useSelector } from 'react-redux';
import { getComplexId, getCrmData, getCurrentLang } from 'src/store/app/selectors';
import { isNaN, isFinite } from 'lodash';
import { useIsDevMode } from 'src/hooks/app';
import { isNumeric } from 'src/helpers/isNumeric';
import { useGetComplex } from 'src/hooks/useGetComplex';
import { IComplex } from 'src/typings/complexes';

export const useQRCode = () => {
  const { isCrossTable } = useBuildType();
  const { isDevMode } = useIsDevMode();
  const crmData = useSelector(getCrmData);
  const complexId = useSelector(getComplexId);
  const { complex } = useGetComplex(complexId ? String(complexId) : null, false);
  const currentLang = useSelector(getCurrentLang);
  const getQrCode = async (link: string, options?: QRCodeToDataURLOptions) => {
    let result = '';
    try {
      result = await QRCode.toDataURL(link, options);
    } catch (err) {
      result = '';
    }
    return result;
  };

  const getLink = () => {
    if (isCrossTable || crmData) {
      return '';
    }
    const string = new URL(document.URL).pathname.split('/');
    const complexShortName: string = string[string.length - 1];
    if ((complex?.complexShortName.length && !isNumeric(complexShortName)) || isDevMode) {
      return `${getClientModuleAccessUrl(complex, currentLang)}${window.location.hash}`;
    }
    return '';
  };

  const getClientModuleAccessUrl = (_complex: IComplex | null, lang: string): string => {
    if (_complex?.clientModuleAccessUrls.length && _complex?.clientModuleAccessUrls[0]?.url) {
      return `${_complex?.clientModuleAccessUrls[0].url}`;
    }
    return `https://flatshow.property/${lang}/${_complex?.complexShortName}`;
  };

  return {
    getQrCode,
    getLink,
    getClientModuleAccessUrl,
  };
};
