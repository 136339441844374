import React from 'react';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import { BoxFormTextField } from 'src/components/profileComponents/FormTextField';
import { translations } from 'src/constants/translations';
import { BoxFormAutocomplete } from 'src/components/profileComponents/FormAutocomplete';
import { GroupWrapper } from 'src/components/profileComponents/Marketplace/components/GroupWrapper';
import {
  accordionStateOptions, AccordionStateType,
  IMarketplaceFilterTagSelectedValues,
} from 'src/components/profileComponents/Marketplace/types';
import { setInputValue } from 'src/components/profileComponents/Marketplace/helpers';
import {
  GroupWrapperFiltersAction,
} from 'src/components/profileComponents/Marketplace/components/GroupWrapperActions/GroupWrapperFiltersAction';
import { useStyles } from './styles';

interface ITagFilter {
  entity: {item: IMarketplaceFilterTagSelectedValues},
  lang: string,
  onDrag?: () => void,
  onTrash?: () => void,
}

export const TagFilter: React.FC<ITagFilter> = ({
  entity, lang, onDrag, onTrash,
}) => {
  const styles = useStyles();
  return (
    <>
      <GroupWrapper
        name="Tag filter (selected values)"
        startIcon={<FmdGoodOutlinedIcon />}
        actions={(
          <GroupWrapperFiltersAction
            onDrag={onDrag}
            onTrash={onTrash}
          />
        )}
      >
        <div className={styles.tagGroupFields}>
          <BoxFormTextField
            label={translations.name}
            name="name"
            defaultValue={entity.item.name?.[lang]}
            onChange={(event) => {
              setInputValue<IMarketplaceFilterTagSelectedValues>({ propLink: entity.item, propName: 'name' }, event.target.value, lang);
            }}
          />
          <BoxFormAutocomplete
            label={translations.accordionState}
            name="accordionState"
            defaultValue={{ label: entity.item.accordionState, value: entity.item.accordionState }}
            options={accordionStateOptions}
            onChange={(event: {label: string, value: AccordionStateType}) => {
              const tmp = entity;
              tmp.item.accordionState = event.value;
            }}
          />
        </div>
      </GroupWrapper>
    </>
  );
};
