import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles(() => createStyles({
  tileLocationMetroStationWrapper: {
    gap: '20px',
    display: 'flex',
  },
  optionSwitch: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  uom: {
    maxWidth: '46px',
    minWidth: '46px',
  },
  shorterDistanceOnly: {
    maxWidth: '146px',
    minWidth: '146px',
  },
}));
