import { httpService } from 'src/services';
import { ApiUrl } from 'src/constants';
import { HydraResponse, HydraView, Response } from 'src/api/hydraResponse';
import {
  IFieldWithTranslation,
  IMarketplace,
  IMarketplaceProperty,
} from 'src/components/profileComponents/Marketplace/types';
import {
  IMarketplaceComplexTileConfig,
  IMarketplaceFilterRequest,
  IMarketplaceFilterResponse,
  IMarketplaceLanguageObjects, IMarketplaceLanguageObjectsData, IMarketplaceTilesConfig, IMarketplaceTilesType,
} from 'src/typings/marketplace';

export function getMarketplaces(token: string = ''): Promise<HydraResponse<IMarketplace[]>> {
  return httpService.get(`${ApiUrl.getApiUrl().getMarketplaces}`, {}, {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/ld+json',
  });
}

export function getMarketplaceById(token: string = '', id: number): Promise<Response<IMarketplace>> {
  return httpService.get(`${ApiUrl.getApiUrl().getMarketplaces}/${id}`, {}, {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/ld+json',
  });
}

export function postMarketplace(token: string = '', data: any) {
  return httpService.post(`${ApiUrl.getApiUrl().getMarketplaces}`, data, {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/ld+json',
  });
}

export function postMarketplaceFilter(token: string = '', data: IMarketplaceFilterRequest): Promise<IMarketplaceFilterResponse> {
  return httpService.post(`${ApiUrl.getApiUrl().getMarketplacesFilters}`, data, {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/ld+json',
  });
}

export function putMarketplace(token: string = '', id: number, data: any) {
  return httpService.put(`${ApiUrl.getApiUrl().getMarketplaces}/${id}`, data, {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/ld+json',
  });
}

export function getMarketplaceLanguageObjects(token: string = '', data: IMarketplaceLanguageObjectsData): Promise<{data: IMarketplaceLanguageObjects}> {
  return httpService.post(`${ApiUrl.getApiUrl().getMarketplacesLanguageObjects}`, data, {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/ld+json',
  });
}

export function getMarketplaceTiles(token: string = '', params: string): Promise<HydraResponse<IMarketplaceComplexTileConfig[]>> {
  // return new Promise((resolve) => {
  //   resolve({
  //     config: { url: '' },
  //     headers: {},
  //     duration: 0,
  //     ok: true,
  //     originalError: null,
  //     problem: null,
  //     status: 0,
  //     data: {
  //       'hydra:itemsPerPage': 0,
  //       'hydra:totalItems': 0,
  //       'hydra:view': null,
  //       'hydra:member': [{
  //         complexTileConfig: {
  //           id: 0,
  //           complexOrderings: [
  //             {
  //               id: 0,
  //               sortOrder: 0,
  //               isActive: false,
  //               complex: {
  //                 id: 0,
  //                 title: { uk: 'ЖК Голосіївський' },
  //                 language: 'uk',
  //                 previewFileUri: {
  //                   '@id': '/api/file_uris/152',
  //                   '@type': 'FileUri',
  //                   '@context': '/api/contexts/FileUri',
  //                   id: 152,
  //                   domain: 'showfiles.net',
  //                   path: '/',
  //                   name: 'tUMFQxdr',
  //                   extension: 'jpg',
  //                   resolutions: null,
  //                   alternativeTypes: null,
  //                   fileUrl: 'https://showfiles.net/tUMFQxdr.jpg',
  //                 },
  //               },
  //               tags: [
  //                 {
  //                   id: 0,
  //                   sortOrder: 0,
  //                   text: { uk: 'asd' },
  //                   iconFileUri: {
  //                     '@id': 'asd.png',
  //                     '@context': 'context',
  //                     '@type': 'type',
  //                     id: 0,
  //                     domain: '',
  //                     path: '',
  //                     name: '',
  //                     extension: '',
  //                     resolutions: null,
  //                     alternativeTypes: null,
  //                     fileUrl: 'https://showfiles.net/tpHuPjKp.svg',
  //                   },
  //                 },
  //               ],
  //             },
  //             {
  //               id: 1,
  //               sortOrder: 1,
  //               isActive: false,
  //               complex: {
  //                 id: 0,
  //                 title: { uk: 'Forest Park' },
  //                 language: 'uk',
  //               },
  //               tags: [
  //                 {
  //                   id: 0,
  //                   sortOrder: 0,
  //                   text: { uk: 'asd' },
  //                   iconFileUri: {
  //                     '@id': 'asd.png',
  //                     '@context': 'context',
  //                     '@type': 'type',
  //                     id: 0,
  //                     domain: '',
  //                     path: '',
  //                     name: '',
  //                     extension: '',
  //                     resolutions: null,
  //                     alternativeTypes: null,
  //                     fileUrl: '',
  //                   },
  //                 },
  //               ],
  //             },
  //           ],
  //           header: {
  //             id: 0,
  //             diType: 'ComplexProperty',
  //             property: {
  //               '@id': '',
  //               systemName: '',
  //               oldSystemName: null,
  //               translates: { uk: 'title' },
  //             },
  //             isActive: false,
  //           },
  //           location: {
  //             id: 0,
  //             isMetroOrDistrict: true,
  //             isActive: true,
  //             values: [
  //               {
  //                 id: 0,
  //                 type: IMarketplaceTilesType.CITY,
  //                 sortOrder: 0,
  //                 prefix: { uk: 'м.' },
  //                 suffix: null,
  //                 property: {
  //                   '@id': '',
  //                   systemName: '',
  //                   oldSystemName: null,
  //                   translates: { uk: 'City' },
  //                 },
  //               },
  //               {
  //                 id: 1,
  //                 type: IMarketplaceTilesType.METROSTATION,
  //                 sortOrder: 0,
  //                 prefix: null,
  //                 suffix: { uk: 'метро' },
  //                 isShorterDistanceOnly: true,
  //                 walkingDistance: {
  //                   id: 0,
  //                   isEnabled: true,
  //                   iconFileUri: {
  //                     '@context': '',
  //                     '@id': '',
  //                     '@type': '',
  //                     id: 0,
  //                     domain: '',
  //                     path: '',
  //                     name: '',
  //                     extension: '',
  //                     resolutions: null,
  //                     alternativeTypes: null,
  //                     fileUrl: '',
  //                   },
  //                   uomText: { uk: 'хв' },
  //                   property: {
  //                     '@id': '',
  //                     systemName: '',
  //                     oldSystemName: null,
  //                     translates: null,
  //                   },
  //                 },
  //                 drivingDistance: {
  //                   id: 0,
  //                   isEnabled: true,
  //                   iconFileUri: {
  //                     '@context': '',
  //                     '@id': '',
  //                     '@type': '',
  //                     id: 0,
  //                     domain: '',
  //                     path: '',
  //                     name: '',
  //                     extension: '',
  //                     resolutions: null,
  //                     alternativeTypes: null,
  //                     fileUrl: '',
  //                   },
  //                   uomText: { uk: 'хв' },
  //                   property: {
  //                     '@id': '',
  //                     systemName: '',
  //                     oldSystemName: null,
  //                     translates: null,
  //                   },
  //                 },
  //               },
  //               {
  //                 id: 2,
  //                 type: IMarketplaceTilesType.DISTRICT,
  //                 sortOrder: 0,
  //                 prefix: null,
  //                 suffix: { uk: 'р-н.' },
  //                 property: {
  //                   '@id': '',
  //                   systemName: '',
  //                   oldSystemName: null,
  //                   translates: null,
  //                 },
  //               },
  //             ],
  //           },
  //           minMaxRange: {
  //             prefix: { uk: 'від' },
  //             suffix: { uk: 'до' },
  //             isActive: true,
  //             virtualProperty: {
  //               id: 0,
  //               type: 'MinMaxVirtualProperty',
  //               property: {
  //                 '@id': '',
  //                 systemName: 'placements.totalPrice',
  //                 oldSystemName: null,
  //                 translates: null,
  //               },
  //             },
  //             uomText: { uk: 'грн' },
  //           },
  //           projectTimeline: {
  //             prefix: { uk: 'Завершення будівництва' },
  //             suffix: null,
  //             isActive: true,
  //             property: {
  //               '@id': '',
  //               systemName: 'developmentSpecification.completionDates.name',
  //               oldSystemName: null,
  //               translates: null,
  //             },
  //           },
  //           unitsBreakdown: {
  //             id: 0,
  //             prefix: null,
  //             suffix: null,
  //             isActive: false,
  //             virtualProperty: {
  //               type: 'GroupingVirtualProperty',
  //               property:
  //                 {
  //                   '@id': '',
  //                   systemName: 'placements.plannigType',
  //                   oldSystemName: null,
  //                   translates: null,
  //                 },
  //               subProperties: [
  //                 {
  //                   id: 0,
  //                   type: 'MinVirtualProperty',
  //                   func: 'min',
  //                   property: {
  //                     '@id': '',
  //                     systemName: 'placements.totalArea',
  //                     oldSystemName: null,
  //                     translates: null,
  //                   },
  //                   prefix: { uk: 'від' },
  //                   uomText: { uk: 'м²' },
  //                 },
  //                 {
  //                   id: 1,
  //                   type: 'MinVirtualProperty',
  //                   func: 'min',
  //                   property: {
  //                     '@id': '',
  //                     systemName: 'placements.totalPrice',
  //                     oldSystemName: null,
  //                     translates: null,
  //                   },
  //                   prefix: { uk: 'від' },
  //                   uomText: { uk: 'грн.' },
  //                 },
  //               ],
  //             },
  //           },
  //           availabilityCounter: {
  //             prefix: null,
  //             suffix: { uk: 'юнітів доступно' },
  //             isActive: true,
  //           },
  //         },
  //       }],
  //     },
  //     // Complex: {
  //     //   virtualProperties: [
  //     //     {
  //     //       type: 'minMax',
  //     //       propertyName: 'placements.totalArea',
  //     //       min: 1,
  //     //       max: 2,
  //     //     },
  //     //     {
  //     //       type: 'subMin',
  //     //       propertyName: 'placements.row0',
  //     //       value: { uk: 'Однокімнатні' },
  //     //       sub: {
  //     //         propertyName: 'placements.totalPrice',
  //     //         min: 1234,
  //     //       },
  //     //     },
  //     //   ],
  //     // },
  //   });
  // });

  return httpService.get(`${ApiUrl.getApiUrl().getMarketplaceTiles}${params}`, {}, {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/ld+json',
  });
}

export function putMarketplaceTiles(token: string = '', params: string, data: IMarketplaceComplexTileConfig): Promise<HydraResponse<IMarketplaceComplexTileConfig[]>> {
  return httpService.put(`${ApiUrl.getApiUrl().getMarketplaceTiles}${params}`, data, {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/ld+json',
  });
}

export function getMarketplaceProperties(token: string = '', id: number): Promise<HydraResponse<IMarketplaceProperty[]>> {
  return new Promise((resolve) => {
    resolve(
      {
        config: { url: '' },
        headers: {},
        duration: 1,
        ok: true,
        originalError: null,
        problem: null,
        status: 0,
        data: {
          'hydra:totalItems': 1,
          'hydra:itemsPerPage': 10,
          'hydra:view': {
            '@id': '',
            '@type': '',
            'hydra:first': '',
            'hydra:last': '',
            'hydra:next': '',
          },
          'hydra:member': [
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'complex.location.countryManual',
              oldSystemName: '',
              translates: { uk: 'Країна' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'complex.location.cityManual',
              oldSystemName: '',
              translates: { uk: 'Місто' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'complex.location.districtManual',
              oldSystemName: '',
              translates: { uk: 'Вулиця' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'complex.location.nearestMetroStationManual',
              oldSystemName: '',
              translates: { uk: 'Станція метро' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'complex.location.nearestMetroBranchManual',
              oldSystemName: '',
              translates: { uk: 'Гілка метро' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'complex.location.nearestMetroWalkMinutesManual',
              oldSystemName: '',
              translates: { uk: 'Час ходу до станції' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'complex.location.nearestMetroDriveMinutesManual',
              oldSystemName: '',
              translates: { uk: 'Час їзди до станції' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'numeration',
              oldSystemName: '',
              translates: { uk: 'Номер квартири' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'note',
              oldSystemName: '',
              translates: { uk: 'Нотатка' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'tag',
              oldSystemName: '',
              translates: { uk: 'Тег' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'rooms',
              oldSystemName: '',
              translates: { uk: 'Кількість кімнат' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'row0',
              oldSystemName: '',
              translates: { uk: 'ров0' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'row1',
              oldSystemName: '',
              translates: { uk: 'ров1' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'row2',
              oldSystemName: '',
              translates: { uk: 'ров2' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'row3',
              oldSystemName: '',
              translates: { uk: 'ров3' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'row4',
              oldSystemName: '',
              translates: { uk: 'ров4' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'row5',
              oldSystemName: '',
              translates: { uk: 'ров5' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'row6',
              oldSystemName: '',
              translates: { uk: 'ров6' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'row7',
              oldSystemName: '',
              translates: { uk: 'ров7' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'row8',
              oldSystemName: '',
              translates: { uk: 'ров8' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'row9',
              oldSystemName: '',
              translates: { uk: 'ров9' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'planningType',
              oldSystemName: '',
              translates: { uk: 'Тип планування' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'placementType.title',
              oldSystemName: '',
              translates: { uk: 'Тип приміщення' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'placementStatus.name',
              oldSystemName: '',
              translates: { uk: 'Статус' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'placementStatus.moduleName',
              oldSystemName: '',
              translates: { uk: 'Модуль статус' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'placementStatus.crosstableName',
              oldSystemName: '',
              translates: { uk: 'Шахматка статус' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'floors.numeration',
              oldSystemName: '',
              translates: { uk: 'Поверх' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'section.numeration',
              oldSystemName: '',
              translates: { uk: 'Секція' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'house.numeration',
              oldSystemName: '',
              translates: { uk: 'Будинок' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'house.developmentSpecification.completionDates.name',
              oldSystemName: '',
              translates: { uk: 'Дата завершення' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'house.developmentSpecification.purchaseTerms.name',
              oldSystemName: '',
              translates: { uk: 'Умови купівлі' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'house.developmentSpecification.propertyClasses.name',
              oldSystemName: '',
              translates: { uk: 'Клас житла' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'house.developmentSpecification.salesStatuses.name',
              oldSystemName: '',
              translates: { uk: 'Статус продажів' },
            },
            {
              '@id': '/api/marketplace_properties/0',
              systemName: 'house.developmentSpecification.constructionPhases.name',
              oldSystemName: '',
              translates: { uk: 'Фаза побудови' },
            },
          ],
        },
      },
    );
    // return httpService.get(`${ApiUrl.getApiUrl().getMarketplaceProperties}/${id}`, {}, {
    //   Authorization: `Bearer ${token}`,
    //   'Content-Type': 'application/ld+json',
  });
}
