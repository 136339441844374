import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles(() => createStyles({
  complexTileWrapper: {
    marginTop: '40px',
    backgroundColor: 'white',
  },
  title: {
    marginBottom: '16px',
    fontSize: '14px',
  },
  tileTag: {
    marginBottom: '16px',
  },
}));
