import React, { useState } from 'react';
import { LocationFilter } from 'src/components/Marketplace/components/LocationFilter';
import { TypeFilter } from 'src/components/Marketplace/components/TypeFilter';
import { PriceFilter } from 'src/components/Marketplace/components/PriceFilter';
import { RoomsFilter } from 'src/components/Marketplace/components/RoomsFilter';
import { useSelector } from 'react-redux';
import {
  getCurrentMarketplace,
} from 'src/store/marketplace/selectors';
import {
  IMarketplaceFilterCheckboxAllValues,
  IMarketplaceFilterLocation,
  IMarketplaceFilterRange,
  IMarketplaceFilterTagAllValues,
  IMarketplaceFilterType,
} from 'src/components/profileComponents/Marketplace/types';
import { useStyles } from './styles';
import { MarketplaceFilterPopUpProvider } from './provider';

export const MarketplaceFilterHeader = () => {
  const marketplace = useSelector(getCurrentMarketplace);
  const styles = useStyles();
  const [isPopupOpen, setIsPopupOpen] = useState<string | null>(null);

  const closePopup = () => {
    setIsPopupOpen(null);
  };

  return (
    <div className={`${styles.filterHeader} ${![null, ''].includes(isPopupOpen) ? 'show' : ''}`}>
      <MarketplaceFilterPopUpProvider value={isPopupOpen}>
        { marketplace && marketplace.frontConfiguration.module.filtersConfiguration.primarySection.selections
          .sort((a, b) => a.sortOrder - b.sortOrder)
          .map((filter) => (
            <>
              { filter.type === IMarketplaceFilterType.GroupingFilter && (
                <LocationFilter
                  selection={filter as IMarketplaceFilterLocation}
                  setIsPopupOpen={setIsPopupOpen}
                  closePopup={closePopup}
                />
              ) }
              { filter.type === IMarketplaceFilterType.AllValuesTag && (
                <TypeFilter
                  selection={filter as IMarketplaceFilterTagAllValues}
                  setIsPopupOpen={setIsPopupOpen}
                  closePopup={closePopup}
                />
              ) }
              { filter.type === IMarketplaceFilterType.AllValuesRange && (
                <PriceFilter
                  selection={filter as IMarketplaceFilterRange}
                  setIsPopupOpen={setIsPopupOpen}
                  closePopup={closePopup}
                  showCurrencyToggle={false}
                />
              ) }
              { filter.type === IMarketplaceFilterType.AllValuesCheckbox && (
                <RoomsFilter
                  selection={filter as IMarketplaceFilterCheckboxAllValues}
                  setIsPopupOpen={setIsPopupOpen}
                  closePopup={closePopup}
                />
              ) }
            </>
          )) }
      </MarketplaceFilterPopUpProvider>
    </div>
  );
};
