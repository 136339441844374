import React from 'react';

import {
  AppFullScreenContainer,
  FilterMap,
} from 'src/components';
import { locals } from 'src/constants/locals';
import {
  useSetDocumentTitle,
  useTranslations,
  useDisablePinchZoomEffect,
  useIsMobile, useBuildType,
} from 'src/hooks';
import { Marketplace } from 'src/components/Marketplace';
import LinearProgress from '@mui/material/LinearProgress';
import { useSelector } from 'react-redux';
import { getMarketplaceIsLoading } from 'src/store/marketplace/selectors';
import Box from '@mui/material/Box';
import { useStyles } from 'src/components/Marketplace/styles';
import { FilterDesktop } from '../../components/FilterProperty/components';

export const MainPage: React.FC = () => {
  const { getTranslations } = useTranslations();
  const { isMobileScreen } = useIsMobile();
  const { isMarketplace } = useBuildType();
  const isMarketplaceLoading = useSelector(getMarketplaceIsLoading);
  const styles = useStyles();
  useDisablePinchZoomEffect();
  useSetDocumentTitle(getTranslations(locals.appTranslations.appTitleMainPage));

  return (
    <>
      { !isMarketplace && (
      <AppFullScreenContainer>
        <>
          {!isMobileScreen && <FilterDesktop />}
          <FilterMap />
        </>
      </AppFullScreenContainer>
      ) }
      { isMarketplace && (
        <Box position="relative">
          { isMarketplaceLoading && (
            <>
              <Box position="absolute" top="0" width="100%">
                <LinearProgress />
              </Box>
              <Box className={styles.layoutLoader} />
            </>
          ) }
          <Marketplace />
        </Box>
      ) }
    </>
  );
};
