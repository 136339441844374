import {
  IMarketplaceLocationRequestFilter, IMarketplaceRangeRequestFilter,
  IMarketplaceRequestFilter,
} from 'src/components/profileComponents/Marketplace/types';
import { UrlConfigure } from 'src/typings/app';

const linksPropsMapping = {
  'placementType.title': UrlConfigure.typePlacementUrl,
};

const getMappedProp = (prop: string): string => {
  // @ts-ignore
  return linksPropsMapping[prop] ? linksPropsMapping[prop] : prop;
};

export const getLinkParams = (data: IMarketplaceRequestFilter[]): string | undefined => {
  if (!data?.length) {
    return undefined;
  }
  let result = '';
  data.forEach((link, index) => {
    const sign = ((!index && link.oldSystemName) || result === '') ? '?' : '&';
    if ('equals' in (link as IMarketplaceLocationRequestFilter) && link.oldSystemName) {
      result += `${sign}${getMappedProp(link.oldSystemName)}=${(link as IMarketplaceLocationRequestFilter).equals}`;
    }
    if (('min' && 'max') in link && link.oldSystemName) {
      result += `${sign}${getMappedProp(link.oldSystemName)}=${(link as IMarketplaceRangeRequestFilter).min};${(link as IMarketplaceRangeRequestFilter).max}`;
    }
  });
  return result;
};
