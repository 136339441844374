import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles(() => createStyles({
  description: {
    marginBottom: '40px',
  },
  metroOrDistrict: {
    gap: '20px',
    display: 'flex',
    padding: '10px 0px 40px 0px',
  },
  optionSwitch: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
}));
