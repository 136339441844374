import {
  takeEvery, put, call, select,
} from 'redux-saga/effects';
import { getDisplayPlacementData } from 'src/api';
import { getBuildType } from 'src/helpers';
import { BuildEnv } from 'src/typings/env';
import { setView2dSelectedTypeIsInitAction } from 'src/store/view-2d/selectors';
import { queryClient } from 'src/lib/queryClient';
import { QUERY_KEYS } from 'src/constants/queryKeys';
import { ActionTypes, Actions } from './actions';
import { IDisplayPlacementData } from './reducer';
import { getComplexId } from '../app/selectors';

export function* setDisplayPlacementData() {
  const isInit: string = yield select(setView2dSelectedTypeIsInitAction);
  const complexId: number | null = yield select(getComplexId);
  const buildType = getBuildType();
  const isCrossTable = buildType === BuildEnv.crossTable;
  let firstType: IDisplayPlacementData|undefined;

  let data = queryClient.getQueryData<IDisplayPlacementData[]>([QUERY_KEYS.DISPLAY_PLACEMENT_DATA, complexId]);
  if (!data) {
    const response: { data: IDisplayPlacementData[] } = yield call(getDisplayPlacementData);
    data = response.data;

    queryClient.setQueryData([QUERY_KEYS.DISPLAY_PLACEMENT_DATA, complexId], data);
  }

  if (data) {
    yield put(Actions.setView2dDisplayTypes(data));
    if (isCrossTable) {
      firstType = data.find((item) => {
        return item.enable_for_crosstable;
      });
    } else {
      firstType = data.find((item) => {
        return item.enable_for_module;
      });
    }
    if (isInit && firstType) {
      yield put(Actions.setView2dSelectedType(firstType.field_name));
    }
  }
}

export function* getView2dWatchSaga() {
  yield takeEvery(ActionTypes.SET_VIEW2D_DATA, setDisplayPlacementData);
}
