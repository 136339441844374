export interface GroupWrapperFiltersActionProps {
  onTrash?: () => void
  onDrag?: () => void
  onRocket?: (state: boolean) => void
  onCollapse?: (state: GroupWrapperFiltersCollapseState) => void
  collapseState?: GroupWrapperFiltersCollapseState
  rocketState?: boolean
}
export enum GroupWrapperFiltersCollapseState {
  OPEN = 'open',
  CLOSED = 'closed'
}
