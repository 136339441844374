import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles(() => createStyles({
  tilesWrapper: {
    '& .projects-order': {
      marginBottom: '82px',
    },
  },
  customGroupWrapperStyles: {
    backgroundColor: '#F5F5F5',
  },
}));
