import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { PopupStateContext } from 'src/components/Marketplace/components/MarketplaceFilterHeader/context';
import Box from '@mui/material/Box';
import { LocationFilterPopup } from 'src/components/Marketplace/components/LocationFilter/LocationFilterPopup';
import {
  IMarketplaceFilterLocation,
} from 'src/components/profileComponents/Marketplace/types';
import { useSelector } from 'react-redux';
import { getMarketplaceLanguage } from 'src/store/marketplace/selectors';
import { useMarketPlaceFilter } from 'src/components/Marketplace/hooks';
import { useStyles } from './styles';

interface Props {
  selection: IMarketplaceFilterLocation,
  setIsPopupOpen: (state: string | null) => void,
  closePopup: () => void,
}

export const LocationFilter: React.FC<Props> = ({ selection, setIsPopupOpen, closePopup }) => {
  const styles = useStyles();
  const lang = useSelector(getMarketplaceLanguage);
  const [isPopupOpen, setIsLocalPopupOpen] = useState(false);
  const [activeStations, setActiveStations] = useState<string[]>([]);
  const {
    whereConditions,
  } = useMarketPlaceFilter();
  const filterType = 'LocationFilter';

  const openPopupType = useContext(PopupStateContext);

  const appliedButtonsText = useMemo(() => {
    return activeStations.length && whereConditions?.length ? activeStations.join(', ') : null;
  }, [activeStations, whereConditions]);

  useEffect(() => {
    if (openPopupType !== filterType && openPopupType !== null) {
      setIsLocalPopupOpen(false);
    }
  }, [openPopupType, filterType]);

  const togglePopup = () => {
    const newState = !isPopupOpen;
    setIsLocalPopupOpen(newState);
    setIsPopupOpen(newState ? 'LocationFilter' : null);
  };

  return (
    <Box
      className={`${styles.container} ${isPopupOpen ? 'show' : ''}`}
      onClick={(e) => {
        e.stopPropagation();
        togglePopup();
      }}
    >
      {lang && (
        <>
          {selection.name?.[lang] && (
          <h3 className={styles.title}>{ selection.name[lang] }</h3>
          ) }
          {selection.callToActionText?.[lang] && (
          <p className={styles.subtitle}>{ appliedButtonsText || selection.callToActionText?.[lang] }</p>
          ) }
          {isPopupOpen && (
          <LocationFilterPopup
            selection={selection}
            isPopupOpen={isPopupOpen}
            // setIsPopupOpen={setIsPopupOpen}
            closePopup={() => {
              togglePopup();
              closePopup();
              // setIsPopupOpen(null);
            }}
            // activeTab={activeTab}
            // setActiveTab={setActiveTab}
            // activeButtons={activeButtons}
            // setActiveButtons={setActiveButtons}
            activeStations={activeStations}
            setActiveStations={setActiveStations}
          />
          )}
        </>
      )}
    </Box>
  );
};
