import { IFilterAllData, ListOfKeysFilterAllData } from 'src/typings/filter';

const fieldsToCompare = [
  ListOfKeysFilterAllData.placementTotalPrice,
  ListOfKeysFilterAllData.placementTotalPriceAlternative,
  ListOfKeysFilterAllData.placementPricePerSqM,
  ListOfKeysFilterAllData.placementPricePerSqMAlternative,
];

export const UseNumberFormat = (separator?: string) => {
  const defaultSeparator: string = ' ';
  const numberSeparatorFieldsExclude = [ListOfKeysFilterAllData.placementNumeration, 'numeration'];
  const numberSeparator = (value: string, placementProperty?: string) => {
    if (placementProperty && numberSeparatorFieldsExclude.includes(placementProperty as ListOfKeysFilterAllData)) {
      return value;
    }
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator || defaultSeparator);
  };
  const replaceFraction = (value: string) => value.toString().replace(/\b(\d+)\.00\b/g, '$1');
  const compareAllArrayValuesAfterDecimal = (data: string[], searchValue?: string): boolean => {
    const defaultSearchValue = '.00';
    const compareValue = searchValue || defaultSearchValue;
    return data.some((item) => item?.length && item.slice(item.length - compareValue.length) !== compareValue);
  };
  const roundIfDecimalsIsZero = (data: IFilterAllData[], searchKeysArray: ListOfKeysFilterAllData[]) => {
    let result = data;
    let fieldsToCompareParams = {};
    searchKeysArray.forEach((searchKey) => {
      if (!compareAllArrayValuesAfterDecimal(data.map((item) => item[searchKey] || ''))) {
        fieldsToCompareParams = {
          ...fieldsToCompareParams,
          [searchKey]: true,
        };
        result = result.map((item) => ({ ...item, [searchKey]: item[searchKey] ? numberSeparator(String(Math.round(item[searchKey])), searchKey) : item[searchKey] }));
      } else {
        fieldsToCompareParams = {
          ...fieldsToCompareParams,
          [searchKey]: false,
        };
        result = result.map((item) => ({ ...item, [searchKey]: item[searchKey] ? numberSeparator(String(item[searchKey]), searchKey) : item[searchKey] }));
      }
    });
    return {
      data: result,
      fieldsToCompareParams,
    };
  };
  return {
    numberSeparator,
    replaceFraction,
    compareAllArrayValuesAfterDecimal,
    roundIfDecimalsIsZero,
    fieldsToCompare,
  };
};
