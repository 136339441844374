import React, { useEffect, useMemo, useState } from 'react';
import {
  Backdrop, Box, Fade, Modal, Paper,
} from '@mui/material';
import { translations } from 'src/constants/translations';
import CloseIcon from '@mui/icons-material/Close';
import { useStyles } from 'src/components/profileComponents/Complexes/styles';
import { ButtonApp } from 'src/components/Button';
import { httpService } from 'src/services';
import { ApiUrl } from 'src/constants';
import { toQueryString } from 'src/helpers/buildQueryParamsString';
import { useSelector } from 'react-redux';
import { requestSelectors } from 'src/store/requests/selectors';
import { IComplex } from 'src/typings/complexes';
import { useExportAvailability } from 'src/hooks/app/useExportAvailability';
import { TypeOfRole } from 'src/typings/user';
import { BoxFormAutocomplete } from 'src/components/profileComponents/FormAutocomplete';
import {
  IDevelopmentSpecificationPropChange,
} from 'src/components/profileComponents/Complexes/components/ProjectSpecification/types';
import CircularProgress from '@mui/material/CircularProgress';
import { getCrmData } from 'src/store/app/selectors';

interface ModalProps {
  isOpen: boolean;
  setModalOpen: (open: boolean) => void;
}
export const AvailabilityReportModal: React.FC<ModalProps> = ({ isOpen, setModalOpen }) => {
  const { exportAvailability, loadingProgress } = useExportAvailability();
  const crmData = useSelector(getCrmData);
  const styles = useStyles({});
  const user = useSelector(requestSelectors.user.getUserData);
  const [selectedComplexes, setSelectedComplexes] = useState<IDevelopmentSpecificationPropChange<IComplex>[]>([]);
  const [options, setOptions] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const getParams = useMemo(() => ({
    users: user?.id,
    properties: {
      0: 'id',
      1: 'title',
      2: 'previewFileUri',
      3: 'logoFileUri',
      4: 'complexShortName',
      5: 'language',
      6: 'clientModuleAccessUrls',
      developers: [
        'iconUri',
        'logoFileUri',
      ],
    },
  }), [user]);

  useEffect(() => {
    httpService.get(`${ApiUrl.getApiUrl().getComplexes}?${toQueryString(getParams)}&pagination=false`)
      .then((res: any) => {
        const complexes: IComplex[] = res.data['hydra:member'];
        const allOptions = complexes.map((item) => ({ label: item.title[item.language], value: item }));
        const preselectedComplexes = (user?.role?.['@id'] === TypeOfRole.supervisor)
          ? allOptions
          : [{ label: complexes[0].title[complexes[0].language], value: complexes[0] }];
        setSelectedComplexes(!!crmData ? [] : preselectedComplexes);
        setOptions(!!crmData ? [] : allOptions);
      });
  }, [crmData]);

  const submit = () => {
    setIsLoading(true);
    exportAvailability(selectedComplexes.map((comp) => comp.value))
      .finally(() => {
        setIsLoading(false);
      });
  };

  const close = () => setModalOpen(false);
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={styles.modalWindow}
      open={isOpen}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <Paper
          style={{
            outline: 'none',
            zIndex: '1',
          }}
        >
          <Box width="800px" maxHeight="90vh" overflow="auto" p="52px">
            <Box mb={6} fontSize="24px" fontWeight={600} display="flex" alignItems="center" justifyContent="space-between">
              {translations.exportAvailability}
              <CloseIcon onClick={close} className={styles.closeBtn} />
            </Box>
            <Box fontSize="18px">
              <Box sx={{ width: '100%', typography: 'body1' }} mb={8}>
                <BoxFormAutocomplete
                  label={translations.selectProjectToBeDisplayed}
                  name="complexes-availability"
                  options={options}
                  value={selectedComplexes}
                  onChange={setSelectedComplexes}
                />
              </Box>
            </Box>
            <Box display="flex" justifyContent="flex-end" gap="20px">
              <ButtonApp
                onClick={close}
                text={translations.cancel}
                color="primary"
                variant="outlined"
                type="button"
                className={styles.btn}
              />
              <ButtonApp
                startIcon={isLoading ? <CircularProgress variant="determinate" size="20px" value={loadingProgress} /> : undefined}
                disabled={!selectedComplexes.length || isLoading || !!crmData}
                text={translations.exportAvailability}
                color="primary"
                variant="contained"
                type="submit"
                className={styles.btn}
                onClick={submit}
              />
            </Box>
          </Box>
        </Paper>
      </Fade>
    </Modal>
  );
};
