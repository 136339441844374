import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import Box from '@mui/material/Box';
import { FilterActions } from 'src/components/Marketplace/components/FilterActions';
import {
  IMarketplaceFilterTagAllValues,
} from 'src/components/profileComponents/Marketplace/types';
import { useMarketPlaceFilter } from 'src/components/Marketplace/hooks';
import { IMarketplaceFilterResponseValue } from 'src/typings/marketplace';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { getMarketplaceActiveFilter, getMarketplaceFilters } from 'src/store/marketplace/selectors';
import { Actions } from 'src/store/marketplace/actions';
import { useStyles } from './styles';

interface Props {
  selection?: IMarketplaceFilterTagAllValues,
  setIsPopupOpen?: (value: null | boolean) => void,
  setCallToActionButtons?: (value: any) => void,
  closePopup?: () => void,
  customClass?: string
  showActions?: boolean,
  onApply?: () => void,
}
export const TypeFilterPopup: React.FC<Props> = ({
  selection,
  setIsPopupOpen,
  setCallToActionButtons,
  closePopup,
  customClass,
  showActions = true,
  onApply,
}) => {
  const styles = useStyles();
  const {
    getMarketplaceInitialFilter,
    marketplaceInitialFilters,
    marketplaceFilters,
    setWhereConditions,
    getFilterEqualsActiveData,
    resetFilterWhereConditions,
    getMarketplaceFilter,
  } = useMarketPlaceFilter();
  const activeFilter = useSelector(getMarketplaceActiveFilter);
  const popupRef = useRef(null);
  const [options, setOptions] = useState<IMarketplaceFilterResponseValue[] | undefined>();
  const [availableOptions, setAvailableOptions] = useState<IMarketplaceFilterResponseValue[] | undefined>();
  const [activeButtons, setActiveButtons] = useState<string[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selection?.property?.systemName) {
      const value = getMarketplaceInitialFilter(selection.property.systemName)?.values;
      setOptions(value);
      setAvailableOptions(value);
    }
  }, [selection, marketplaceInitialFilters]);

  useEffect(() => {
    if (selection?.property?.systemName) {
      const value = getFilterEqualsActiveData(selection?.property?.systemName);
      const filteredData = getMarketplaceFilter(selection.property.systemName)?.values;
      if (filteredData) {
        setAvailableOptions(filteredData);
      }
      setActiveButtons(value);
      if (setCallToActionButtons) {
        setCallToActionButtons(value);
      }
    }
  }, [selection, marketplaceFilters]);

  useEffect(() => {
    return () => {
      dispatch(Actions.setMarketplaceActiveFilter(undefined));
    };
  }, []);

  const isDisabled = useCallback((value: IMarketplaceFilterResponseValue): boolean => {
    if (!options?.length && !availableOptions?.length) {
      return false;
    }
    return !availableOptions?.filter((option) => option.value === value.value).length;
  }, [selection, options, availableOptions]);

  const getUnitCount = useCallback((value: IMarketplaceFilterResponseValue): number => {
    const count = marketplaceFilters?.find((filterItem) => filterItem.propertyName === selection?.property?.systemName)?.values
      .find((val) => val.value === value.value);
    return isDisabled(value) ? 0 : (count?.unitCount || value.unitCount);
  }, [options, availableOptions]);

  const toggleButton = (buttonLabel: IMarketplaceFilterResponseValue) => {
    if (selection?.property?.systemName) {
      setWhereConditions([{
        propertyName: selection?.property?.systemName,
        oldSystemName: selection?.property.oldSystemName,
        equals: buttonLabel.value,
      }], selection.id);
    }
  };

  const handleReset = () => {
    if (selection?.property?.systemName) {
      resetFilterWhereConditions([selection?.property.systemName]);
    }
  };

  const handleApply = () => {
    if (setIsPopupOpen) setIsPopupOpen(null);
    if (closePopup) closePopup();
  };

  const isActive = useCallback((option: IMarketplaceFilterResponseValue) => {
    return activeButtons.find((item) => item === option.value);
  }, [activeButtons]);

  return (
    <Box
      className={`${styles.popup} ${customClass || ''} popup`}
      ref={popupRef}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="lContent">
        { options && options.map((option) => (
          <button
            key={option.value}
            className={classnames('lBtn', { active: isActive(option) }, { disabled: isDisabled(option) })}
            onClick={(e) => {
              e.stopPropagation();
              toggleButton(option);
            }}
            type="button"
          >
            { option.value }
            <span className={styles.counter}>
              (
              { getUnitCount(option) }
              )
            </span>
          </button>
        )) }
      </div>

      { showActions && (
      <FilterActions
        onReset={handleReset}
        onApply={handleApply}
      />
      ) }
    </Box>
  );
};
