import React from 'react';
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';
import { GroupWrapper } from 'src/components/profileComponents/Marketplace/components/GroupWrapper';
import { translations } from 'src/constants/translations';
import { BoxFormTextField } from 'src/components/profileComponents/FormTextField';
import { BoxFormAutocomplete } from 'src/components/profileComponents/FormAutocomplete';
import { IOSSwitch } from 'src/components/SwitchIos';
import {
  accordionStateOptions, AccordionStateType, IFieldWithTranslation,
  IMarketplaceFilterRange, IMarketplaceProperty,
} from 'src/components/profileComponents/Marketplace/types';
import {
  IDevelopmentSpecificationPropChange,
} from 'src/components/profileComponents/Complexes/components/ProjectSpecification/types';
import { setInputValue } from 'src/components/profileComponents/Marketplace/helpers';
import {
  GroupWrapperFiltersAction,
} from 'src/components/profileComponents/Marketplace/components/GroupWrapperActions/GroupWrapperFiltersAction';
import { useStyles } from './styles';

interface IRangeProps {
  entity: {item: IMarketplaceFilterRange},
  lang: string,
  propertyOptions: IDevelopmentSpecificationPropChange<IMarketplaceProperty>[]
  onTrash: () => void,
}

export const Range: React.FC<IRangeProps> = ({
  entity, lang, propertyOptions, onTrash,
}) => {
  const styles = useStyles();
  return (
    <>
      <GroupWrapper
        name="Range"
        startIcon={<LabelOutlinedIcon />}
        actions={(
          <GroupWrapperFiltersAction
            onTrash={onTrash}
            onDrag={() => {}}
          />
        )}
      >
        <div className={styles.rangeWrapper}>
          <BoxFormTextField
            label={translations.name}
            name="name"
            defaultValue={entity.item.name?.[lang]}
            onChange={(event) => {
              setInputValue<IMarketplaceFilterRange>({ propLink: entity.item, propName: 'name' }, event.target.value, lang);
            }}
          />
          <BoxFormTextField
            label={translations.fromNaming}
            name="from"
            defaultValue={entity.item.fromName?.[lang]}
            onChange={(event) => {
              setInputValue<IMarketplaceFilterRange>({ propLink: entity.item, propName: 'fromName' }, event.target.value, lang);
            }}
          />
          <BoxFormTextField
            label={translations.toNaming}
            name="to"
            defaultValue={entity.item.toName?.[lang]}
            onChange={(event) => {
              setInputValue<IMarketplaceFilterRange>({ propLink: entity.item, propName: 'toName' }, event.target.value, lang);
            }}
          />
          <BoxFormAutocomplete
            label={translations.chooseProperty}
            name="property"
            defaultValue={{ label: entity.item.property?.translates?.[lang] || entity.item.property?.systemName, value: entity.item.property }}
            onChange={(event: IDevelopmentSpecificationPropChange<IMarketplaceProperty>) => {
              const tmp = entity;
              tmp.item.property = event.value;
            }}
            options={propertyOptions}
          />

          <BoxFormAutocomplete
            label={translations.accordionState}
            name="accordionState"
            defaultValue={{ label: entity.item.accordionState, value: entity.item.accordionState }}
            options={accordionStateOptions}
            onChange={(event: {label: string, value: AccordionStateType}) => {
              const tmp = entity;
              tmp.item.accordionState = event.value;
            }}
          />
        </div>
        <div className={styles.subRangeWrapper}>
          <div>
            <div className={styles.subRangeSwitch}>
              <IOSSwitch name="checkedInfrastructure" />
            </div>
          </div>
          <BoxFormTextField
            label={translations.mainUOM}
            name="mainUOM"
            defaultValue={entity.item.mainUom?.[lang]}
            onChange={(event) => {
              setInputValue<IMarketplaceFilterRange>({ propLink: entity.item, propName: 'mainUom' }, event.target.value, lang);
            }}
          />
          <BoxFormTextField
            label={translations.secondaryUOM}
            name="secondaryUOM"
            defaultValue={entity.item.secondaryUom?.[lang]}
            onChange={(event) => {
              setInputValue<IMarketplaceFilterRange>({ propLink: entity.item, propName: 'secondaryUom' }, event.target.value, lang);
            }}
          />
          <BoxFormTextField
            label={translations.conversionRate}
            name="conversionRate"
            defaultValue={String(entity.item?.uomRate || '')}
            onChange={(event) => {
              const tmp = entity;
              tmp.item.uomRate = Number(event.target.value);
            }}
          />
        </div>
      </GroupWrapper>
    </>
  );
};
