import { postMarketplaceFilter } from 'src/api/marketplace';
import { useCallback, useEffect, useState } from 'react';
import { useGetAuthData } from 'src/hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCurrentMarketplace, getMarketplaceActiveFilter, getMarketplaceFilters,
  getMarketplaceInitialFilters, getMarketplaceLanguage, getMarketplaceSettings, getMarketplaceWhereConditions,
} from 'src/store/marketplace/selectors';
import { IMarketplaceFilterResponseFilter, IMarketplaceFilterResponseValue } from 'src/typings/marketplace';
import { Actions } from 'src/store/marketplace/actions';
import {
  IMarketplaceLocationRequestFilter, IMarketplaceRangeRequestFilter,
  IMarketplaceRequestFilter, IViewTileType,
} from 'src/components/profileComponents/Marketplace/types';

export const useMarketPlaceFilter = () => {
  const marketplace = useSelector(getCurrentMarketplace);
  const lang = useSelector(getMarketplaceLanguage);
  const marketplaceInitialFilters = useSelector(getMarketplaceInitialFilters);
  const marketplaceFilters = useSelector(getMarketplaceFilters);
  const marketplaceSettings = useSelector(getMarketplaceSettings);
  const whereConditions = useSelector(getMarketplaceWhereConditions);
  const activeFilter = useSelector(getMarketplaceActiveFilter);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();

  const fetchMarketplacesFilters = useCallback(() => {
    if (marketplace && lang) {
      setIsLoading(true);
      postMarketplaceFilter(jwt, {
        language: lang,
        marketplaceId: marketplace.id,
      })
        .then((res) => {
          // const { data } = res;
          // setMarketplaces(data['hydra:member']);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [marketplace]);

  const isActiveFilter = useCallback((filterToCheck: number | undefined): boolean => {
    return activeFilter === filterToCheck;
  }, [activeFilter]);

  const isFilterDisabled = useCallback((value: IMarketplaceFilterResponseValue, systemName: string | undefined): boolean => {
    const availableOptions = getMarketplaceFilter(systemName || '')?.values;
    if (!marketplaceInitialFilters?.length && !availableOptions?.length) {
      return false;
    }
    return !availableOptions?.filter((option) => option.value === value.value).length;
  }, [marketplaceFilters, marketplaceInitialFilters]);

  const getMarketplaceInitialFilter = useCallback((searchPropertyName: string) => {
    let result: IMarketplaceFilterResponseFilter | undefined;
    if (marketplaceInitialFilters) {
      result = marketplaceInitialFilters.find((filter) => filter.propertyName === searchPropertyName);
    }
    return result;
  }, [marketplaceInitialFilters]);

  const getMarketplaceFilter = useCallback((searchPropertyName: string) => {
    let result: IMarketplaceFilterResponseFilter | undefined;
    if (marketplaceFilters) {
      result = marketplaceFilters.find((filter) => filter.propertyName === searchPropertyName);
    }
    return result;
  }, [marketplaceFilters]);

  const getFilterEqualsActiveData = useCallback((propertyName: string) => {
    let result: string[] = [];
    if (whereConditions) {
      result = whereConditions.filter((filter) => filter.propertyName === propertyName)
        .map((filter) => {
          return (filter as IMarketplaceLocationRequestFilter).equals;
        });
    }
    return result;
  }, [whereConditions]);

  const getFilterRangeActiveData = useCallback((propertyName: string) => {
    let result: number[] = [];
    if (whereConditions) {
      result = whereConditions.filter((filter) => filter.propertyName === propertyName)
        .map((filter) => {
          return [(filter as IMarketplaceRangeRequestFilter).min, (filter as IMarketplaceRangeRequestFilter).max];
        })[0];
    }
    return result;
  }, [whereConditions]);

  const setWhereConditions = useCallback((condition: IMarketplaceRequestFilter[], filterType: number | undefined) => {
    if (whereConditions) {
      dispatch(Actions.setMarketplaceWhereConditions(condition));
      dispatch(Actions.setMarketplaceLinkParams(condition));
      dispatch(Actions.setMarketplaceActiveFilter(filterType));
    }
  }, [whereConditions]);

  const resetAllFilterWhereConditions = useCallback(() => {
    if (whereConditions) {
      dispatch(Actions.setMarketplaceResetAllWhereConditions());
    }
  }, [whereConditions]);

  const resetFilterWhereConditions = useCallback((propertyName: string[]) => {
    if (whereConditions) {
      dispatch(Actions.setMarketplaceResetWhereConditions(propertyName));
      dispatch(Actions.setMarketplaceActiveFilter(undefined));
    }
  }, [whereConditions]);

  const setActiveTab = useCallback((tileType: IViewTileType) => {
    if (marketplaceSettings) {
      dispatch(Actions.setMarketplaceViewTile({
        type: tileType,
        pageNumber: marketplaceSettings?.viewTile.pageNumber,
      }));
    }
  }, [marketplaceSettings]);

  return {
    isLoading,
    fetchMarketplacesFilters,
    marketplaceInitialFilters,
    marketplaceFilters,
    activeFilter,
    whereConditions,
    getMarketplaceInitialFilter,
    getMarketplaceFilter,
    getFilterEqualsActiveData,
    getFilterRangeActiveData,
    setWhereConditions,
    resetFilterWhereConditions,
    resetAllFilterWhereConditions,
    isActiveFilter,
    isFilterDisabled,
    setActiveTab,
  };
};
