import React from 'react';
import { useSelector } from 'react-redux';
import {
  getMarketplaceSettings, getMarketplaceViewTile,
} from 'src/store/marketplace/selectors';
import { IViewTileType } from 'src/components/profileComponents/Marketplace/types';
import { useStyles } from './styles';

export const NavigationTabs: React.FC<any> = ({ onTabClick }) => {
  const styles = useStyles();
  const settings = useSelector(getMarketplaceSettings);
  const viewTile = useSelector(getMarketplaceViewTile);

  return (
    <div className={[styles.headerNav, 'headerNavTop'].join(' ')}>
      <nav className={styles.navigation}>
        <button
          className={`${styles.tab} ${viewTile?.type === IViewTileType.Complexes ? styles.active : ''}`}
          onClick={() => onTabClick(IViewTileType.Complexes)}
          type="button"
        >
          {`${settings?.generalUnitInfo.complexesTotalCount} проєкти`}
        </button>
        <button
          className={`${styles.tab} ${viewTile?.type === IViewTileType.Placements ? styles.active : ''}`}
          onClick={() => onTabClick(IViewTileType.Placements)}
          type="button"
        >
          {`${settings?.generalUnitInfo.placementsTotalCount} юніти`}
        </button>
      </nav>
    </div>
  );
};
