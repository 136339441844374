import { useCallback } from 'react';
import { useGetAuthData } from 'src/hooks/use-get-auth-data';
import { useMutation, useQuery } from '@tanstack/react-query';
import { QUERY_KEYS } from 'src/constants/queryKeys';
import { getPlacementTypesByComplex } from 'src/api';
import { getMarketplaceTiles, putMarketplaceTiles } from 'src/api/marketplace';
import { IMarketplace, IMarketplaceProperty } from 'src/components/profileComponents/Marketplace/types';
import { IMarketplaceComplexTileConfig, IMarketplaceTilesLocationValue } from 'src/typings/marketplace';
import { ITranslate } from 'src/typings/viewConfig';
import { queryClient } from 'src/lib/queryClient';

export const useMarketplaceTile = (marketplace: IMarketplace | undefined) => {
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();

  const { data: tilesConfig, isLoading: tilesConfigLoading, error: tilesConfigError } = useQuery({
    queryKey: [QUERY_KEYS.TILE],
    queryFn: async () => {
      if (!marketplace) return undefined;
      const data = await getMarketplaceTiles(jwt, `?module.frontConfiguration.marketplace=${marketplace.id}`);
      return data.data['hydra:member'][0];
    },
    enabled: !!jwt && !!marketplace,
  });

  const saveTilesConfig = useMutation({
    mutationFn: async (data: {dataToSave: IMarketplaceComplexTileConfig}) => {
      const req = await putMarketplaceTiles(jwt, `/${data.dataToSave.id}`, data.dataToSave);
      return req;
    },
    onSuccess: (data, variables) => {
      queryClient.setQueryData([QUERY_KEYS.TILE, { id: marketplace?.id }], data);
    },
  });

  return {
    saveTilesConfig,
    tilesConfig,
    tilesConfigLoading,
    tilesConfigError,
  };
};
