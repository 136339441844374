import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useGetAuthData } from 'src/hooks';
import { Actions as FilterActions } from 'src/store/requests/filter/actions';
import { IMessage, CRMNames } from 'src/typings/app';
import { Actions } from 'src/store/app/actions';
import { Actions as ActionsCross } from 'src/store/crosstable/actions';
import { Actions as ActionsFloors } from 'src/store/floors/actions';
import {
  getAmoToken,
  getComplexByAmoPipeline,
  getBitrixDeal,
  getPlacementByBitrix,
  getPlacementByAmo,
  getComplexesByBitrixDomain,
  getComplexData,
  getCreatioDeal,
  getUserData,
  getComplexesByCreatioDeveloper,
  getPlacementByCreatio,
  getComplexesByNetHunt,
  getPlacementByNetHunt,
  getNetHuntDeal,
} from 'src/api';
import { pathList } from 'src/route-list';
import { IComplex } from 'src/typings/complexes';
import { IUsers } from 'src/typings/user';
import { useQueryClient } from '@tanstack/react-query';

export const useSubsribeMessage = () => {
  const { setJWT } = useGetAuthData();
  const history = useHistory();
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const handlerAmo = async (data: IMessage, cleanupFunction: boolean) => {
    const {
      userEmail,
      pipelineId,
      dealId,
    } = data;
    const formatId = `${pipelineId}.${dealId}`;
    console.log('MSG', data);
    if (dealId === 0) {
      history.push(`${pathList.errorPage}/zeroDeal`);
    } else {
      dispatch(Actions.setCrmData(data));
      const res = await getAmoToken(userEmail);
      if (!cleanupFunction) {
        setJWT(res.data.token);
        if (pipelineId) {
          const { data: placements } = await getPlacementByAmo(res.data.token, formatId);
          if (placements['hydra:totalItems']) {
            dispatch(Actions.selectAppartment(placements['hydra:member'][0].id));
            dispatch(Actions.setIsOpenAppartSidebar(true));
          }
          const comp = await getComplexByAmoPipeline(res.data.token, pipelineId);
          if (comp.data['hydra:totalItems'] && comp.data['hydra:member'][0]) {
            const compId = comp.data['hydra:member'][0].id;
            history.push(`/${compId}/`);
          } else {
            history.push(`${pathList.errorPage}/unknownPipeline`);
          }
        }
      }
    }
  };

  const handlerBitrix = async (data: IMessage, cleanupFunction: boolean) => {
    const {
      userEmail,
      dealId,
      domain,
      categoryId,
    } = data;
    console.log('MSG', data);

    const res = await getAmoToken(userEmail);
    const { token } = res.data;
    if (!cleanupFunction && domain) {
      dispatch(Actions.setCrmData(data));
      setJWT(token);
      const { data: complexes } = await getComplexesByBitrixDomain(token, categoryId, userEmail, domain);
      if (complexes['hydra:totalItems']) {
        dispatch(ActionsCross.setAvailableComplexes(complexes['hydra:member']));
      }
      if (dealId) {
        const { data: bitrixDeal } = await getBitrixDeal(token, dealId);
        dispatch(ActionsCross.setBitrixDeal(bitrixDeal));
        const { data: placements } = await getPlacementByBitrix(token, dealId);
        dispatch(Actions.setBindingAppartment(!!placements['hydra:totalItems']));
        if (placements['hydra:totalItems']) {
          dispatch(Actions.selectAppartment(placements['hydra:member'][0].id));
          dispatch(Actions.setIsOpenAppartSidebar(true));
          const compAccess = complexes['hydra:member'].find((comp: IComplex) => {
            return comp.id === placements['hydra:member'][0].complex.id;
          });
          if (compAccess) {
            const firstComplexId = placements['hydra:member'][0].complex.id;
            const response = await getComplexData(jwt, firstComplexId);

            queryClient.setQueryData(
              ['complex', String(firstComplexId)],
              response,
            );

            const { data: firstComplex } = response;
            if (firstComplex && firstComplex.language) {
              dispatch(ActionsCross.setDefaultPlacementType(placements['hydra:member'][0].placementType.title[firstComplex.language]));
              dispatch(ActionsFloors.setSelectedFloor(String(placements['hydra:member'][0].floors[0].id)));
              dispatch(ActionsFloors.setSelectedFloorNumber(placements['hydra:member'][0].floors[0].numeration[firstComplex.language]));
              history.push(`/${firstComplexId}/`);
            }
          } else {
            history.push(`${pathList.errorPage}/wrongComplexAccess`);
          }
        }
      }
    }
  };

  const handlerCreatio = async (data: IMessage, cleanupFunction: boolean) => {
    const {
      dealId,
      domain,
      projectId,
      token,
    } = data;
    console.log('MSG', data);
    if (!cleanupFunction && domain && token) {
      dispatch(Actions.setCrmData(data));
      setJWT(token);
      const { data: user }: {
        data: IUsers
      } = await getUserData(token);
      const { data: creatioDeal } = await getCreatioDeal(token, dealId);
      dispatch(ActionsCross.setCreatioDeal({
        ...creatioDeal,
        id: dealId,
      }));
      const { data: complexes } = await getComplexesByCreatioDeveloper(token, projectId, user.email);

      if (complexes['hydra:totalItems']) {
        dispatch(ActionsCross.setAvailableComplexes(complexes['hydra:member']));
      }
      const { data: placements } = await getPlacementByCreatio(token, dealId);
      dispatch(Actions.setBindingAppartment(!!placements['hydra:totalItems']));
      if (placements['hydra:totalItems']) {
        dispatch(Actions.selectAppartment(placements['hydra:member'][0].id));
        dispatch(Actions.setIsOpenAppartSidebar(true));
        const compAccess = complexes['hydra:member'].find((comp: IComplex) => {
          return comp.id === placements['hydra:member'][0].complex.id;
        });
        if (compAccess) {
          const firstComplexId = placements['hydra:member'][0].complex.id;
          const response = await getComplexData(jwt, firstComplexId);

          queryClient.setQueryData(
            ['complex', String(firstComplexId)],
            response,
          );

          const { data: firstComplex } = response;
          if (firstComplex && firstComplex.language) {
            dispatch(ActionsCross.setDefaultPlacementType(placements['hydra:member'][0].placementType.title[firstComplex.language]));
            dispatch(ActionsFloors.setSelectedFloor(String(placements['hydra:member'][0].floors[0].id)));
            dispatch(ActionsFloors.setSelectedFloorNumber(placements['hydra:member'][0].floors[0].numeration[firstComplex.language]));
            history.push(`/${firstComplexId}/`);
          }
        } else {
          history.push(`${pathList.errorPage}/wrongComplexAccess`);
        }
      }
    }
  };

  const handlerNetHunt = async (data: IMessage, cleanupFunction: boolean) => {
    const {
      dealId,
      projectId,
      token,
    } = data;
    console.log('MSG', data);
    if (!cleanupFunction && token) {
      dispatch(Actions.setCrmData(data));
      setJWT(token);
      const { data: user }: {
        data: IUsers
      } = await getUserData(token);
      const { data: netHuntDeal } = await getNetHuntDeal(token, dealId);
      dispatch(ActionsCross.setNetHuntDeal({
        ...netHuntDeal,
        id: dealId,
      }));
      const { data: complexes } = await getComplexesByNetHunt(token, projectId, user.email);

      if (complexes['hydra:totalItems']) {
        dispatch(ActionsCross.setAvailableComplexes(complexes['hydra:member']));
      }
      const { data: placements } = await getPlacementByNetHunt(token, dealId);
      dispatch(Actions.setBindingAppartment(!!placements['hydra:totalItems']));
      if (placements['hydra:totalItems']) {
        dispatch(Actions.selectAppartment(placements['hydra:member'][0].id));
        dispatch(Actions.setIsOpenAppartSidebar(true));
        const compAccess = complexes['hydra:member'].find((comp: IComplex) => {
          return comp.id === placements['hydra:member'][0].complex.id;
        });
        if (compAccess) {
          const firstComplexId = placements['hydra:member'][0].complex.id;
          const response = await getComplexData(jwt, firstComplexId);

          queryClient.setQueryData(
            ['complex', String(firstComplexId)],
            response,
          );

          const { data: firstComplex } = response;
          if (firstComplex && firstComplex.language) {
            dispatch(ActionsCross.setDefaultPlacementType(placements['hydra:member'][0].placementType.title[firstComplex.language]));
            dispatch(ActionsFloors.setSelectedFloor(String(placements['hydra:member'][0].floors[0].id)));
            dispatch(ActionsFloors.setSelectedFloorNumber(placements['hydra:member'][0].floors[0].numeration[firstComplex.language]));
            history.push(`/${firstComplexId}/`);
          }
        } else {
          history.push(`${pathList.errorPage}/wrongComplexAccess`);
        }
      }
    }
  };

  useEffect(() => {
    let cleanupFunction = false;
    const listener = (e: any) => {
      if (e.data.type === 'amocrm') {
        handlerAmo({
          crm: CRMNames.amocrm,
          ...e.data.data,
        }, cleanupFunction);
      }
      if (e.data.type === 'bitrix24') {
        handlerBitrix({
          crm: CRMNames.bitrix,
          ...e.data.data,
        }, cleanupFunction);
      }
      if (e.data.type === 'creatio') {
        handlerCreatio({
          crm: CRMNames.creatio,
          ...e.data.data,
        }, cleanupFunction);
      }
      if (e.data.type === 'netHunt') {
        handlerNetHunt({
          crm: CRMNames.netHunt,
          ...e.data.data,
        }, cleanupFunction);
      }
      if (e.data.type === 'update2d') {
        dispatch(FilterActions.getDataFilterUpdate());
      }
    };
    window.addEventListener('message', listener, false);

    return () => {
      cleanupFunction = true;
      window.removeEventListener('message', listener, false);
    };
  });
};
