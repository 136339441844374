import { useTable } from 'src/hooks/table/useTable';
import { IComplex, IStatus } from 'src/typings/complexes';
import { getPlacementStatusByComplex, getPlacementTypesByComplex, getViewsByComplex } from 'src/api';
import * as ExcelJS from 'exceljs';
import { AddWorksheetOptions } from 'exceljs';
import { IPlacementType, IView } from 'src/store/type-displayed-spinner/types';
import { IPropertySettings } from 'src/typings/viewConfig';
import { useGetAuthData } from 'src/hooks/use-get-auth-data';
import { IFilterAllData, ListOfKeysFilterAllData } from 'src/typings/filter';
import { useState } from 'react';

export const useExportAvailability = () => {
  const [loadingProgress, setLoadingProgress] = useState<number>(0);
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();
  const {
    exportExcelFile,
    getUniquePlacementProperties,
    setExcelExportInProgress,
    applyAndDownloadReport,
  } = useTable();

  const exportAvailability = async (complexes: IComplex[]) => {
    const workBook = new ExcelJS.Workbook();
    const workBookOptions: Partial<AddWorksheetOptions> = {
      pageSetup: {
        paperSize: 9,
        orientation: 'landscape',
      },
      views: [{ state: 'frozen', ySplit: 11 }],
    };
    setExcelExportInProgress(true);
    for (const [i, complex] of complexes.entries()) {
      const subViewType = 'adminPanel';
      const crosstableView = 'Crosstable';
      const listSubViewType = 'ListSubView';
      // eslint-disable-next-line no-await-in-loop
      const res = await Promise.all([
        getViewsByComplex(complex.language, String(complex.id)),
        getPlacementTypesByComplex(String(complex.id)),
        getPlacementStatusByComplex(jwt, String(complex.id)),
      ]);
      const placements: {[key: string]: IFilterAllData} = res[0].data['detail-info'].placement;
      const placementsTypes: IPlacementType[] = res[1].data['hydra:member'];
      const placementStatuses: IStatus[] = res[2].data['hydra:member'];
      const placementStatusesCanBeAttachToDeal = placementStatuses
        .filter((status) => status.amoCanBeSetForDeal)
        .map((status) => status.module_name[complex.language]);
      const placementsFilteredByStatus: {[key: string]: IFilterAllData} = {};
      Object.keys(placements)
        .forEach((key) => {
          if (placementStatusesCanBeAttachToDeal.includes(placements[key][ListOfKeysFilterAllData.placementStatusModuleName])) {
            placementsFilteredByStatus[key] = placements[key];
          }
        });
      const placementSettingsArray: IPropertySettings[] = [];
      placementsTypes.forEach((currentType: IPlacementType) => {
        if (currentType && Array.isArray(currentType.frontConfig[subViewType].views)) {
          // @ts-ignore
          const subViewTemp = (currentType.frontConfig[subViewType].views as IView[])
            .find((item) => item['@type'] === crosstableView)?.subViews
            .find((item) => item['@type'] === listSubViewType);
          if (subViewTemp?.propertySettings) {
            placementSettingsArray.push(...[...subViewTemp.propertySettings]);
          }
        }
      });
      const uniquePlacementProperties = getUniquePlacementProperties(placementSettingsArray);
      if (placementsFilteredByStatus && Object.keys(placementsFilteredByStatus).length) {
        const sheet = workBook.addWorksheet(String(`${complex.title[complex.language]}`), workBookOptions);
        const columns = uniquePlacementProperties
          .map((item) => ({ headerName: item.translates?.[complex.language] || item.oldSystemName || '', field: item.oldSystemName || undefined }))
          .filter((item) => item.headerName);
        exportExcelFile(placementsFilteredByStatus, sheet, workBook, columns, complex);
        setLoadingProgress(((i + 1) * 100) / complexes.length);
      }
    }
    setTimeout(() => {
      applyAndDownloadReport(workBook, true);
      setExcelExportInProgress(false);
      setLoadingProgress(0);
    }, 1000);
  };

  return {
    exportAvailability,
    loadingProgress,
  };
};
