import React, { useEffect } from 'react';
import { IMarketplaceTilesConfigHeader } from 'src/typings/marketplace';
import {
  GroupWrapperFiltersAction,
} from 'src/components/profileComponents/Marketplace/components/GroupWrapperActions/GroupWrapperFiltersAction';
import { GroupWrapper } from 'src/components/profileComponents/Marketplace/components/GroupWrapper';
import { useStyles } from 'src/components/profileComponents/Marketplace/components/Tiles/styles';
import {
  GroupWrapperFiltersCollapseState,
} from 'src/components/profileComponents/Marketplace/components/GroupWrapperActions/GroupWrapperFiltersAction/types';
import { translations } from 'src/constants/translations';
import { BoxFormAutocomplete } from 'src/components/profileComponents/FormAutocomplete';
import { IComplexProperty } from 'src/components/profileComponents/Marketplace/types';
import {
  IDevelopmentSpecificationPropChange,
} from 'src/components/profileComponents/Complexes/components/ProjectSpecification/types';

interface Props {
  entity: {item: IMarketplaceTilesConfigHeader}
  lang: string;
  complexProperties: IComplexProperty[]
}
export const TileHeader: React.FC<Props> = ({ entity, lang, complexProperties }) => {
  const mainStyles = useStyles();
  const [collapseState, setCollapseState] = React.useState<GroupWrapperFiltersCollapseState>(GroupWrapperFiltersCollapseState.OPEN);
  const [rocketState, setRocketState] = React.useState<boolean>(entity.item.isActive);
  const [options, setOptions] = React.useState<IDevelopmentSpecificationPropChange<IComplexProperty>[]>([]);

  useEffect(() => {
    if (complexProperties) {
      setOptions(complexProperties.map((item) => ({ label: item.translates?.[lang] || item.systemName, value: item })));
    }
  }, [complexProperties]);

  const rocketChange = (state: boolean) => {
    const tmp = entity;
    tmp.item.isActive = state;
    setRocketState(state);
  };

  return (
    <div>
      <GroupWrapper
        name="Header"
        customStyles={!rocketState ? mainStyles.customGroupWrapperStyles : undefined}
        actions={(
          <GroupWrapperFiltersAction
            collapseState={collapseState}
            rocketState={rocketState}
            onRocket={rocketChange}
            onCollapse={(state) => setCollapseState(state)}
            onDrag={() => {}}
          />
        )}
      >
        {entity.item.property?.systemName && (
        <BoxFormAutocomplete
          label={translations.chooseProperty}
          name="property"
          defaultValue={{ label: entity.item.property?.translates?.[lang] || entity.item.property?.systemName, value: entity.item.property }}
          onChange={(event: IDevelopmentSpecificationPropChange<IComplexProperty>) => {
            const tmp = entity;
            tmp.item.property = event.value;
          }}
          options={options}
        />
        )}
      </GroupWrapper>
    </div>
  );
};
