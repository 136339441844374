import React from 'react';
import { FilterCard } from 'src/components/Marketplace/components/FilterCard';
import { useSelector } from 'react-redux';
import {
  getMarketplaceComplexesTile,
} from 'src/store/marketplace/selectors';
import { useStyles } from './styles';

export const MarketplaceFilterResults: React.FC<any> = () => {
  const styles = useStyles();
  const complexes = useSelector(getMarketplaceComplexesTile);

  return (
    <div className={styles.results}>
      {complexes && complexes.map((item) => (
        <div key={item.id}>
          <FilterCard card={item} />
        </div>
      ))}
    </div>
  );
};
