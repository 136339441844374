import React from 'react';
import {
  accordionStateOptions, AccordionStateType,
  IFieldWithTranslation, IMarketplaceFilterCheckboxAllValues,
  IMarketplaceFilterRange,
  IMarketplaceProperty, sortOrderOptions, sortOrderOptionsMap, sortOrderOptionsNotProjectName, SortOrderType,
} from 'src/components/profileComponents/Marketplace/types';
import {
  IDevelopmentSpecificationPropChange,
} from 'src/components/profileComponents/Complexes/components/ProjectSpecification/types';
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';
import { BoxFormTextField } from 'src/components/profileComponents/FormTextField';
import { translations } from 'src/constants/translations';
import { setInputValue } from 'src/components/profileComponents/Marketplace/helpers';
import { BoxFormAutocomplete } from 'src/components/profileComponents/FormAutocomplete';
import { GroupWrapper } from 'src/components/profileComponents/Marketplace/components/GroupWrapper';
import { useStyles } from 'src/components/profileComponents/Marketplace/components/Filters/components/Tag/styles';
import {
  GroupWrapperFiltersAction,
} from 'src/components/profileComponents/Marketplace/components/GroupWrapperActions/GroupWrapperFiltersAction';

interface ICheckboxProps {
  entity: {item: IMarketplaceFilterCheckboxAllValues},
  lang: string,
  propertyOptions: IDevelopmentSpecificationPropChange<IMarketplaceProperty>[]
  onTrash: () => void,
}

export const Checkbox: React.FC<ICheckboxProps> = ({
  entity, lang, propertyOptions, onTrash,
}) => {
  const styles = useStyles();
  return (
    <GroupWrapper
      name="Checkbox (all values)"
      startIcon={<LabelOutlinedIcon />}
      actions={(
        <GroupWrapperFiltersAction
          onTrash={onTrash}
          onDrag={() => {}}
        />
      )}
    >
      <div className={styles.tagWrapper}>
        <BoxFormTextField
          label={translations.name}
          name="name"
          defaultValue={entity.item.name?.[lang]}
          onChange={(event) => {
            setInputValue<IMarketplaceFilterCheckboxAllValues>({ propLink: entity.item, propName: 'name' }, event.target.value, lang);
          }}
        />
        <BoxFormTextField
          label={translations.callToAction}
          name="callToAction"
          defaultValue={entity.item.callToActionText?.[lang]}
          onChange={(event) => {
            setInputValue<IMarketplaceFilterCheckboxAllValues>({ propLink: entity.item, propName: 'callToActionText' }, event.target.value, lang);
          }}
        />
        <BoxFormAutocomplete
          label={translations.chooseProperty}
          name="property"
          defaultValue={{ label: entity.item.property?.translates?.[lang] || entity.item.property?.systemName, value: entity.item.property }}
          onChange={(event: IDevelopmentSpecificationPropChange<IMarketplaceProperty>) => {
            const tmp = entity;
            tmp.item.property = event.value;
          }}
          options={propertyOptions}
        />
        <BoxFormAutocomplete
          label={translations.sortOrder}
          name="sortOrder"
          defaultValue={
          entity.item.propertyValuesOrder
            ? {
              label: sortOrderOptionsMap[entity.item.propertyValuesOrder],
              value: entity.item.propertyValuesOrder,
            }
            : undefined
        }
          options={sortOrderOptions}
          onChange={(event: IDevelopmentSpecificationPropChange<SortOrderType>) => {
            const tmp = entity;
            tmp.item.propertyValuesOrder = event.value;
          }}
        />
        <BoxFormAutocomplete
          label={translations.accordionState}
          name="accordionState"
          defaultValue={{ label: entity.item.accordionState, value: entity.item.accordionState }}
          options={accordionStateOptions}
          onChange={(event: {label: string, value: AccordionStateType}) => {
            const tmp = entity;
            tmp.item.accordionState = event.value;
          }}
        />
      </div>
    </GroupWrapper>
  );
};
