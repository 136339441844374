import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles(() => createStyles({
  gallery: {
    display: 'flex',
    gap: '20px',
    marginBottom: '16px',
  },
  galleryImageUpload: {
    display: 'flex',
    height: '144px',
    width: '256px',
    padding: '12px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '16px',
    alignSelf: 'stretch',
    border: '1px dashed #6C59E6',
    borderRadius: '8px',
    backgroundColor: '#F7F5FF',
    fontSize: '14px',
    textAlign: 'center',
  },
  galleryImage: {
    height: '144px',
    width: '256px',
    padding: '12px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '16px',
    alignSelf: 'stretch',
    border: '1px solid #6C59E6',
    borderRadius: '8px',
    position: 'relative',
    '& .galleryImageDrag': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .action': {
      width: '32px',
      height: '32px',
      borderRadius: '50%',
      backgroundColor: 'white',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
    '& .galleryImageRemove': {
      width: '20px',
      height: '20px',
      position: 'absolute',
      top: '50%',
      left: '0',
      right: '0',
      marginInline: 'auto',
      transform: 'translate(0, -50%)',
    },
  },
  actionsWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    justifyContent: 'flex-end',
    '& .trash, .drag': {
      color: '#6C59E6',
    },
  },
}));
