import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import Box from '@mui/material/Box';
import { FilterActions } from 'src/components/Marketplace/components/FilterActions';
import { useMarketPlaceFilter } from 'src/components/Marketplace/hooks';
import {
  IMarketplaceFilterLocation, IMarketplaceFilterLocationFilter, IMarketplaceFilterType,
} from 'src/components/profileComponents/Marketplace/types';
import {
  IMarketplaceFilterResponseFilter,
} from 'src/typings/marketplace';
import { useSelector } from 'react-redux';
import { getMarketplaceLanguage } from 'src/store/marketplace/selectors';
import classnames from 'classnames';
import { useStyles } from './styles';

interface Props {
  selection?: IMarketplaceFilterLocation,
  isPopupOpen: boolean,
  // setIsPopupOpen: (state: null | boolean) => void,
  closePopup?: () => void,
  // activeTab: string | undefined,
  // setActiveTab: (value: string | undefined) => void,
  // activeButtons: string[],
  // setActiveButtons: (value: any) => void,
  activeStations: any
  setActiveStations?: (value: any) => void,
  showActions?: boolean,
  customClass?: string
}
export const LocationFilterPopup: React.FC<Props> = ({
  selection,
  isPopupOpen,
  // setIsPopupOpen,
  // activeTab,
  // setActiveTab,
  // activeButtons,
  // setActiveButtons,
  activeStations,
  setActiveStations,
  showActions = true,
  customClass,
  closePopup,
}) => {
  const styles = useStyles();
  const popupRef = useRef(null);
  const {
    getFilterEqualsActiveData, resetFilterWhereConditions, marketplaceFilters, getMarketplaceInitialFilter, setWhereConditions,
  } = useMarketPlaceFilter();
  const lang = useSelector(getMarketplaceLanguage);
  const [activeTab, setActiveTab] = useState<string | undefined>(selection?.filters[0].property?.systemName);
  const [options, setOptions] = useState<{[kye: string]: IMarketplaceFilterResponseFilter}>();
  const [activeButtons, setActiveButtons] = useState<string[]>([]);

  useEffect(() => {
    const result: any = {};
    selection?.filters.forEach((filter) => {
      if (filter.property?.systemName) {
        result[filter.property?.systemName] = getMarketplaceInitialFilter(filter.property?.systemName);
      }
    });
    setOptions(result);
  }, [selection]);

  useEffect(() => {
    const result: string[] = [];
    selection?.filters.forEach((filter) => {
      if (filter.property?.systemName) {
        result.push(..._getFilterDataBySystemName(filter.property?.systemName));
      }
      if (filter.subFilters.length) {
        const subSystemName = filter.subFilters[0].property?.systemName;
        if (subSystemName) {
          result.push(..._getFilterDataBySystemName(subSystemName));
        }
      }
    });
    setActiveButtons(result);
    if (setActiveStations) {
      setActiveStations(result);
    }
  }, [selection, marketplaceFilters]);

  const isActive = useCallback((option: string) => {
    return activeButtons.find((item) => item === option);
  }, [activeButtons]);

  const isDisabled = useCallback((option: string, systemName: string | undefined, subOption?: string, subSystemName?: string): boolean => {
    if (!marketplaceFilters?.length) {
      return false;
    }
    if (subSystemName) {
      return !marketplaceFilters
        .find((prop) => prop.propertyName === systemName)?.values
        .find((prop) => prop.value === option)?.subValues
        .find((prop) => prop.propertyName === subSystemName)?.values
        .find((prop) => prop.value === subOption);
    }
    return !marketplaceFilters
      .find((prop) => prop.propertyName === systemName)?.values
      .find((prop) => prop.value === option);
  }, [selection, marketplaceFilters]);

  const _getFilterDataBySystemName = useCallback((systemName: string): string[] => {
    const result: string[] = [];
    if (systemName) {
      const activeBts = getFilterEqualsActiveData(systemName);
      if (activeBts.length) {
        activeBts.forEach((item) => {
          result.push(item);
        });
      }
    }
    return result;
  }, [getFilterEqualsActiveData, marketplaceFilters]);

  const toggleButton = (value: any) => {
    if (value) {
      setWhereConditions([{
        propertyName: value.systemName,
        oldSystemName: value.oldSystemName,
        equals: value.value,
      }], selection?.id);
    }
  };

  const isChecked = useCallback((subValues: IMarketplaceFilterResponseFilter): boolean => {
    return subValues.values.every((item) => activeButtons.includes(item.value));
  }, [activeButtons]);

  const toggleCheckbox = (filter: IMarketplaceFilterLocationFilter, subValue: string) => {
    if (filter.property?.systemName) {
      const initialFilter = getMarketplaceInitialFilter(filter.property?.systemName);
      if (initialFilter && initialFilter.values.length) {
        const filterValue = initialFilter.values.find((item) => item.value === subValue);
        if (filterValue?.subValues[0].values.length) {
          const subValues = filterValue.subValues[0].values.map((item) => ({
            propertyName: filterValue?.subValues[0].propertyName,
            oldSystemName: filterValue?.subValues[0].oldSystemName,
            equals: item.value,
          }));
          setWhereConditions(subValues, selection?.id);
        }
      }
    }
  };

  const handleReset = () => {
    const result: string[] = [];
    selection?.filters.forEach((filter) => {
      if (filter.property?.systemName) {
        result.push(filter.property?.systemName);
      }
    });
    resetFilterWhereConditions(result);
  };

  const handleApply = () => {
    // setIsPopupOpen(false);
    if (closePopup) closePopup();
  };

  return (
    <Box
      className={`${styles.popup} ${customClass || ''} popup`}
      ref={popupRef}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={styles.tabs}>
        { selection && lang && selection.filters?.map((filter) => (
          <>
            <Box
              key={filter.property?.systemName}
              className={`${styles.tab} ${
                activeTab === filter.property?.systemName ? styles.activeTab : ''
              }`}
              onClick={(e) => {
                e.stopPropagation();
                setActiveTab(filter.property?.systemName);
              }}
            >
              { filter.name?.[lang] }
            </Box>
          </>
        )) }
      </div>

      <div className={styles.lContent}>
        { selection && lang && selection.filters?.map((filter) => (
          <>
            {activeTab === filter.property?.systemName && filter.property?.systemName && options?.[filter.property?.systemName] && (
              <div className={`${filter.subFilters.length ? 'checkboxesWrapper' : 'btnWrapper'}`}>
                {options[filter.property?.systemName].values.map((subFilter) => {
                  if (!filter.subFilters.length) {
                    return (
                      <button
                        key={subFilter.value}
                        className={classnames('lBtn',
                          { active: isActive(subFilter.value) },
                          { disabled: isDisabled(subFilter.value, filter.property?.systemName) })}
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleButton({
                            systemName: filter.property?.systemName,
                            value: subFilter.value,
                            oldSystemName: filter.property?.oldSystemName,
                          });
                        }}
                        type="button"
                      >
                        { subFilter.value }
                      </button>
                    );
                  }
                  if (filter.subFilters.length) {
                    return (
                      <>
                        <div className={styles.checkboxGroup} key={filter.property?.systemName}>
                          <div className={styles.checkboxDiv}>
                            <input
                              readOnly
                              type="checkbox"
                              className={classnames('lCheckbox', { disabled: isDisabled(subFilter.value, filter.property?.systemName) })}
                              id="line"
                              checked={isChecked(subFilter.subValues[0])}
                              onClick={(e: any) => {
                                e.stopPropagation();
                                toggleCheckbox(filter, subFilter.value);
                              }}
                            />
                            <label htmlFor="line">
                              { subFilter.value }
                            </label>
                          </div>
                          { !!subFilter.subValues.length && (
                            <ul className={styles.stationList}>
                              { subFilter.subValues[0].values.map((station) => (
                                <li
                                  key={station.value}
                                  className={classnames('lBtn',
                                    { active: isActive(station.value) },
                                    { disabled: isDisabled(subFilter.value, filter.property?.systemName, station.value, subFilter.subValues[0].propertyName) })}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    toggleButton({ systemName: subFilter.subValues[0].propertyName, value: station.value });
                                  }}
                                  role="presentation"
                                >
                                  { station.value }
                                </li>
                              )) }
                            </ul>
                          ) }
                        </div>
                      </>
                    );
                  }
                  return null;
                }) }
              </div>
            ) }
          </>
        )) }
      </div>

      <FilterActions
        onReset={handleReset}
        onApply={handleApply}
        showActions={showActions}
      />
    </Box>
  );
};
