import { IComplex } from 'src/typings/complexes';

import {
  getComplexDataByGetParams,
} from 'src/api';
import { useGetAuthData } from 'src/hooks/use-get-auth-data';
import { useQuery } from '@tanstack/react-query';

interface ResponseProps {
  members: IComplex[],
  itemsPerPage: number,
  totalItems: number,
  hydraView: any,
}
export const useGetComplexes = (userId: number | undefined, page: number, options: string) => {
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();

  const query = useQuery({
    queryKey: ['complexes', options, userId, page],
    queryFn: async (): Promise<ResponseProps | null> => {
      if (!userId) return null;
      const { data } = await getComplexDataByGetParams(jwt, `${options}&page=${page}`);
      return {
        itemsPerPage: data['hydra:itemsPerPage'],
        totalItems: data['hydra:totalItems'],
        hydraView: data['hydra:view'],
        members: data['hydra:member'],
      };
    },
    enabled: !!userId,
    staleTime: 1000 * 60 * 5,
    cacheTime: 1000 * 60 * 10,
  });

  return query;
};
