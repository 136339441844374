import React, { useCallback, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { RandomInteger } from 'src/helpers/randomInteger';
import {
  IMarketplaceFilterTagSelectedValues,
  IMarketplaceFilterTagSelectedValueSelectedProperty, IMarketplaceFilterType,
} from 'src/components/profileComponents/Marketplace/types';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import { getCurrentMarketplace, getMarketplaceLanguage } from 'src/store/marketplace/selectors';
import { useMarketPlaceFilter } from 'src/components/Marketplace/hooks';

interface Props {
  selection?: IMarketplaceFilterTagSelectedValues,
  activeOptions: IMarketplaceFilterTagSelectedValueSelectedProperty[]
  onOptionClick: (prop: IMarketplaceFilterTagSelectedValueSelectedProperty) => void,
  styles: any,
  view?: 'slider' | 'list'
}
export const FilterOptionSlider: React.FC<Props> = ({
  selection,
  activeOptions,
  onOptionClick,
  styles,
  view = 'slider',
}) => {
  const {
    marketplaceFilters,
  } = useMarketPlaceFilter();
  const marketplace = useSelector(getCurrentMarketplace);
  const lang = useSelector(getMarketplaceLanguage);
  const filterType = IMarketplaceFilterType.SelectedValuesTag;

  const isActive = useCallback((option: IMarketplaceFilterTagSelectedValueSelectedProperty) => {
    return activeOptions.find((item) => item.value === option.value);
  }, [activeOptions]);

  const isDisabled = useCallback((option: IMarketplaceFilterTagSelectedValueSelectedProperty): boolean => {
    if (!marketplaceFilters?.length || !lang) {
      return false;
    }
    return !marketplaceFilters
      .find((prop) => prop.propertyName === option.property?.systemName)?.values
      .find((prop) => prop.value === option.value[lang]);
  }, [selection, marketplaceFilters]);

  return (
    <>
      {view === 'slider' && (
        <Swiper
          className={styles.filterOptions}
          modules={[Navigation]}
          spaceBetween={8}
          slidesPerView="auto"
          navigation
        >
          { lang && marketplace && selection?.selectedProperties.map((option) => (
            <SwiperSlide key={RandomInteger()} className={styles.filterSlide}>
              <button
                className={classnames('lBtn', { active: isActive(option) }, { disabled: isDisabled(option) })}
                onClick={() => onOptionClick(option)}
                type="button"
              >
                <img className={styles.icon} src={option.iconFileUri?.fileUrl} alt="icon" />
                {option.value?.[lang] || option.property?.systemName}
              </button>
            </SwiperSlide>
          ))}
        </Swiper>
      ) }
      { view === 'list' && (
        <div className={styles.list}>
          { selection && lang && selection.selectedProperties.map((option) => (
            <button
              className={classnames('lBtn', { active: isActive(option) }, { disabled: isDisabled(option) })}
              onClick={() => onOptionClick(option)}
              type="button"
            >
              <img className={styles.icon} src={option.iconFileUri?.fileUrl} alt="icon" />
              {option.value?.[lang] || option.property?.systemName}
            </button>
          ))}
        </div>
      )}
    </>
  );
};
