import {
  useCallback, useEffect, useState,
} from 'react';
import {
  IMarketplace,
  IMarketplaceProperty, Option,
} from 'src/components/profileComponents/Marketplace/types';
import {
  getMarketplaceById,
  getMarketplaceLanguageObjects,
  getMarketplaceProperties,
  getMarketplaces,
} from 'src/api/marketplace';
import { useGetAuthData } from 'src/hooks/use-get-auth-data';
import { IMarketplaceLanguageObjects, IMarketplaceLanguageObjectsData } from 'src/typings/marketplace';

export const useMarketplaces = () => {
  const [marketplaces, setMarketplaces] = useState<IMarketplace[]>([]);
  const [marketplace, setMarketplace] = useState<IMarketplace>();
  const [marketplaceProperties, setMarketplaceProperties] = useState<IMarketplaceProperty[]>([]);
  const [marketplaceLanguageObjects, setMarketplaceLanguageObjects] = useState<IMarketplaceLanguageObjects | undefined>();
  const [marketplaceLanguageObjectsOptions, setMarketplaceLanguageObjectsOptions] = useState<Option[]>([]);
  const [marketplaceLanguageObjectsIsLoading, setMarketplaceLanguageObjectsIsLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [marketplacePropertiesIsLoading, setMarketplacePropertiesIsLoading] = useState<boolean>(false);
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();

  const fetchMarketplaces = () => {
    setIsLoading(true);
    getMarketplaces(jwt)
      .then((res) => {
        const { data } = res;
        setMarketplaces(data['hydra:member']);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const fetchMarketplace = (id: number) => {
    setIsLoading(true);
    getMarketplaceById(jwt, id)
      .then((res) => {
        setMarketplace(res.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const fetchMarketplaceProperties = (id: number) => {
    setMarketplacePropertiesIsLoading(true);
    getMarketplaceProperties(jwt, id)
      .then((res) => {
        const { data } = res;
        setMarketplaceProperties(data['hydra:member']);
        setMarketplacePropertiesIsLoading(false);
      }).catch(() => {
        setMarketplacePropertiesIsLoading(false);
      });
  };

  const fetchMarketplaceLanguageObjects = (objectData: IMarketplaceLanguageObjectsData, lang: string) => {
    setMarketplaceLanguageObjectsIsLoading(true);
    getMarketplaceLanguageObjects(jwt, objectData)
      .then((res) => {
        const { data } = res;
        setMarketplaceLanguageObjects(data);
        const options: Option[] = [];
        objectData.findProperties.forEach((property) => {
          data[property].forEach((propertyValue) => {
            if (propertyValue[lang]) {
              options.push({ label: propertyValue[lang], value: propertyValue[lang] });
            }
          });
        });
        setMarketplaceLanguageObjectsOptions(options);
        setMarketplaceLanguageObjectsIsLoading(false);
      }).catch(() => {
        setMarketplacePropertiesIsLoading(false);
      });
  };

  const requestMarketplaceLanguageObjects = (objectData: IMarketplaceLanguageObjectsData): Promise<{data: IMarketplaceLanguageObjects}> => {
    return new Promise((resolve, reject) => {
      getMarketplaceLanguageObjects(jwt, objectData)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  };

  const getMarketplace = useCallback((id: number) => {
    return marketplaces.find((item) => item.id === id);
  }, [marketplaces]);

  useEffect(() => {
    fetchMarketplaces();
  }, [jwt]);

  return {
    marketplaces,
    marketplace,
    getMarketplace,
    marketplaceProperties,
    isLoading,
    marketplacePropertiesIsLoading,
    marketplaceLanguageObjectsIsLoading,
    marketplaceLanguageObjects,
    marketplaceLanguageObjectsOptions,
    fetchMarketplaces,
    fetchMarketplace,
    fetchMarketplaceProperties,
    fetchMarketplaceLanguageObjects,
    setMarketplaceLanguageObjectsOptions,
    requestMarketplaceLanguageObjects,
    setMarketplaceProperties,
    setMarketplaces,
  };
};
