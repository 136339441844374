import React from 'react';
import { translations } from 'src/constants/translations';
import { Drag } from 'src/components/ApartmentPageV2/LayoutApartmentItem/components/DragIcon';
import { SvgTrash } from 'src/components/ApartmentPageV2/LayoutApartmentItem/components/SvgTrash';
import { IMarketplaceTilesConfigComplex } from 'src/typings/marketplace';
import Box from '@mui/material/Box';
import { useStyles } from './styles';

interface Props {
  complex: {item: IMarketplaceTilesConfigComplex}
  onTrash: () => void;
}
export const GalleryTile: React.FC<Props> = ({ complex, onTrash }) => {
  const styles = useStyles();
  return (
    <div className={styles.gallery}>
      <div className={styles.galleryImageUpload}>{ translations.galleryImageUploadText }</div>
      <div
        className={styles.galleryImage}
        style={{
          backgroundImage: `url("${complex.item.complex.previewFileUri?.fileUrl || ''}")`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <div className={styles.actionsWrapper}>
          <div className="galleryImageDrag">
            <Box className="action" onClick={onTrash}>
              <Drag />
            </Box>
          </div>
          <div className="galleryImageRemove">
            <div className="action">
              <SvgTrash width="20" height="20" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
