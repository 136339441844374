import React, { useCallback, useEffect } from 'react';
import { GroupWrapper } from 'src/components/profileComponents/Marketplace/components/GroupWrapper';
import { translations } from 'src/constants/translations';
import { primarySelectionTags } from 'src/components/profileComponents/Marketplace/components/Filters/constants';
import {
  ActionButtons,
} from 'src/components/profileComponents/Marketplace/components/Filters/components/ActionButtons';
import { SubGroupWrapper } from 'src/components/profileComponents/Marketplace/components/SubGroupWrapper';
import {
  ComplexTileTags,
} from 'src/components/profileComponents/Marketplace/components/Tiles/compopents/ComplexTileTags';
import { IMarketplaceTilesConfigComplex } from 'src/typings/marketplace';
import { ReactSortable, Sortable, Store } from 'react-sortablejs';
import { GalleryTile } from 'src/components/profileComponents/Marketplace/components/Tiles/compopents/GalleryTile';
import {
  GroupWrapperFiltersAction,
} from 'src/components/profileComponents/Marketplace/components/GroupWrapperActions/GroupWrapperFiltersAction';
import {
  GroupWrapperFiltersCollapseState,
} from 'src/components/profileComponents/Marketplace/components/GroupWrapperActions/GroupWrapperFiltersAction/types';
import { useStyles as mainUseStyles } from 'src/components/profileComponents/Marketplace/components/Tiles/styles';
import { useStyles } from './styles';

interface Props {
  entity: { item: IMarketplaceTilesConfigComplex };
  lang: string;
  removeTag: (tagIndex: number) => void;
  addTag: () => void;
  updateComplex: (complex: IMarketplaceTilesConfigComplex) => void;
  isDragging: boolean;
}
export const ComplexTile: React.FC<Props> = ({
  entity, lang, removeTag, addTag, updateComplex, isDragging,
}) => {
  const styles = useStyles();
  const mainStyles = mainUseStyles();
  const [collapseState, setCollapseState] = React.useState<GroupWrapperFiltersCollapseState>(GroupWrapperFiltersCollapseState.OPEN);
  const [rocketState, setRocketState] = React.useState<boolean>(entity.item.isActive);
  const onTrash = () => {};

  useEffect(() => {
    setCollapseState(isDragging ? GroupWrapperFiltersCollapseState.CLOSED : GroupWrapperFiltersCollapseState.OPEN);
  }, [isDragging]);

  const rocketChange = (state: boolean) => {
    const tmp = entity;
    tmp.item.isActive = state;
    setRocketState(state);
  };

  return (
    <div className={styles.complexTileWrapper}>
      <GroupWrapper
        name={entity.item.complex.title?.[lang || '']}
        customStyles={!rocketState ? mainStyles.customGroupWrapperStyles : undefined}
        actions={(
          <GroupWrapperFiltersAction
            collapseState={collapseState}
            rocketState={rocketState}
            onDrag={() => {}}
            onRocket={rocketChange}
            onCollapse={(state) => {
              if (!isDragging) {
                setCollapseState(state);
              }
            }}
          />
        )}
      >
        {collapseState === GroupWrapperFiltersCollapseState.OPEN && (
          <>
            <div className={styles.title}>
              { translations.editGallery }
            </div>
            <GalleryTile complex={{ item: entity.item }} onTrash={onTrash} />
            <div className={styles.title}>
              { translations.addProjectTileTags }
            </div>
            <div className={styles.tileTag}>
              <ActionButtons
                buttons={primarySelectionTags}
                onClick={addTag}
              />
            </div>
            <ReactSortable
              animation={150}
              handle=".drag-handle"
              list={entity.item.tags.map((item) => ({ ...item, chosen: false }))}
              setList={(newList, sortable: Sortable | null, store: Store) => {
                if (store && store.dragging && store.dragging.props && JSON.stringify(store.dragging.props.list) !== JSON.stringify(newList)) {
                  const orderedList = newList.map((item, index) => ({ ...item, sortOrder: index }));
                  updateComplex({ ...entity.item, tags: orderedList });
                }
              }}
            >
              {entity.item.tags.map((tag, tagIndex) => (
                <div key={tag.id}>
                  <SubGroupWrapper
                    name="Tile tag"
                    onTrash={() => removeTag(tagIndex)}
                  >
                    <ComplexTileTags
                      entity={{ item: tag }}
                      lang={lang}
                    />
                  </SubGroupWrapper>
                </div>
              ))}
            </ReactSortable>
          </>
        )}

      </GroupWrapper>
    </div>
  );
};
