import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  wrapper: {
    padding: '32px 64px',
    maxWidth: '1440px',
    margin: 'auto',
    fontFamily: 'Inter, sans-serif !important',
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
    position: 'relative',

    fontSize: '16px',

    '& button': {
      fontFamily: 'inherit',
      backgroundColor: 'transparent',
      border: 'none',
      padding: 0,
    },

    '& ul': {
      padding: 0,
      margin: 0,
      '& li': {
        listStyle: 'none',
      },
    },

    '& img': {
      display: 'block',
    },

    '& .disabled': {
      opacity: 0.5,
      pointerEvents: 'none',
    },

    [theme.breakpoints.down('lg')]: {
      padding: '20px',
    },

    [theme.breakpoints.down('md')]: {
      '& .lBtn': {
        fontSize: '14px',
        minHeight: '44px',
        height: '44px',
        padding: '0 15px',
      },
      '& .lCheckbox + label': {
        fontSize: '14px',
      },

      '& .lCheckbox': {
        width: '22px',
        height: '22px',
      },
    },

    '& .lBtn': {
      gap: '8px',
      color: '#1e1e1eb2',
      border: '1px solid #1e1e1e1a',
      cursor: 'pointer',
      display: 'flex',
      padding: '0 20px',
      fontSize: '16px',
      fontWeight: '400',
      background: '#fff',
      transition: 'all 0.3s ease',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      borderRadius: '50px',
      maxHeight: '52px',
      minHeight: '52px',
      height: '52px',
      boxSizing: 'border-box',
    },

    '& .lBtn:hover': {
      color: '#1e1e1eb2',
      background: '#fff',
      border: '1px solid #1e1e1eb2',
    },

    '& .lBtn.active': {
      color: '#1e1e1e',
      background: '#fff',
      border: '1px solid #1e1e1e',
    },

    '& .lBtn.active:hover': {
      background: '#1e1e1e0d',
    },

    '& .lBtn svg': {
      width: '20px',
      height: '20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    '& .lBtn.active svg': {
      color: '#000',
      transition: 'color 0.3s',
    },
    '& .lContent': {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      gap: '8px',
    },
    '& .lCheckbox': {
      width: '20px',
      border: '2px solid #1e1e1e4d',
      cursor: 'pointer',
      height: '20px',
      margin: '0',
      position: 'relative',
      background: '#fff',
      transition: 'all 0.3s ease',
      borderRadius: '2px',
      '-webkit-appearance': 'none',
    },

    '& .lCheckbox:checked': {
      borderColor: '#ed1c24',
      backgroundColor: '#ed1c24',
    },

    '& .lCheckbox:checked::after': {
      content: '""',
      inset: '-3px 0 0 0',
      width: '7px',
      border: 'solid white',
      height: '12px',
      margin: 'auto',
      position: 'absolute',
      transform: 'rotate(45deg)',
      borderWidth: '0 2px 2px 0',
    },

    '& .lCheckbox + label': {
      lineHeight: '20px',
      pointerEvents: 'none',
    },
    '&.loading': {
      pointerEvents: 'none',
    },
  },
  layoutLoader: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: '0',
    top: '0',
    zIndex: '9999',
    backgroundColor: 'rgba(255,255,255,.5)',
    pointerEvents: 'none',
    '-webkit-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    'user-select': 'none',
  },
}));
