import React, { useState } from 'react';
import { IMarketplaceTilesConfigComplexTag, IMarketplaceTilesLocationValue } from 'src/typings/marketplace';
import { readURL } from 'src/helpers';
import {
  UploadMarketplace,
} from 'src/components/profileComponents/Marketplace/components/Filters/components/UploadMarketplace';
import { translations } from 'src/constants/translations';
import { setInputValue } from 'src/components/profileComponents/Marketplace/helpers';
import { BoxFormTextField } from 'src/components/profileComponents/FormTextField';
import { IOSSwitch } from 'src/components/SwitchIos';
import {
  useStyles,
} from './styles';

interface IProps {
  entity: { item: IMarketplaceTilesLocationValue };
  lang: string;
}
export const TileLocationDistrict: React.FC<IProps> = ({ entity, lang }) => {
  const styles = useStyles();
  const [image, setImage] = useState<string | null>('' || null);
  return (
    <>
      <div className={styles.tileLocationDistrictWrapper}>
        <UploadMarketplace
          register={() => {}}
          registerName=""
          image={image}
          onUpload={async (file, response) => {
            const tmp = entity;
            tmp.item.iconFileUri = response.data[0];
            const base64: string = await readURL(file);
            if (base64) {
              setImage(base64);
            }
          }}
        />
        <BoxFormTextField
          label={translations.prefix}
          name="prefix"
          defaultValue={entity.item.prefix?.[lang]}
          onChange={(event) => {
            setInputValue<IMarketplaceTilesLocationValue>({ propLink: entity.item, propName: 'prefix' }, event.target.value, lang);
          }}
        />
        <BoxFormTextField
          label={translations.suffix}
          name="prefix"
          defaultValue={entity.item.suffix?.[lang]}
          onChange={(event) => {
            setInputValue<IMarketplaceTilesLocationValue>({ propLink: entity.item, propName: 'suffix' }, event.target.value, lang);
          }}
        />
      </div>
    </>
  );
};
