import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles(() => createStyles({
  groupWrapperActions: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  },
  actionWrapper: {
    width: '20px',
    height: '20px',
    cursor: 'pointer',
    color: '#6C59E6',
  },
}));
