import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { translations } from 'src/constants/translations';
import { ButtonApp } from 'src/components/Button';
import { Breadcrumbs, ICrumbs } from 'src/components/profileComponents/Breadcrumbs';
import { pathList } from 'src/containers/Profile/routeList';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useParams } from 'react-router-dom';
import { useMarketplaces } from 'src/hooks/marketplace';
import { Loader } from 'src/components/Loader';
import { ReactSortable, Sortable, Store } from 'react-sortablejs';
import { ComplexTile } from 'src/components/profileComponents/Marketplace/components/Tiles/compopents';
import { useMarketplaceTile } from 'src/hooks/marketplace/useMarketplaceTile';
import {
  IMarketplaceComplexTileConfig,
  IMarketplaceTilesConfigComplex,
} from 'src/typings/marketplace';
import { RandomInteger } from 'src/helpers/randomInteger';
import { TileHeader } from 'src/components/profileComponents/Marketplace/components/Tiles/compopents/TileHeader';
import { tilesInitValues } from 'src/components/profileComponents/Marketplace/components/Tiles/constatns';
import { TileLocation } from 'src/components/profileComponents/Marketplace/components/Tiles/compopents/TileLocation';
import {
  TileMinMaxRange,
} from 'src/components/profileComponents/Marketplace/components/Tiles/compopents/TileMinMaxRange';
import {
  TileProjectTimeline,
} from 'src/components/profileComponents/Marketplace/components/Tiles/compopents/TileProjectTimeline';
import {
  TileUnitsBreakdown,
} from 'src/components/profileComponents/Marketplace/components/Tiles/compopents/TileUnitsBreakdown';
import {
  TileAvailabilityCounter,
} from 'src/components/profileComponents/Marketplace/components/Tiles/compopents/TileAvailabilityCounter';
import { useStyles } from './styles';

export const Tiles: React.FC<{}> = () => {
  const { id }: { id: string } = useParams();
  const { fetchMarketplace, marketplace } = useMarketplaces();
  const { tilesConfig, saveTilesConfig } = useMarketplaceTile(marketplace);
  const [config, setConfig] = useState<IMarketplaceComplexTileConfig>(tilesInitValues);
  const [collapseAllComplexes, setCollapseAllComplexes] = useState<boolean>(false);
  const styles = useStyles();

  useEffect(() => {
    fetchMarketplace(Number(id));
  }, [id]);

  useEffect(() => {
    if (tilesConfig) {
      setConfig(tilesConfig);
    }
  }, [tilesConfig]);

  const {
    handleSubmit,
  } = useForm({
    resolver: yupResolver(yup.object().shape({})),
    mode: 'onChange',
  });

  const onSave = () => {
    saveTilesConfig.mutate({ dataToSave: config });
  };

  const removeTag = (configIndex: number, tagIndex: number) => {
    const tmp = { ...config };
    tmp.complexOrderings[configIndex].tags.splice(tagIndex, 1);
    setConfig(tmp);
  };

  const addTag = (configIndex: number) => {
    const tmp = { ...config };
    tmp.complexOrderings[configIndex].tags.push({
      id: RandomInteger(),
      sortOrder: tmp.complexOrderings[configIndex].tags.length,
      text: null,
      iconFileUri: null,
    });
    setConfig(tmp);
  };

  const updateComplex = (complex: IMarketplaceTilesConfigComplex) => {
    const tmp = { ...config };
    const foundComplex = tmp.complexOrderings.find((item) => item.id === complex.id);
    if (foundComplex) {
      const foundComplexIndex = tmp.complexOrderings.indexOf(foundComplex);
      tmp.complexOrderings.splice(foundComplexIndex, 1, complex);
      setConfig(tmp);
    }
  };

  if (!marketplace || !config) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
        mb={4}
      >
        <Loader />
      </Box>
    );
  }

  const crumbs: ICrumbs[] = [
    {
      name: translations.marketplace,
      url: `/profile/${pathList.marketplace}`,
    },
    {
      name: `${marketplace?.name[marketplace?.language]} set-up`,
      url: `/profile/${pathList.marketplace}/${marketplace?.id}`,
    },
    {
      name: 'Projects tile',
    },
  ];
  return (
    <Box py={5} px={4}>
      <form onSubmit={handleSubmit(onSave)}>
        <Box mb={8} display="flex" flexWrap="nowrap">
          <Box fontSize="32px" mb={1} fontWeight={700} mr="auto">
            { translations.projectsTitle }
          </Box>
          <div>
            <ButtonApp
              text={translations.saveChanges}
              color="primary"
              variant="contained"
              type="submit"
            />
          </div>
        </Box>
        <Box mb={5}>
          <Breadcrumbs crumbs={crumbs} />
        </Box>
        <div className={styles.tilesWrapper}>
          { config && marketplace && (
            <>
              <div className="projects-order">
                <Box fontSize="24px" mb={8} fontWeight={700}>
                  { translations.projectsOrder }
                </Box>
                <ReactSortable
                  animation={150}
                  handle=".drag-handle"
                  list={config.complexOrderings.map((entity) => ({ ...entity, chosen: false }))}
                  onStart={() => {
                    setCollapseAllComplexes(true);
                  }}
                  onUnchoose={() => {
                    setCollapseAllComplexes(false);
                  }}
                  setList={(newList, sortable: Sortable | null, store: Store) => {
                    if (store && store.dragging && store.dragging.props && JSON.stringify(store.dragging.props.list) !== JSON.stringify(newList)) {
                      const orderedList = newList.map((item, index) => ({ ...item, sortOrder: index }));
                      setConfig({ ...config, complexOrderings: orderedList });
                    }
                  }}
                >
                  { config.complexOrderings.map((complexOrdering, index) => {
                    return (
                      <div key={complexOrdering.id}>
                        { complexOrdering && (
                        <ComplexTile
                          entity={{ item: complexOrdering }}
                          isDragging={collapseAllComplexes}
                          lang={marketplace.language}
                          removeTag={(tagIndex) => removeTag(index, tagIndex)}
                          addTag={() => addTag(index)}
                          updateComplex={updateComplex}
                        />
                        ) }
                      </div>
                    );
                  }) }
                </ReactSortable>
              </div>
              <div>
                <Box fontSize="24px" mb={8} fontWeight={700}>
                  { translations.projectsDescription }
                </Box>
                <TileHeader
                  entity={{ item: config.header }}
                  lang={marketplace.language}
                  complexProperties={marketplace.complexProperties}
                />
                <TileLocation
                  entity={{ item: config.location }}
                  lang={marketplace.language}
                />
                {/* <TileMinMaxRange */}
                {/* entity={{ item: config.minMaxRange }} */}
                {/* lang={marketplace.language} */}
                {/* placementProperties={marketplace.placementProperties} */}
                {/* /> */}
                {/* <TileProjectTimeline */}
                {/*  entity={{ item: config.projectTimeline }} */}
                {/*  lang={marketplace.language} */}
                {/* /> */}
                {/* <TileUnitsBreakdown */}
                {/*  entity={{ item: config.unitsBreakdown }} */}
                {/*  lang={marketplace.language} */}
                {/* /> */}
                {/* <TileAvailabilityCounter */}
                {/*  entity={{ item: config.availabilityCounter }} */}
                {/*  lang={marketplace.language} */}
                {/* /> */}
              </div>
            </>
          ) }
        </div>
      </form>
    </Box>
  );
};
