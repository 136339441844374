import React from 'react';
import { GroupWrapper } from 'src/components/profileComponents/Marketplace/components/GroupWrapper';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import { BoxFormTextField } from 'src/components/profileComponents/FormTextField';
import { translations } from 'src/constants/translations';
import { BoxFormAutocomplete } from 'src/components/profileComponents/FormAutocomplete';
import {
  accordionStateOptions, AccordionStateType, IFieldWithTranslation,
  IMarketplaceFilterLocation,
  IMarketplaceModulePrimarySectionType,
} from 'src/components/profileComponents/Marketplace/types';
import { setInputValue } from 'src/components/profileComponents/Marketplace/helpers';
import {
  GroupWrapperFiltersAction,
} from 'src/components/profileComponents/Marketplace/components/GroupWrapperActions/GroupWrapperFiltersAction';
import { useStyles } from './styles';

interface LocationGroupProps {
  entity: {item: IMarketplaceModulePrimarySectionType},
  lang: string
  onTrash: () => void
}

export const LocationGroup: React.FC<LocationGroupProps> = ({
  entity, lang, onTrash,
}) => {
  const styles = useStyles();
  return (
    <>
      <GroupWrapper
        name="Locations"
        startIcon={<FmdGoodOutlinedIcon />}
        actions={(
          <GroupWrapperFiltersAction
            onTrash={onTrash}
            onDrag={() => {}}
          />
        )}
      >
        <div className={styles.locationsFields}>
          <BoxFormTextField
            label={translations.projectName}
            name="name"
            defaultValue={entity.item.name?.[lang]}
            onChange={(event) => {
              setInputValue<IMarketplaceModulePrimarySectionType>({ propLink: entity.item, propName: 'name' }, event.target.value, lang);
            }}
          />
          <BoxFormTextField
            label={translations.callToAction}
            name="callToAction"
            defaultValue={entity.item.callToActionText ? entity.item.callToActionText[lang || ''] : ''}
            onChange={(event) => {
              setInputValue<IMarketplaceModulePrimarySectionType>({ propLink: entity.item, propName: 'callToActionText' }, event.target.value, lang);
            }}
          />
          <BoxFormAutocomplete
            label={translations.accordionState}
            name="accordionState"
            defaultValue={{ label: entity.item.accordionState, value: entity.item.accordionState }}
            options={accordionStateOptions}
            onChange={(event: {label: string, value: AccordionStateType}) => {
              const tmp = entity;
              tmp.item.accordionState = event.value;
            }}
          />
        </div>
      </GroupWrapper>
    </>
  );
};
