import { useMemo, useState } from 'react';
import { isNumber } from 'lodash';

interface IPaginationSettings<T> {
  list: T[],
  totalItems?: number,
  itemsPerPage?: number,
}
export const usePagination = <T>(settings: IPaginationSettings<T>) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = useMemo(() => {
    if (Array.isArray(settings.list)) {
      return settings.list.length;
    } if (typeof settings.list === 'object') {
      return Object.keys(settings.list);
    }
    return 1;
  }, [settings]);

  const totalPages = useMemo(() => {
    if (isNumber(settings.totalItems) && isNumber(settings.itemsPerPage)) {
      return Math.ceil(settings?.totalItems / settings?.itemsPerPage);
    }
    return 1;
  }, [settings]);

  return {
    currentPage,
    itemsPerPage,
    totalPages,
    setCurrentPage,
  };
};
