import React from 'react';
import { GroupWrapperProps } from 'src/components/profileComponents/Marketplace/components/GroupWrapper/types';
import { useStyles } from './styles';

export const GroupWrapper: React.FC<GroupWrapperProps> = ({
  children, startIcon, name, actions, customStyles,
}) => {
  const styles = useStyles();
  return (
    <>
      <div className={`${styles.groupWrapper} ${customStyles}`}>
        <div className={styles.wrapper}>
          <div className={styles.nameWrapper}>
            {startIcon && (
              <div className={styles.icon}>{ startIcon }</div>
            ) }
            <div className={styles.name}>{ name }</div>
          </div>
          <div className={styles.actionsWrapper}>
            {actions && (
              <>{ actions }</>
            )}
          </div>
        </div>
      </div>
      <div className={styles.body}>
        { children }
      </div>
    </>
  );
};
