/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { requestSelectors } from 'src/store/requests/selectors';
import { getAvailableComplexes } from 'src/store/crosstable/selectors';
import { CRMNames } from 'src/typings/app';
import { getCrmData } from 'src/store/app/selectors';
import { translations } from 'src/constants/translations';
import { useGetAuthData, useSetDocumentTitle } from 'src/hooks';
import { IComplex } from 'src/typings/complexes';
import { SearchField } from 'src/components/SearchField';
import { TypeOfRole } from 'src/typings/user';
import { Loader } from 'src/components/Loader';
import { AppPagination } from 'src/components/AppPagination';
import { usePagination } from 'src/hooks/usePagination';
import { AvailabilityReportModal } from 'src/components/profileComponents/Complexes/components/AvailabilityReportModal';
import { toQueryString } from 'src/helpers/buildQueryParamsString';
import { useQueryClient } from '@tanstack/react-query';
import { useGetComplexes } from 'src/hooks/useGetComplexes';
import { getComplexDataByGetParams } from 'src/api';
import { ComplexItem } from './components/ComplexItem';
import { useStyles } from './styles';
import { ButtonApp } from '../../Button';
import { ComplexModal } from './components/ComplexModal';

export const Complexes = () => {
  const styles = useStyles({});
  const crmData = useSelector(getCrmData);
  const user = useSelector(requestSelectors.user.getUserData);
  const availableComplexes = useSelector(getAvailableComplexes);
  const [page, setPage] = useState<number>(1);
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();
  const {
    data,
    isLoading,
  } = useGetComplexes(!crmData ? user?.id : undefined, page, toQueryString({
    users: user?.id,
    properties: {
      0: 'id',
      1: 'title',
      2: 'previewFileUri',
      3: 'logoFileUri',
      4: 'complexShortName',
      5: 'language',
      developers: [
        'iconUri',
        'logoFileUri',
      ],
    },
  }));
  const { members, totalItems, itemsPerPage } = data || {};
  const isLargeScreen = useMediaQuery('(min-width:1200px)');
  const queryClient = useQueryClient();

  const [modalOpen, setModalOpen] = useState(false);
  const [availabilityModalOpen, setAvailabilityModalOpen] = useState(false);
  const [editedComplex, setEditedComplex] = useState<IComplex|undefined>();

  const handleAddComplex = () => {
    setEditedComplex(undefined);
    setModalOpen(true);
  };

  const [filteredComplexes, setFilteredComplexes] = useState<IComplex[] | null>(null);
  const { totalPages } = usePagination<IComplex>({ list: availableComplexes || members || [], totalItems: availableComplexes?.length || totalItems, itemsPerPage });

  useSetDocumentTitle(translations.complexesLink);

  useEffect(() => {
    if (crmData?.crm === CRMNames.bitrix || crmData?.crm === CRMNames.creatio) {
      setFilteredComplexes(availableComplexes || null);
    } else {
      setFilteredComplexes(availableComplexes || members || null);
    }
  }, [crmData, availableComplexes, members]);

  const handleChangeComplexes = async (searchTerm: string) => {
    let key;
    let updater;
    if (crmData) {
      switch (crmData.crm) {
        case CRMNames.creatio: {
          const queryString = `users.email=${crmData.userEmail}&batchSearch=${searchTerm.toLowerCase()}`;
          key = ['complex', queryString, user?.id, page];
          updater = await getComplexDataByGetParams(jwt, queryString);
          break;
        }
        case CRMNames.bitrix: {
          const queryString = crmData.categoryId
            ? `users.email=${crmData.userEmail}&bitrixCategories=${crmData.categoryId}&developers.bitrixProject.domain=${crmData.domain}&batchSearch=${searchTerm.toLowerCase()}`
            : `users.email=${crmData.userEmail}&developers.bitrixProject.domain=${crmData.domain}&batchSearch=${searchTerm.toLowerCase()}`;
          key = ['complex', queryString, user?.id, page];
          updater = await getComplexDataByGetParams(jwt, queryString);
          break;
        }
        default: { return null; }
      }
      if (key && updater) {
        queryClient.setQueryData(
          key,
          updater.data['hydra:member'],
        );
      }
    } else {
      const queryString = `users=${user?.id}&batchSearch=${searchTerm.toLowerCase()}`;
      updater = await getComplexDataByGetParams(jwt, queryString);
      queryClient.setQueryData(
        ['complex', queryString, user?.id, page],
        updater.data['hydra:member'],
      );
    }
    setFilteredComplexes(updater.data['hydra:member']);
    return null;
  };

  return (
    <Box py={8} px={4} height="100%" className={styles.wrapper}>
      <Box mb={8} display="flex" flexWrap="nowrap">
        <Box fontSize="32px" mr="24px" fontWeight={700}>
          {translations.projectsLink}
        </Box>
        <Box width={340} mr="auto">
          <SearchField
            handleChange={handleChangeComplexes}
            placeholder={`${translations.project} ${translations.or} ${translations.developer}`}
          />
        </Box>
        {!crmData && user?.role?.['@id'] === TypeOfRole.superadmin && (
          <Box ml="24px">
            <ButtonApp
              text={translations.createProject}
              onClick={handleAddComplex}
              color="primary"
              variant="contained"
              type="submit"
              className={styles.btn}
            />
          </Box>
        )}
        {!crmData && user?.role && [TypeOfRole.superadmin, TypeOfRole.supervisor].includes(user.role['@id']) && (
          <Box ml="24px">
            <ButtonApp
              text={translations.exportAvailability}
              onClick={() => setAvailabilityModalOpen(true)}
              color="primary"
              variant="contained"
              type="submit"
              className={styles.btn}
            />
          </Box>
        )}
      </Box>
      <Box>
        {filteredComplexes && !!filteredComplexes.length && (!isLoading || filteredComplexes?.length) && (
          <>
            <Grid container spacing={6} pb="48px">
              {filteredComplexes.map((complex: IComplex) => {
                return (
                  <Grid item xs={isLargeScreen ? 3 : 4} key={`complex${complex.id}`}>
                    <ComplexItem complex={complex} />
                  </Grid>
                );
              })}
            </Grid>
          </>
        )}
        {(isLoading && !filteredComplexes?.length) && (
          <Box
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            pb={8}
          >
            <Loader />
          </Box>
        )}
        {totalPages > 1 && (
        <Box display="flex" justifyContent="center" pb={8} hidden={isLoading || !filteredComplexes?.length}>
          <AppPagination
            count={totalPages}
            page={page}
            onChange={(event, nextPage) => setPage(nextPage)}
          />
        </Box>
        ) }
        { filteredComplexes && !filteredComplexes.length && !isLoading && (
          <Box display="flex" justifyContent="center">
            {translations.notFoundComplexes}
          </Box>
        )}
      </Box>
      {availabilityModalOpen && (
        <AvailabilityReportModal
          isOpen={availabilityModalOpen}
          setModalOpen={setAvailabilityModalOpen}
        />
      )}
      {modalOpen && (
        <ComplexModal
          isOpen={modalOpen}
          handleChangeOpen={setModalOpen}
          refreshComplexes={() => {}}
          complex={editedComplex}
        />
      )}
    </Box>
  );
};
