import React from 'react';
import {
  IMarketplaceTilesLocation,
  IMarketplaceTilesLocationSubValue,
  IMarketplaceTilesType,
} from 'src/typings/marketplace';
import {
  GroupWrapperFiltersAction,
} from 'src/components/profileComponents/Marketplace/components/GroupWrapperActions/GroupWrapperFiltersAction';
import { GroupWrapper } from 'src/components/profileComponents/Marketplace/components/GroupWrapper';
import {
  GroupWrapperFiltersCollapseState,
} from 'src/components/profileComponents/Marketplace/components/GroupWrapperActions/GroupWrapperFiltersAction/types';
import { SubGroupWrapper } from 'src/components/profileComponents/Marketplace/components/SubGroupWrapper';
import {
  TileLocationCity,
} from 'src/components/profileComponents/Marketplace/components/Tiles/compopents/TileLocationCity';
import Box from '@mui/material/Box';
import {
  TileLocationMetroStation,
} from 'src/components/profileComponents/Marketplace/components/Tiles/compopents/TileLocationMetroStation';
import {
  TileLocationDistrict,
} from 'src/components/profileComponents/Marketplace/components/Tiles/compopents/TileLocationDistrict';
import { useStyles as mainUseStyles } from 'src/components/profileComponents/Marketplace/components/Tiles/styles';
import { translations } from 'src/constants/translations';
import { IOSSwitch } from 'src/components/SwitchIos';
import { setInputValue } from 'src/components/profileComponents/Marketplace/helpers';
import { useStyles } from './styles';

interface Props {
  entity: { item: IMarketplaceTilesLocation}
  lang: string;
}
export const TileLocation: React.FC<Props> = ({ entity, lang }) => {
  const styles = useStyles();
  const mainStyles = mainUseStyles();
  const [collapseState, setCollapseState] = React.useState<GroupWrapperFiltersCollapseState>(GroupWrapperFiltersCollapseState.OPEN);
  const [rocketState, setRocketState] = React.useState<boolean>(entity.item.isActive);

  const rocketChange = (state: boolean, _entity: { item: { isActive: boolean } }) => {
    const tmp = _entity;
    tmp.item.isActive = state;
    setRocketState(state);
  };

  return (
    <>
      <GroupWrapper
        name="Location"
        customStyles={!rocketState ? mainStyles.customGroupWrapperStyles : undefined}
        actions={(
          <GroupWrapperFiltersAction
            collapseState={collapseState}
            rocketState={rocketState}
            onRocket={(state) => rocketChange(state, entity)}
            onCollapse={(state) => setCollapseState(state)}
            onDrag={() => {}}
          />
        )}
      >
        <div className={styles.description}>
          <p>{translations.locationDescription}</p>
        </div>
        {entity && entity.item.values.map((value) => (
          <Box mb={8} key={value.id}>
            { value.diType === IMarketplaceTilesType.CITY && (
              <SubGroupWrapper
                name={value.property?.translates?.[lang] || value.property?.systemName || ''}
                onRocket={(state) => rocketChange(state, { item: value })}
              >
                <TileLocationCity
                  entity={{ item: value }}
                  lang={lang}
                />
              </SubGroupWrapper>
            ) }
            { value.diType === IMarketplaceTilesType.METROSTATION && (
              <SubGroupWrapper
                name={value.property?.translates?.[lang] || value.property?.systemName || ''}
                onRocket={(state) => rocketChange(state, { item: value })}
              >
                <TileLocationMetroStation
                  entity={{ item: value }}
                  lang={lang}
                />
              </SubGroupWrapper>
            ) }
            { value.diType === IMarketplaceTilesType.DISTRICT && (
              <>
                <SubGroupWrapper
                  name={value.property?.translates?.[lang] || value.property?.systemName || ''}
                  onRocket={(state) => rocketChange(state, { item: value })}
                >
                  <TileLocationDistrict
                    entity={{ item: value }}
                    lang={lang}
                  />
                </SubGroupWrapper>
                <div className={styles.metroOrDistrict}>
                  <div className={styles.optionSwitch}>
                    {translations.metroOrDistrict}
                    <IOSSwitch
                      defaultChecked={entity.item.isMetroOrDistrict}
                      onChange={(e: any) => {
                        setInputValue<IMarketplaceTilesLocation>({ propLink: entity.item, propName: 'isMetroOrDistrict' }, e.target.checked);
                      }}
                    />
                  </div>
                  <div className={styles.description}>
                    <p>{translations.districtDescription}</p>
                  </div>
                </div>
              </>
            ) }
          </Box>
        ))}
      </GroupWrapper>
    </>
  );
};
