import { useMemo } from 'react';
import { isString } from 'lodash';
import { isRoman } from 'typescript-roman-numbers-converter';
import { useTranslations } from '../../../hooks';
import { IRangeAndSetData } from '../../../typings/filter';

export const useConvertToFormat = (data: (IRangeAndSetData | string)[]) => {
  const { getTranslations } = useTranslations();

  const currentData = useMemo(() => {
    const mapped = data?.map((item: IRangeAndSetData | string) => {
      const optionIsString = isString(item);
      return {
        key: optionIsString ? item : item?.key,
        text: optionIsString ? getTranslations(item) : item?.text,
        value: optionIsString ? item : item?.value,
      };
    });
    const isAllIsRoman = !!(mapped?.length && mapped?.every((item) => isRoman(item.value)));
    return isAllIsRoman ? mapped : mapped.sort((a, b) => a?.text?.localeCompare(b.text, undefined, { numeric: true, sensitivity: 'base' }));
  }, [data, getTranslations]);

  return {
    currentData: currentData || [],
  };
};
