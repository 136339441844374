import React from 'react';

export const SvgChevronUp: React.FC<{width?: string, height?: string, fill?: string}> = ({ width = 24, height = 24, fill = 'none' }) => {
  return (
    <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill={fill} xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1548_372)">
        <path d="M6 15L12 9L18 15" stroke="currentColor" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_1548_372">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
