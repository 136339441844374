import _ from 'lodash';

export const isObject = (value: string) => {
  return value && typeof value === 'object' && !Array.isArray(value);
};

export const toQueryString = (obj: {[key: string]: any}, prefix = ''): string => {
  const pairs: any[] = [];

  Object.keys(obj).forEach((key: string) => {
    if (key) {
      if (_.has(obj, key)) {
        // @ts-ignore
        const value = obj[key];
        const composedKey = prefix ? `${prefix}[${key}]` : key;

        if (isObject(value)) {
          pairs.push(toQueryString(value, composedKey));
        } else if (Array.isArray(value)) {
          (value as []).forEach((val, idx) => {
            if (isObject(val)) {
              pairs.push(toQueryString(val, `${composedKey}[${idx}]`));
            } else {
              pairs.push(`${encodeURIComponent(`${composedKey}[${idx}]`)}=${encodeURIComponent(val)}`);
            }
          });
        } else {
          pairs.push(`${encodeURIComponent(composedKey)}=${encodeURIComponent(value)}`);
        }
      }
    }
  });

  return `${pairs.join('&')}`;
};
