import React, { useEffect, useMemo } from 'react';
import Box from '@mui/material/Box';
import { SvgTrash } from 'src/components/ApartmentPageV2/LayoutApartmentItem/components/SvgTrash';
import { Drag } from 'src/components/ApartmentPageV2/LayoutApartmentItem/components/DragIcon';
import { SvgChevronUp } from 'src/components/ApartmentPageV2/LayoutApartmentItem/components/SvgChevronUp';
import {
  GroupWrapperFiltersActionProps, GroupWrapperFiltersCollapseState,
} from 'src/components/profileComponents/Marketplace/components/GroupWrapperActions/GroupWrapperFiltersAction/types';
import { SvgChevronBack } from 'src/components/ApartmentPageV2/LayoutApartmentItem/components/SvgChevronBack';
import { SvgRocket } from 'src/components/ApartmentPageV2/LayoutApartmentItem/components/SvgRocket';
import { SvgRocketOff } from 'src/components/ApartmentPageV2/LayoutApartmentItem/components/SvgRocketOff';
import { useStyles } from './styles';

export const GroupWrapperFiltersAction: React.FC<GroupWrapperFiltersActionProps> = (
  {
    onTrash, onDrag, onRocket, collapseState, onCollapse, rocketState,
  },
) => {
  const styles = useStyles();
  const [_collapseState, setCollapseState] = React.useState<GroupWrapperFiltersCollapseState>(collapseState || GroupWrapperFiltersCollapseState.OPEN);

  const onCollapseActionSwitch = useMemo((): GroupWrapperFiltersCollapseState => {
    if (_collapseState === GroupWrapperFiltersCollapseState.OPEN) {
      return GroupWrapperFiltersCollapseState.CLOSED;
    }
    return GroupWrapperFiltersCollapseState.OPEN;
  }, [_collapseState]);

  useEffect(() => {
    if (onCollapse && _collapseState !== undefined) {
      onCollapse(_collapseState);
    }
  }, [onCollapse, _collapseState]);

  return (
    <div className={styles.groupWrapperActions}>
      {onRocket && rocketState !== undefined && (
        <Box className={styles.actionWrapper} onClick={() => onRocket(!rocketState)}>
          {rocketState && (
            <SvgRocket width="20" height="20" />
          )}
          {!rocketState && (
            <SvgRocketOff width="20" height="20" />
          )}
        </Box>
      ) }
      { onTrash && (
        <Box
          className={styles.actionWrapper}
          onClick={onTrash}
        >
          <SvgTrash />
        </Box>
      ) }
      {onCollapse && _collapseState !== undefined && (
        <Box
          className={styles.actionWrapper}
          onClick={() => setCollapseState(onCollapseActionSwitch)}
        >
          {_collapseState === GroupWrapperFiltersCollapseState.OPEN && (
          <SvgChevronUp width="20" height="20" />
          )}
          {_collapseState === GroupWrapperFiltersCollapseState.CLOSED && (
          <SvgChevronBack width="20" height="20" />
          )}
        </Box>
      )}
      {onDrag && (
        <Box className={styles.actionWrapper} onMouseDown={onDrag}><Drag /></Box>
      ) }
    </div>
  );
};
