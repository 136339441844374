import React, { useState } from 'react';
import {
  UploadMarketplace,
} from 'src/components/profileComponents/Marketplace/components/Filters/components/UploadMarketplace';
import { translations } from 'src/constants/translations';
import {
  IMarketplaceFilterTagSelectedValueSelectedProperty,
} from 'src/components/profileComponents/Marketplace/types';
import { BoxFormTextField } from 'src/components/profileComponents/FormTextField';
import { IComplex } from 'src/typings/complexes';
import { IMarketplaceTilesConfigComplexTag } from 'src/typings/marketplace';
import { readURL } from 'src/helpers';
import { setInputValue } from 'src/components/profileComponents/Marketplace/helpers';
import {
  useStyles,
} from './styles';

interface Props {
  entity: {item: IMarketplaceTilesConfigComplexTag},
  lang: string,
}
export const ComplexTileTags: React.FC<Props> = ({ entity, lang }) => {
  const [image, setImage] = useState<string | null>(entity?.item.iconFileUri?.fileUrl || null);
  const styles = useStyles();
  return (
    <div className={styles.TileTagWrapper}>
      <UploadMarketplace
        register={() => {}}
        registerName=""
        image={image}
        onUpload={async (file, response) => {
          const tmp = entity;
          tmp.item.iconFileUri = response.data[0];
          const base64: string = await readURL(file);
          if (base64) {
            setImage(base64);
          }
        }}
      />
      <BoxFormTextField
        label={translations.enterTagText}
        name="text"
        defaultValue={entity.item.text?.[lang]}
        onChange={(event) => {
          setInputValue<IMarketplaceTilesConfigComplexTag>({ propLink: entity.item, propName: 'text' }, event.target.value, lang);
        }}
      />
    </div>
  );
};
