import React, { Ref } from 'react';
import Box from '@mui/material/Box';
import { ApartmentTitleButton } from 'src/components/ApartmentPageV2/ApartmentTitle/ApartmentTitleButton';
import { useStyles } from 'src/components/ApartmentPageV2/ApartmentTitle/styles';
import { IViewConfig } from 'src/typings/viewConfig';
import { getValueByPlacementSystemName } from 'src/helpers/placementSystemName';
import { UseNumberFormat } from 'src/hooks';
import { locals } from 'src/constants';
import { IFilterAllData } from 'src/typings/filter';

export interface IApartmentRequest {
  showTitle?: boolean;
  showCallToActionBtn?: boolean
  showRedirectBtn?: boolean
  ref?: Ref<HTMLDivElement>;
  viewConfig?: IViewConfig;
  placeInfo?: IFilterAllData;
}

export const ApartmentTitle: React.FC<IApartmentRequest> = ({
  showCallToActionBtn, showRedirectBtn, viewConfig, placeInfo,
}) => {
  const styles = useStyles({});
  const { numberSeparator, replaceFraction } = UseNumberFormat();
  return (
    <Box
      className={styles.apartment}
    >
      <Box
        className={styles.apartmentTitle}
        style={{
          color: viewConfig?.headerSection?.mainHeader.styles.fontColor,
          fontWeight: viewConfig?.headerSection?.mainHeader.styles.fontWeight,
        }}
      >
        { viewConfig && viewConfig?.headerSection?.mainHeader?.propertySettings.map((setting, index) => {
          const value = getValueByPlacementSystemName(viewConfig.placement, setting.placementProperty.systemName, { lang: viewConfig.lang });
          return Boolean(value) && (
          <Box
            key={setting.id}
            dangerouslySetInnerHTML={{
              __html: `${(index > 0) ? '&nbsp;<span>•</span>&nbsp;' : ''}
              ${setting.prefix ? setting.prefix?.[viewConfig.lang] || '' : ''}
              ${numberSeparator(replaceFraction(String(value)), setting.placementProperty.systemName)}
              ${setting.suffix ? setting.suffix?.[viewConfig.lang] || '' : ''}`,
            }}
          />
          );
        }) }
      </Box>
      <Box
        className={styles.apartmentPriceHolder}
      >
        {viewConfig?.headerSection?.subHeader.propertySettings.map((setting) => {
          const value = getValueByPlacementSystemName(
            viewConfig?.placement,
            setting.placementProperty.systemName,
            { lang: viewConfig?.lang, sortValue: true },
          );
          return Boolean(value) && (
            <Box
              key={setting.id}
              style={{
                color: setting.styles.fontColor,
                fontWeight: setting.styles.fontWeight,
                textDecorationLine: setting.styles.fontDecoration,
              }}
              className={styles.apartmentPrice}
              dangerouslySetInnerHTML={{
                __html: `
              ${setting.prefix ? setting.prefix[viewConfig.lang] || '' : ''}
              ${numberSeparator(replaceFraction(String(value)), setting.placementProperty.systemName)}
              ${setting.suffix ? setting.suffix[viewConfig.lang] || '' : ''}`,
              }}
            />
          );
        })}
      </Box>
      {showCallToActionBtn && viewConfig && viewConfig.headerSection?.callToAction.isHidden === false && (
        <div className={styles.titleButton}>
          <ApartmentTitleButton
            title={locals.appTranslations.appRequestACall}
            sx={{
              backgroundColor: viewConfig?.headerSection?.callToAction.styles.backgroundColor,
              color: viewConfig?.headerSection?.callToAction.styles.fontColor,
              fontWeight: viewConfig?.headerSection?.callToAction.styles.fontWeight,
              '&.MuiButton-root:hover': {
                backgroundColor: viewConfig?.headerSection?.callToAction.styles.backgroundColor,
              },
            }}
          />
        </div>
      )}
      {showRedirectBtn && viewConfig && viewConfig.headerSection?.unitPageRedirect.isHidden === false && (
        <div className={styles.titleButton}>
          <ApartmentTitleButton
            title={viewConfig?.headerSection?.unitPageRedirect.name?.translates?.[viewConfig?.lang]
          || viewConfig?.headerSection?.unitPageRedirect.name?.systemName || 'More details'}
            sx={{
              backgroundColor: viewConfig?.headerSection?.unitPageRedirect.styles.backgroundColor,
              color: viewConfig?.headerSection?.unitPageRedirect.styles.fontColor,
              fontWeight: viewConfig?.headerSection?.unitPageRedirect.styles.fontWeight,
              '&.MuiButton-root:hover': {
                backgroundColor: viewConfig?.headerSection?.unitPageRedirect.styles.backgroundColor,
              },
            }}
            onClickEvent={() => {
              if (viewConfig?.applicationProperties) {
                const foundProp = viewConfig.placementProperties
                  .find((prop) => prop.systemName === viewConfig.headerSection.unitPageRedirect.placementProperty?.systemName);
                // @ts-ignore
                if (foundProp && foundProp?.oldSystemName && placeInfo?.[foundProp.oldSystemName]) {
                  // @ts-ignore
                  window.open(placeInfo?.[foundProp.oldSystemName], '_blank');
                }
              }
            }}
          />
        </div>
      )}
    </Box>
  );
};
