import { useState } from 'react';
import { IFilterAllData, ListOfKeysFilterAllData } from 'src/typings/filter';
import { GridColDef, gridExpandedSortedRowIdsSelector } from '@mui/x-data-grid';
import * as ExcelJS from 'exceljs';
import {
  AddWorksheetOptions, Buffer, Workbook, Worksheet,
} from 'exceljs';
import { useGetRequestAppData } from 'src/hooks/app';
import { useGetFilterParams } from 'src/hooks/use-get-filter-params';
import { urlToBase64 } from 'src/helpers/urlToBase64';
import { useDispatch, useSelector } from 'react-redux';
import { getTableApiRef, getTableColumns, getTableRows } from 'src/store/table/selectors';
import { GridStateColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import { IExportPdfData } from 'src/typings/table';
import { Actions as TableActions } from 'src/store/table/actions';
import { useGetComplex } from 'src/hooks/useGetComplex';
import { getComplexId } from 'src/store/app/selectors';
import { getShowFlatshow } from 'src/store/requests/app/selector';
import { UseNumberFormat } from 'src/hooks/useNumberFormat';
import { format } from 'date-fns';
import { IComplex } from 'src/typings/complexes';
import { IPlacementProperty, IPropertySettings } from 'src/typings/viewConfig';
import { useQRCode } from 'src/hooks/useQRCode';
import useCreateTablePdf from '../../helpers/createTablePdf';

export const useTable = () => {
  const complexId = useSelector(getComplexId);
  const { complex } = useGetComplex(complexId ? String(complexId) : null, false);
  const apiRef = useSelector(getTableApiRef);
  const isShowFlatshow = useSelector(getShowFlatshow);
  const rows = useSelector(getTableRows);
  const columns = useSelector(getTableColumns);
  const { appRequestData } = useGetRequestAppData();
  const { selectedParams } = useGetFilterParams();
  const { createTablePdf, isLoading: pdfExportInProgress } = useCreateTablePdf();
  const spliceRowsCount = 10;
  const issuedDateText = 'Issued';
  const alphabet = Array.from({ length: 26 }, (_, i) => String.fromCharCode(65 + i));
  const defaultFontStyle = {
    name: 'Inter',
    family: 4,
    size: 11,
  };
  const hyperLinkCells = [ListOfKeysFilterAllData.placementNumeration];
  const [excelExportInProgress, setExcelExportInProgress] = useState<boolean>(false);
  const { roundIfDecimalsIsZero, fieldsToCompare } = UseNumberFormat();
  const { getClientModuleAccessUrl } = useQRCode();
  const dispatch = useDispatch();

  const setColumns = (columnsData: GridColDef[]) => {
    dispatch(TableActions.setTableColumns(columnsData));
  };

  const setRows = (rowsData: IFilterAllData[]) => {
    dispatch(TableActions.setTableRows(rowsData));
  };

  const getExportPdfData = (view2dDataObject: {[key: string]: IFilterAllData}): IExportPdfData => {
    const result: IExportPdfData = {
      projectLogo: '',
      developerLogo: '',
      issuedDate: '',
      showFlatShowLogo: !!isShowFlatshow,
      tableFields: [],
      tableData: [],
    };
    const visibleColumns = apiRef?.current.getVisibleColumns();
    if (complex) {
      result.projectLogo = complex.logoFileUri?.fileUrl || '';
      result.developerLogo = complex.developers[0].logoFileUri?.fileUrl || '';
    }
    const currentDate = new Date();
    result.issuedDate = `${issuedDateText}: ${format(currentDate, 'MMMM dd, yyyy')}`;
    if (visibleColumns && apiRef) {
      const ids = gridExpandedSortedRowIdsSelector(apiRef);
      const sortedRows = roundIfDecimalsIsZero(ids.map((rowId) => view2dDataObject[rowId]), fieldsToCompare);
      result.tableFields = visibleColumns.map((item: GridStateColDef) => item.headerName) as string[];
      const availableFields = visibleColumns.map((item: GridStateColDef) => item.field);
      result.tableData = sortedRows.data.map((row) => {
        const rowResult = {};
        Object.keys(row).forEach((key) => {
          if (availableFields.includes(key)) {
            const foundField = visibleColumns.find((field: any) => field.field === key);
            if (foundField && foundField.headerName) {
              // @ts-ignore
              rowResult[foundField.headerName] = Array.isArray(row[key]) ? row[key].join(',') : row[key];
            }
          }
        });
        return rowResult;
      });
    }

    createTablePdf(result);
    return result;
  };

  const getWorkbook = () => {
    const workBook = new ExcelJS.Workbook();
    const workBookOptions: Partial<AddWorksheetOptions> = {
      pageSetup: {
        paperSize: 9,
        orientation: 'landscape',
      },
      views: [{ state: 'frozen', ySplit: 11 }],
    };
    return { workBook, workBookOptions };
  };

  const getUniquePlacementProperties = (props: IPropertySettings[]): IPlacementProperty[] => {
    const result: IPlacementProperty[] = [];
    props.forEach((prop) => {
      if (!result.find((item) => item.systemName === prop.placementProperty.systemName)) {
        result.push(prop.placementProperty);
      }
    });
    return result;
  };

  const exportExcelFile = (
    view2dDataObject: {[key: string]: IFilterAllData},
    sheet: Worksheet,
    workBook: Workbook,
    columnsList?: {headerName: string | undefined, field: string | undefined}[],
    _complex?: IComplex,
  ) => {
    return new Promise((resolve, reject) => {
      const sheetLocal = sheet;
      sheetLocal.properties.defaultColWidth = 22;
      const imageCellHeight = 140;

      sheetLocal.getRow(1).font = {
        ...defaultFontStyle,
        size: 12,
        bold: true,
      };

      const gridColumns = apiRef?.current?.getVisibleColumns() || columnsList;
      if (gridColumns) {
        sheetLocal.columns = gridColumns.map((column) => ({
          header: column.headerName,
          key: column.field,
        }));

        const ids = apiRef?.current ? gridExpandedSortedRowIdsSelector(apiRef) : Object.keys(view2dDataObject).map((key) => view2dDataObject[key][ListOfKeysFilterAllData.id]);
        const data = roundIfDecimalsIsZero(ids.map((item) => view2dDataObject[item]), fieldsToCompare);
        if (data) {
          data.data.forEach((item) => {
            const row: {[index: string]:string | object} = {};
            gridColumns?.forEach((visibleColumn) => {
              const fieldData = item[visibleColumn?.field as ListOfKeysFilterAllData];
              const value = Array.isArray(fieldData) ? fieldData.join(', ') : fieldData;
              let rowValue = value;
              if (_complex && visibleColumn?.field && hyperLinkCells.includes(visibleColumn.field as ListOfKeysFilterAllData)) {
                rowValue = {
                  text: value,
                  hyperlink: `${getClientModuleAccessUrl(_complex, _complex.language)}#/own/${item[ListOfKeysFilterAllData.id]}`,
                };
              }
              row[String(visibleColumn.field)] = rowValue;
            });
            const rowResult = sheet.addRow(row);
            rowResult.font = defaultFontStyle;
            hyperLinkCells.forEach((linkCell) => {
              const cell = rowResult.getCell(linkCell);
              cell.font = {
                ...cell.font,
                underline: true,
                color: { theme: 10 },
              };
            });
          });

          sheet.spliceRows(1, 0, ...new Array(spliceRowsCount));
          const filtersList = sheet.getCell(`A${spliceRowsCount - 1}`);
          filtersList.font = { ...defaultFontStyle, bold: true };
          filtersList.value = selectedParams.map((item) => item.value).join(', ');
          if (gridColumns.length) {
            const issuedDate = sheet.getCell(`${alphabet[gridColumns.length - 1]}${spliceRowsCount - 1}`);
            issuedDate.font = { ...defaultFontStyle, bold: true };
            const currentDate = new Date();
            const issuedDateFormat = format(currentDate, 'MMMM dd, yyyy');
            issuedDate.value = `${issuedDateText}: ${issuedDateFormat}`;
          }
          if (_complex) {
            const complexLogoUri = _complex.logoFileUri?.fileUrl;
            const developerLogoUri = _complex.developers[0]?.logoFileUri?.fileUrl;
            Promise.all([getB64ByUri(complexLogoUri || ''), getB64ByUri(developerLogoUri || '')])
              .then((b64Result) => {
                const complexB64 = b64Result[0];
                const developerB64 = b64Result[1];
                const complexImageId = workBook.addImage({
                  base64: complexB64.result.base64Url,
                  extension: complexB64.extName,
                });
                const developerImageId = workBook.addImage({
                  base64: developerB64.result.base64Url,
                  extension: developerB64.extName,
                });
                const complexRatio = convertPixelsToExcelPoints(complexB64.result.image.width) / convertPixelsToExcelPoints(complexB64.result.image.height);
                const developerRatio = convertPixelsToExcelPoints(developerB64.result.image.width) / convertPixelsToExcelPoints(developerB64.result.image.height);
                const complexImageWidth = imageCellHeight * complexRatio;
                sheet.addImage(complexImageId, {
                  tl: { col: 0, row: 0 },
                  ext: { width: complexImageWidth >= 1000 ? 1000 : complexImageWidth, height: imageCellHeight },
                });
                sheet.addImage(developerImageId, {
                  tl: { col: gridColumns.length - 2.1, row: 0 },
                  ext: { width: imageCellHeight * developerRatio, height: imageCellHeight },
                });
                resolve(sheet);
              })
              .catch((err) => reject(err));
          }
        }
      }
    });
  };

  const applyAndDownloadReport = (workBook: Workbook, isAvailabilityReport?: boolean) => {
    const date = getToday();
    workBook.xlsx.writeBuffer().then((blobPart: Buffer) => {
      const blob = new Blob([blobPart], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = !isAvailabilityReport ? `${appRequestData?.module_title}_${date.m}.${date.d}.${date.y}.xlsx` : `Availability report ${date.m}.${date.d}.${date.y}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const getToday = (): {m: string, d: string, y: number, today: string} => {
    const date = new Date();
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const yyyy = date.getFullYear();
    return {
      m: mm,
      d: dd,
      y: yyyy,
      today: `${mm}/${dd}/${yyyy}`,
    };
  };

  const getB64ByUri = async (uri: string): Promise<{ result: any, extName: 'jpeg' | 'png' | 'gif' }> => {
    const result: any = await urlToBase64(uri);
    const splitted = uri.split('.');
    const extName: any = splitted[splitted.length - 1];
    return { result, extName };
  };

  const convertPixelsToExcelPoints = (pixels: number): number => {
    return pixels * 0.75;
  };

  return {
    apiRef,
    rows,
    columns,
    defaultFontStyle,
    spliceRowsCount,
    alphabet,
    excelExportInProgress,
    pdfExportInProgress,
    setRows,
    setColumns,
    exportExcelFile,
    getWorkbook,
    getExportPdfData,
    getUniquePlacementProperties,
    setExcelExportInProgress,
    applyAndDownloadReport,
  };
};
