import React, { useEffect, useState } from 'react';
import {
  UploadMarketplace,
} from 'src/components/profileComponents/Marketplace/components/Filters/components/UploadMarketplace';
import { BoxFormTextField } from 'src/components/profileComponents/FormTextField';
import { translations } from 'src/constants/translations';
import { setInputValue } from 'src/components/profileComponents/Marketplace/helpers';
import { IMarketplaceTilesLocationSubValue, IMarketplaceTilesLocationValue } from 'src/typings/marketplace';
import { IOSSwitch } from 'src/components/SwitchIos';
import { ITranslate } from 'src/typings/viewConfig';
import { BoxFormAutocomplete } from 'src/components/profileComponents/FormAutocomplete';
import { Events } from 'rheostat';
import { readURL } from 'src/helpers';
import {
  IDevelopmentSpecificationPropChange,
} from 'src/components/profileComponents/Complexes/components/ProjectSpecification/types';
import { IComplexProperty, IMarketplacePlacementProperty } from 'src/components/profileComponents/Marketplace/types';
import {
  useStyles,
} from './styles';

interface IProps {
  entity: { item: IMarketplaceTilesLocationValue };
  lang: string;
}
export const TileLocationMetroStation: React.FC<IProps> = ({ entity, lang }) => {
  const styles = useStyles();
  const [image, setImage] = useState<string | null>('' || null);
  const [imageWalking, setImageWalking] = useState<string | null>('' || null);
  const [imageDriving, setImageDriving] = useState<string | null>('' || null);
  const [options, setOptions] = React.useState<IDevelopmentSpecificationPropChange<boolean>[]>([]);

  useEffect(() => {
    if (entity) {
      setOptions([{
        label: 'Enabled',
        value: true,
      }, {
        label: 'Disabled',
        value: false,
      }]);
    }
  }, [entity]);

  return (
    <div className={styles.tileLocationMetroStationWrapper}>
      <UploadMarketplace
        register={() => {}}
        registerName=""
        image={image}
        onUpload={async (file, response) => {
          const tmp = entity;
          tmp.item.iconFileUri = response.data[0];
          const base64: string = await readURL(file);
          if (base64) {
            setImage(base64);
          }
        }}
      />
      <BoxFormTextField
        label={translations.prefix}
        name="prefix"
        defaultValue={entity.item.prefix?.[lang]}
        onChange={(event) => {
          setInputValue<IMarketplaceTilesLocationValue>({ propLink: entity.item, propName: 'prefix' }, event.target.value, lang);
        }}
      />
      <BoxFormTextField
        label={translations.suffix}
        name="suffix"
        defaultValue={entity.item.suffix?.[lang]}
        onChange={(event) => {
          setInputValue<IMarketplaceTilesLocationValue>({ propLink: entity.item, propName: 'suffix' }, event.target.value, lang);
        }}
      />
      <div className={styles.optionSwitch}>
        {translations.walkingDistance}
        <IOSSwitch
          defaultChecked={!!entity.item.walkingDistance?.isActive}
          onChange={(e: any) => {
            setInputValue<IMarketplaceTilesLocationSubValue>({ propLink: entity.item.walkingDistance, propName: 'isEnabled' }, e.target.checked);
          }}
        />
      </div>
      <UploadMarketplace
        register={() => {}}
        registerName=""
        image={imageWalking}
        onUpload={async (file, response) => {
          const tmp = entity;
          if (tmp.item.walkingDistance) {
            tmp.item.walkingDistance.iconFileUri = response.data[0];
            const base64: string = await readURL(file);
            if (base64) {
              setImageWalking(base64);
            }
          }
        }}
      />
      <div className={styles.uom}>
        <BoxFormTextField
          label={translations.uom}
          name="uom"
          defaultValue={entity.item.walkingDistance?.uomText?.[lang]}
          onChange={(event) => {
            setInputValue<IMarketplaceTilesLocationSubValue>({ propLink: entity.item.walkingDistance, propName: 'uomText' }, event.target.value, lang);
          }}
        />
      </div>
      <div className={styles.optionSwitch}>
        {translations.drivingDistance}
        <IOSSwitch
          defaultChecked={!!entity.item.drivingDistance?.isActive}
          onChange={(e: any) => {
            setInputValue<IMarketplaceTilesLocationSubValue>({ propLink: entity.item.drivingDistance, propName: 'isEnabled' }, e.target.checked);
          }}
        />
      </div>
      <UploadMarketplace
        register={() => {}}
        registerName=""
        image={imageDriving}
        onUpload={async (file, response) => {
          const tmp = entity;
          if (tmp.item.drivingDistance) {
            tmp.item.drivingDistance.iconFileUri = response.data[0];
            const base64: string = await readURL(file);
            if (base64) {
              setImageDriving(base64);
            }
          }
        }}
      />
      <div className={styles.uom}>
        <BoxFormTextField
          label={translations.uom}
          name="uom"
          defaultValue={entity.item.drivingDistance?.uomText?.[lang]}
          onChange={(event) => {
            setInputValue<IMarketplaceTilesLocationSubValue>({ propLink: entity.item.drivingDistance, propName: 'uomText' }, event.target.value, lang);
          }}
        />
      </div>
      <div className={styles.shorterDistanceOnly}>
        <BoxFormAutocomplete
          label={translations.shorterDistanceOnly}
          name="property"
          defaultValue={{ label: entity.item.isShorterDistanceOnly ? 'Enabled' : 'Disabled', value: entity.item.isShorterDistanceOnly }}
          onChange={(event: IDevelopmentSpecificationPropChange<boolean>) => {
            const tmp = entity;
            tmp.item.isShorterDistanceOnly = event.value;
          }}
          options={options}
        />
      </div>
    </div>
  );
};
