import { Theme } from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { purpleColor, whiteColor } from 'src/constants/styles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  complexItemWrapper: {
    cursor: 'pointer',
  },
  previewWrap: {
    transition: 'all 0.5s',
    '&:hover': {
      boxShadow: '1px 0px 5px -1px black',
    },
  },
  preview: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '100%',
    cursor: 'pointer',
    minWidth: '100%',
  },
  logoWrapper: {
    height: '60px',
    width: '100%',
    background: '#FCFCFD',
    opacity: 0.9,
    border: '1px solid #E9E6EF',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '38px',
  },
  logo: {
    maxHeight: 'calc(100% - 10px)',
    maxWidth: '80%',
  },
  title: {
    color: '#201E2A',
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '29px',
    borderBottom: '1px solid #BEB4FE',
    display: 'inline',
    cursor: 'pointer',
    '&:hover': {
      borderColor: 'white',
    },
  },
  editBtn: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    zIndex: 2,
    color: whiteColor,
    backgroundColor: purpleColor,
    transition: '0.3s all',
    '&:hover': {
      backgroundColor: 'rgb(108 89 230 / 70%)',
    },
  },
}));
