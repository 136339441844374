/* eslint-disable no-param-reassign */
import { useSelector } from 'react-redux';
import { RefObject, useCallback, useState } from 'react';
import _ from 'lodash';

import { UrlConfigure } from 'src/typings/app';
import { getSelectedView } from 'src/store/type-displayed-spinner/selectors';
import { getSelectedPlacement } from 'src/store/requests/type-of-property/selector';
import { useGetFilterParams } from 'src/hooks/use-get-filter-params';
import { getCurrentSpinner } from 'src/store/spinner/selectors';
import { getCurrentEntity, isShowNestedFilter, getCurrentLang } from 'src/store/app/selectors';
import { IFirstSpinner } from 'src/typings/spinner';
import { getAllFloorData } from 'src/store/floors/selectors';
import { IFloorsStateReducer } from 'src/store/floors/reducer';

export const useGenerateUlrLink = (refCopyInput: RefObject<HTMLInputElement>) => {
  const { complexId } = window;
  const displayType: string = useSelector(getSelectedView);
  const currentSpinner: IFirstSpinner | null = useSelector(getCurrentSpinner);
  const typePlacement: string | undefined = useSelector(getSelectedPlacement);
  const currentEntity = useSelector(getCurrentEntity);
  const showNestedFilter: boolean = useSelector(isShowNestedFilter);
  const floorAllData: IFloorsStateReducer = useSelector(getAllFloorData);
  const getStorageLanguage: any = useSelector(getCurrentLang);
  const [shareFilterLink, setShareFilterLink] = useState('');
  const { selectedParams } = useGetFilterParams();

  const handleGeneratorLink = useCallback(() => {
    const { pathname, origin } = document.location;

    const detectTypeOfSpinner: string = displayType;
    const generateFilterParameters: string = selectedParams.reduce((acc, cur) => {
      const transformData = _.isArray(cur.data)
        ? cur.data.sort((a: any, b: any) => a - b).join(';')
        : cur.data;

      return `${acc}&${cur.name}=${transformData}`;
    }, '');

    setShareFilterLink(`${origin}${pathname}#/?${
      UrlConfigure.typePlacementUrl}=${typePlacement}&${
      UrlConfigure.displayType}=${detectTypeOfSpinner}&${
      UrlConfigure.curSpinnerName}=${currentSpinner?.name}&${
      UrlConfigure.curSpinnerId}=${currentSpinner?.['name-id']}&${
      UrlConfigure.curEntityName}=${currentEntity.name}&${
      UrlConfigure.curEntityId}=${currentEntity.id}&${
      UrlConfigure.floorData}=${encodeURI(JSON.stringify(floorAllData))}&${
      UrlConfigure.collapsedFilter}=${showNestedFilter}&${
      encodeURI(generateFilterParameters)}`);

    setTimeout(() => {
      if (refCopyInput.current) {
        refCopyInput.current.type = 'text';
        refCopyInput.current.select();
        refCopyInput.current.setSelectionRange(0, 99999);

        document.execCommand('copy');
        refCopyInput.current.type = 'hidden';
      }
    }, 100);
  }, [
    complexId,
    refCopyInput,
    displayType,
    typePlacement,
    selectedParams,
    currentSpinner,
    floorAllData,
    showNestedFilter,
  ]);

  return {
    shareFilterLink,
    handleGeneratorLink,
  };
};
