import { IMarketplaceComplexTileConfig } from 'src/typings/marketplace';

export const tilesInitValues: IMarketplaceComplexTileConfig = {
  id: 0,
  complexOrderings: [],
  header: {
    id: 0,
    diType: 'ComplexProperty',
    property: null,
    isActive: false,
  },
  location: {
    id: 0,
    isMetroOrDistrict: false,
    isActive: false,
    values: [],
  },
  minMaxRange: {
    prefix: null,
    suffix: null,
    isActive: true,
    virtualProperty: {
      id: 0,
      type: 'MinMaxVirtualProperty',
      property: null,
    },
    uomText: null,
  },
  projectTimeline: {
    prefix: null,
    suffix: null,
    property: null,
    isActive: false,
  },
  unitsBreakdown: {
    id: 0,
    prefix: null,
    suffix: null,
    isActive: false,
    virtualProperty: {
      type: 'GroupingVirtualProperty',
      property: null,
      subProperties: [{
        id: 0,
        type: 'MinVirtualProperty',
        func: 'min',
        property: null,
        prefix: null,
        uomText: null,
      }],
    },
  },
  availabilityCounter: {
    prefix: null,
    suffix: null,
    isActive: true,
  },
};
