import { ApiUrl, crosstableUrl } from 'src/constants';
import { httpService } from 'src/services';
import { getBuildType } from 'src/helpers';
import { BuildEnv } from 'src/typings/env';

export function getSpinnerInitData(lang: string) {
  const buildType = getBuildType();
  const isCrossTable = buildType === BuildEnv.crossTable;
  return httpService.get(`${ApiUrl.getApiUrl().getSpinnerViews}&lang=${lang}${isCrossTable ? '&view=crosstable' : ''}`);
}

export function getViewsByComplex(lang: string, id: string) {
  const buildType = getBuildType();
  const isCrossTable = buildType === BuildEnv.crossTable;
  return httpService.get(`${ApiUrl.getApiUrl().getSpinnerViewsBaseUrl}?id=${id}&lang=${lang}${isCrossTable ? '&view=crosstable' : ''}`);
}

export function getSpinnerByEntity(id: number|string, entityName: string) {
  return httpService.get(`${crosstableUrl}/${entityName}_spinner_v2_views?${entityName}=${id}&pagination=false`, {}, {});
}
