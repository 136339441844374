import {
  takeEvery, put, call, select,
} from 'redux-saga/effects';
import { ActionTypes } from 'src/store/requests/app/actions';
import { requestSelectors } from 'src/store/requests/selectors';
import { getDefaultPlacementType } from 'src/store/crosstable/selectors';
import { IAppRequest } from 'src/typings/app';
import { getBuildType } from 'src/helpers';
import { BuildEnv } from 'src/typings/env';
import { getPlacementTypesByComplex } from 'src/api';
import { IPlacementType } from 'src/store/type-displayed-spinner/types';
import { IFrontConfigTypeEnum } from 'src/typings/table';
import { queryClient } from 'src/lib/queryClient';
import { QUERY_KEYS } from 'src/constants/queryKeys';
import { Actions } from './actions';
import { getComplexId } from '../app/selectors';

export function* setTypeDisplayedParams(action: {
  type: string,
  payload: IAppRequest,
}) {
  const complexId: number | null = yield select(getComplexId);
  const { data } = yield call(getPlacementTypesByComplex);

  queryClient.setQueryData([QUERY_KEYS.PLACEMENT_TYPES, complexId], data['hydra:member']);

  const buildType = getBuildType();
  const isCrossTable = buildType === BuildEnv.crossTable;
  const defaultLang: string|undefined = yield select(requestSelectors.app.getLang);
  const defaultPlacementType: string | null = yield select(getDefaultPlacementType);

  yield put(Actions.setListTypesSuccess(data['hydra:member']));
  if (defaultPlacementType) {
    const displaySpinnerType: IPlacementType = defaultLang ? data['hydra:member'].find((type: IPlacementType) => {
      if (type.title[defaultLang] === defaultPlacementType) {
        return true;
      }
      return false;
    }) : data['hydra:member'][0];
    yield put(Actions.setTypeDisplayedSpinner(displaySpinnerType.frontConfig[isCrossTable ? IFrontConfigTypeEnum.adminPanel : IFrontConfigTypeEnum.website].views.filter((view) => view.isEnabled)[0].type));
  } else {
    const findedType: IPlacementType = data['hydra:member'].find((item: IPlacementType) => {
      return !!item.frontConfig[isCrossTable ? IFrontConfigTypeEnum.adminPanel : IFrontConfigTypeEnum.website].views.length;
    });
    if (findedType) {
      yield put(Actions.setTypeDisplayedSpinner(findedType.frontConfig[isCrossTable ? IFrontConfigTypeEnum.adminPanel : IFrontConfigTypeEnum.website].views.filter((view) => view.isEnabled)[0].type));
    }
  }
}

export function* getTypeDisplayedWatchSaga() {
  yield takeEvery(ActionTypes.GET_APP_DATA_REQUEST_SUCCESS, setTypeDisplayedParams);
}
