import React, { useEffect, useState } from 'react';
import { NavigationTabs } from 'src/components/Marketplace/components/NavigationTabs';
import { useSelector } from 'react-redux';
import { getCurrentMarketplace } from 'src/store/marketplace/selectors';
import { IViewTileType } from 'src/components/profileComponents/Marketplace/types';
import { useMarketPlaceFilter } from 'src/components/Marketplace/hooks';
import { useStyles } from './styles';

export const MarketplaceHeader = () => {
  const marketplace = useSelector(getCurrentMarketplace);
  const [logo, setLogo] = useState<string>('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { setActiveTab } = useMarketPlaceFilter();

  useEffect(() => {
    if (marketplace?.developers[0].logoFileUri?.fileUrl) {
      setLogo(marketplace.developers[0].logoFileUri?.fileUrl);
    }
  }, [marketplace]);

  const styles = useStyles();

  const handleTabClick = (tab: IViewTileType) => {
    setActiveTab(tab);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className={styles.siteHeader}>
      <div className={styles.logo}>
        {logo && (
          <img src={logo} alt="Logo" />
        ) }
      </div>

      <NavigationTabs
        onTabClick={handleTabClick}
        classes={styles}
      />

      <div className={styles.headerBtn}>
        <button
          className={`${styles.menuButton} ${isMenuOpen ? styles.activeMenu : ''}`}
          onClick={toggleMenu}
          type="button"
        >
          <span className={styles.menuLine} />
          <span className={styles.menuLine} />
          <span className={styles.menuLine} />
        </button>
      </div>
    </header>
  );
};
