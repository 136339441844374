import React, {
  useEffect, useMemo, useState,
} from 'react';
import { useSelector } from 'react-redux';

import { ApartmentStatus } from 'src/components/ApartmentStatus';
import { ApartmentConnectDeal } from 'src/components/ApartmentConnectDeal';
import { getCrmData } from 'src/store/app/selectors';
import {
  useBuildType,
  useIsMobile, UseNumberFormat,
} from 'src/hooks';
import Box from '@mui/material/Box';
import { Hidden } from '@mui/material';
import { useGenerateGalleryFormat } from 'src/components/ApartmentPageV2/ApartmentGallery/hooks';
import { useGetParamsData } from 'src/containers/MainPageApartmentsV2/hooks';
import { ApartmentTitle } from 'src/components/ApartmentPageV2/ApartmentTitle';
import { ApartmentLabels } from 'src/components/ApartmentPageV2/ApartmentLabels';
import {
  ApartmentDescriptionAccordion,
} from 'src/components/ApartmentPageV2/ApartmentDescription/components/ApartmentDescriptionAccordion';
import {
  ApartmentDescriptionTour,
} from 'src/components/ApartmentPageV2/ApartmentDescription/components/ApartmentDescriptionTour';
import {
  ApartmentDescriptionSpinner,
} from 'src/components/ApartmentPageV2/ApartmentDescription/components/ApartmentDescriptionSpinner';
import { ComponentAdditionalInfo } from 'src/containers/MainPageApartmentsV2/components/ComponentAdditionalInfo';
import {
  AccordionStateTypeEnum,
  DescriptionGroupTypeEnum,
  IPlacementExplication, IPropertySettings,
  IViewConfig,
} from 'src/typings/viewConfig';
import { getValueByPlacementSystemName } from 'src/helpers/placementSystemName';
import { getApplicationProperty } from 'src/helpers/applicationProperty';
import { ApplicationPropertyTypeEnum } from 'src/components/ApartmentPageV2/ApartmentGallery/types';
import { appIcon } from 'src/assets';
import { getShowFlatshow } from 'src/store/requests/app/selector';
import { Viewport } from 'src/components/Viewport';
import { getOs, OSType } from 'src/helpers';
import { IApartmentDescription } from './types';
import { useStyles } from './styles';

export const ApartmentDescription: React.FC<IApartmentDescription> = ({
  isSingleId,
  placeInfo,
  combineIds,
  spinnerApartments,
  panoApartments,
  gallery,
  roomTours,
  placementViewConfig,
}) => {
  const { isCrossTable } = useBuildType();
  const styles = useStyles({});
  const crmData = useSelector(getCrmData);
  const [viewConfig, setViewConfig] = useState<IViewConfig | undefined>(undefined);
  const [explicationsDataList, setExplicationsDataList] = useState<IPlacementExplication[] | null>(null);
  const { isMobileScreen } = useIsMobile();
  const isShowFlatshow = useSelector(getShowFlatshow);
  const { galleryState } = useGenerateGalleryFormat(
    spinnerApartments,
    panoApartments,
    gallery,
    combineIds,
  );
  const { numberSeparator, replaceFraction } = UseNumberFormat();
  const os = getOs();

  const isSomeItemHasValue = (array: IPropertySettings[]): boolean => {
    if (viewConfig) {
      return array.some((item) => {
        return Boolean(getValueByPlacementSystemName(
          viewConfig.placement,
          item.placementProperty.systemName,
          { lang: viewConfig.lang },
        ));
      });
    }
    return false;
  };

  const {
    chooseData,
  } = useGetParamsData();

  useEffect(() => {
    if (placementViewConfig) {
      setViewConfig(placementViewConfig);
      setExplicationsDataList(placementViewConfig.placement?.placementExplications as IPlacementExplication[] || null);
    }
  }, [placementViewConfig]);

  const explication = useMemo(() => {
    return viewConfig?.descriptionSection?.descriptionSectionPropertySettingsGroups?.find((item) => item.type === DescriptionGroupTypeEnum.explication);
  }, [viewConfig]);

  const sortedDescriptionGroups = useMemo(() => viewConfig?.descriptionSection?.descriptionSectionPropertySettingsGroups
    ?.sort((a, b) => a.sortOrder - b.sortOrder), [viewConfig]);

  return (
    <div className={styles.wrapperDescription}>
      <Hidden mdDown>
        {placeInfo && viewConfig && (
          <ApartmentTitle
            viewConfig={viewConfig}
            showCallToActionBtn={!viewConfig?.headerSection?.callToAction?.isHidden}
            showRedirectBtn={!viewConfig?.headerSection?.unitPageRedirect?.isHidden}
            placeInfo={placeInfo}
          />
        )}
      </Hidden>
      {viewConfig && Boolean(Object.keys(viewConfig).length) && (
        <Box>
          {sortedDescriptionGroups && sortedDescriptionGroups.map((group) => (
            <Box key={group.id}>
              <div>
                { group.type === DescriptionGroupTypeEnum.leftAligned && Boolean(group.propertySettingsGroup.propertySettings.length) && (
                <ApartmentLabels
                  viewConfig={viewConfig}
                  viewConfigGroup={group.propertySettingsGroup}
                />
                ) }
                { (group.type === DescriptionGroupTypeEnum.lineDivided || group.type === DescriptionGroupTypeEnum.explication)
                    && Boolean(group?.propertySettingsGroup?.propertySettings.length) && (
                      <>
                        { group.type === DescriptionGroupTypeEnum.lineDivided
                          && isSomeItemHasValue(group?.propertySettingsGroup?.propertySettings) && (
                          <ApartmentDescriptionAccordion
                            isExpanded={Boolean(group.accordionState === AccordionStateTypeEnum.open)}
                            data={{
                              title: group?.propertySettingsGroup.name ? group?.propertySettingsGroup.name[viewConfig?.lang] : '',
                              content: group?.propertySettingsGroup?.propertySettings.map((item) => ({
                                id: item.id,
                                title: item?.placementProperty?.translates?.[viewConfig?.lang] || '',
                                prefix: item.prefix ? item.prefix[viewConfig?.lang] || '' : '',
                                suffix: item.suffix ? item.suffix[viewConfig?.lang] || '' : '',
                                value: numberSeparator(replaceFraction(getValueByPlacementSystemName(
                                  viewConfig?.placement,
                                  item?.placementProperty.systemName,
                                  { lang: viewConfig?.lang, sortValue: true },
                                )?.toString() || ''), item?.placementProperty.systemName),
                              })),
                            }}
                          />
                        )}
                        { !isMobileScreen && group.type === DescriptionGroupTypeEnum.explication
                          && explicationsDataList
                          && Boolean(explicationsDataList?.length) && (
                          <ApartmentDescriptionAccordion
                            isExpanded={Boolean(group.accordionState === AccordionStateTypeEnum.open)}
                            data={{
                              title: group?.propertySettingsGroup.name ? group?.propertySettingsGroup.name[viewConfig?.lang] : '',
                              content: explicationsDataList.map((item) => ({
                                id: item.id,
                                title: item.name.title?.[viewConfig.lang] || '',
                                prefix: group.propertySettingsGroup.propertySettings[0].prefix
                                  ? group.propertySettingsGroup.propertySettings[0].prefix[viewConfig?.lang] || '' : '',
                                suffix: group.propertySettingsGroup.propertySettings[0].suffix
                                  ? group.propertySettingsGroup.propertySettings[0].suffix[viewConfig.lang] || '' : '',
                                value: numberSeparator(
                                  replaceFraction(String(item.area)),
                                  group.propertySettingsGroup.propertySettings[0].placementProperty.systemName,
                                ),
                                level: `${item.sortOrder}`,
                              })),
                            }}
                          />
                        )}
                      </>
                ) }
              </div>
            </Box>
          )) }

          <Hidden mdUp>
            { explication && explicationsDataList && Boolean(explicationsDataList?.length) && (
              <div>
                <ApartmentDescriptionAccordion
                  isExpanded={explication.accordionState === AccordionStateTypeEnum.open}
                  data={{
                    title: explication.propertySettingsGroup.name ? explication.propertySettingsGroup.name[viewConfig?.lang] : '',
                    content: explicationsDataList.map((item) => ({
                      id: item.id,
                      title: item.name.title?.[viewConfig.lang] || '',
                      prefix: explication.propertySettingsGroup.propertySettings[0].prefix
                        ? explication.propertySettingsGroup.propertySettings[0].prefix[viewConfig?.lang] : '',
                      suffix: explication.propertySettingsGroup.propertySettings[0].suffix
                        ? explication.propertySettingsGroup.propertySettings[0].suffix[viewConfig.lang] : '',
                      value: numberSeparator(
                        replaceFraction(String(item.area)),
                        explication.propertySettingsGroup.propertySettings[0].placementProperty.systemName,
                      ),
                    })),
                  }}
                />
              </div>
            ) }

            { isMobileScreen && galleryState && (
              <ApartmentDescriptionSpinner
                title={
                  getApplicationProperty(
                    viewConfig.applicationProperties,
                    ApplicationPropertyTypeEnum.mobLocationBuilding,
                  )?.translates?.[viewConfig?.lang] || ApplicationPropertyTypeEnum.mobLocationBuilding
                }
                spinnerState={galleryState}
                combineIds={combineIds}
              />
            ) }
          </Hidden>

          { chooseData && (
          <Hidden mdUp>
            <ComponentAdditionalInfo
              title={getApplicationProperty(
                viewConfig.applicationProperties,
                ApplicationPropertyTypeEnum.mobLocationFloor,
              )?.translates?.[viewConfig?.lang] || ApplicationPropertyTypeEnum.mobLocationFloor}
              isMobile
              chooseData={chooseData}
            />
          </Hidden>
          ) }

          { (isCrossTable && isSingleId) && (
            <>
              { crmData ? null : <ApartmentStatus placeInfo={placeInfo} /> }
              <ApartmentConnectDeal placeInfo={placeInfo} />
            </>
          ) }
            { Boolean(roomTours.length) && (
              <Hidden mdUp>
                <Viewport
                  render={(render: { visible: boolean}) => {
                    if (!render.visible) {
                      return (
                        <></>
                      );
                    }
                    return (
                      <div className={`${styles.frameWrapper}`}>
                        <div className={styles.frameWrapperTitle}>
                          {
                            getApplicationProperty(
                              viewConfig.applicationProperties,
                              ApplicationPropertyTypeEnum.mobRoomsTour,
                            )?.translates?.[viewConfig?.lang] || ApplicationPropertyTypeEnum.mobRoomsTour
                          }
                        </div>
                        <div className={`${os === OSType.MAC ? styles.ios : ''}`}>
                          <ApartmentDescriptionTour tours={roomTours} />
                        </div>
                      </div>
                    );
                  }}
                />
              </Hidden>
            ) }
        </Box>
      ) }
      { isShowFlatshow && isMobileScreen && (
        <div className={styles.flatShowLogoWrapper}>
          <a
            href="https://www.flatshow.org/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img className={styles.flatShowLogo} src={appIcon.mainLogoMobile.default} alt="logo" />
          </a>
        </div>
      ) }
    </div>
  );
};
