import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { useTranslations } from 'src/hooks/translataions';
import { ListOfKeysFilterAllData, PlacementDisplayedType } from 'src/typings/filter';
import { useFilterConfigurator } from 'src/hooks/filters';
import { UseNumberFormat } from 'src/hooks';
import { CompareItem } from 'src/components/CompareList/components/CompareItem';
import { useViewConfig } from 'src/hooks/useViewConfig';
import { locals } from 'src/constants';
import { FilterTypeProperty } from 'src/components/FilterTypeProperty';
import { useSelectorsApartments } from 'src/components/FilterProperty/hooks';
import { ICompareItem } from './types';
import { useStyles } from './styles';
import { CompareItemBookNow } from '../CompareItemBookNow';

export const CompareItems: React.FC<ICompareItem> = React.memo(({
  placements,
  removeItemFromCompare,
  renderButtonShowDiff,
}) => {
  const styles = useStyles({});
  const { getTranslations } = useTranslations();
  const { filterConfigurator }: any = useFilterConfigurator(
    PlacementDisplayedType.placement,
    placements[0].id,
  );
  const [pdfLoadingId, setPdfLoadingId] = useState<string | undefined>(undefined);
  const { numberSeparator, replaceFraction } = UseNumberFormat();

  const { getViewConfig } = useViewConfig();

  useEffect(() => {
    getViewConfig(placements[0][ListOfKeysFilterAllData.id]);
  }, [placements]);

  const availableConfig = useMemo(() => {
    if (filterConfigurator) {
      return Object.keys(filterConfigurator)
        .filter((item) => filterConfigurator[item].use)
        .sort((a, b) => filterConfigurator[a].order - filterConfigurator[b].order);
    }
    return [];
  }, [filterConfigurator]);

  return (
    <>
      <div className={`${styles.compareRow}`} style={{ backgroundColor: 'transparent' }}>
        <div className={`${styles.wrapperCompareType} ${styles.wrapperCompareTitle} title`}>
          <h2>
            <FilterTypeProperty title={locals.appTranslations.appCompareApartments} />
          </h2>
        </div>
        <div className={styles.compareRowInner} style={{ backgroundColor: 'transparent' }}>
          { placements.map((item) => {
            return (
              <CompareItem key={item.id} item={item} removeItemFromCompare={removeItemFromCompare} />
            );
          }) }
        </div>
      </div>
      <div className={`${styles.compareRow}`} style={{ backgroundColor: 'transparent' }}>
        <div className={`${styles.wrapperCompareType} ${styles.wrapperCompareTitle} ${styles.desktopShowDiff}`}>
          { renderButtonShowDiff() }
        </div>
        <div className={styles.compareRowInner} style={{ backgroundColor: 'transparent' }}>
          { placements.map((item) => (
            <CompareItemBookNow
              key={item.id}
              item={item}
              pdfDisabled={pdfLoadingId !== undefined && item[ListOfKeysFilterAllData.id] !== pdfLoadingId}
              pdfLoading={(loading) => {
                if (loading) {
                  setPdfLoadingId(item[ListOfKeysFilterAllData.id]);
                } else {
                  setPdfLoadingId(undefined);
                }
              }}
            />
          )) }
        </div>
      </div>
      {
        availableConfig.map((placementType) => {
          const show = Object.keys(placements[0]).includes(placementType);
          return show ? (
            <div key={placementType} className={`${styles.compareRow} compareRowData`}>
              <div
                className={`${styles.wrapperCompareType} ${styles.wrapperCompareTitle}`}
              >
                { getTranslations(placementType) }
              </div>
              <div className={styles.compareRowInner}>
                { placements.map((item) => {
                  // @ts-ignore
                  const dataPlacement = item[placementType];

                  return (
                    <div key={item.id} className={`${styles.compareValue} ${styles.wrapperCompareType}`}>
                      {
                          // eslint-disable-next-line no-nested-ternary
                          numberSeparator(replaceFraction(_.isArray(dataPlacement)
                            ? dataPlacement.length ? dataPlacement.join(', ') : '-'
                            : dataPlacement || '-'),
                          placementType)
                        }
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null;
        })
      }
    </>
  );
});
