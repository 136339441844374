import React from 'react';
import { SubGroupWrapperProps } from 'src/components/profileComponents/Marketplace/components/SubGroupWrapper/types';
import { SvgTrash } from 'src/components/ApartmentPageV2/LayoutApartmentItem/components/SvgTrash';
import { Drag } from 'src/components/ApartmentPageV2/LayoutApartmentItem/components/DragIcon';
import Box from '@mui/material/Box';
import {
  GroupWrapperFiltersAction,
} from 'src/components/profileComponents/Marketplace/components/GroupWrapperActions/GroupWrapperFiltersAction';
import { useStyles } from './styles';

export const SubGroupWrapper: React.FC<SubGroupWrapperProps> = ({
  children, name, onTrash, onRocket,
}) => {
  const styles = useStyles();
  return (
    <div>
      <div className={styles.container}>
        <div className={styles.subGroupContainer}>
          <div className={styles.subGroupWrapper}>
            <div className={styles.wrapper}>
              <div className={styles.nameWrapper}>
                <div className={styles.name}>{ name }</div>
              </div>
              <div className={styles.actionsWrapper}>
                <GroupWrapperFiltersAction
                  onTrash={onTrash}
                  onDrag={() => {}}
                  onRocket={onRocket}
                />
              </div>
            </div>
          </div>
          <div className={styles.body}>
            { children }
          </div>
        </div>
        <div className={styles.offset} />
      </div>
    </div>
  );
};
