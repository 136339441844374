import { IComplex, IFileUri } from 'src/typings/complexes';
import { IPlacement } from 'src/store/placement/reducer';
import { IGeneralUnitInfo, IMarketplaceProperty, IViewTile } from 'src/components/profileComponents/Marketplace/types';
import { ITranslate } from 'src/typings/viewConfig';

export enum IMarketplaceTilesType {
  CITY = 'City',
  METROSTATION = 'MetroStation',
  DISTRICT = 'District'
}

export interface IMarketplaceFilterRequest {
  language: string,
  marketplaceId: number,
}

export interface IMarketplaceFilterResponse {
  complexesTile: IComplex[],
  placementsTile: IPlacement[],
  filter: IMarketplaceFilterResponseFilter,
  viewTile: IViewTile,
  generalUnitInfo: IGeneralUnitInfo,
}

export interface IMarketplaceFilterResponseFilter {
  propertyName: string,
  oldSystemName: string | null,
  values: IMarketplaceFilterResponseValue[]
}

export interface IMarketplaceFilterResponseValue {
  value: string,
  subValues: IMarketplaceFilterResponseFilter[],
  unitCount: number,
}

export interface IMarketplaceLanguageObjectsData {
  marketplaceId: number,
  findProperties: string[],
}
export interface IMarketplaceLanguageObjects {
  [key: string]: { [key: string]: string }[];
}

export interface IMarketplaceTilesConfigComplexTag {
  id: number,
  sortOrder: number,
  text: ITranslate | null,
  iconFileUri: IFileUri | null,
}
export interface IMarketplaceTilesConfigComplex {
  id: number,
  sortOrder: number,
  isActive: boolean,
  complex: Partial<IComplex>,
  tags: IMarketplaceTilesConfigComplexTag[]
}

export interface IMarketplaceTilesConfigHeader {
  id: number,
  diType: string,
  property: IMarketplaceProperty | null,
  isActive: boolean,
}

export interface IMarketplaceTilesLocationValue {
  id: number,
  diType: IMarketplaceTilesType,
  sortOrder: number,
  iconFileUri: IFileUri | null,
  isActive: boolean,
  prefix: ITranslate | null,
  suffix: ITranslate | null,
  isShorterDistanceOnly?: boolean,
  property?: IMarketplaceProperty | null,
  walkingDistance?: IMarketplaceTilesLocationSubValue,
  drivingDistance?: IMarketplaceTilesLocationSubValue,
}

export interface IMarketplaceTilesLocationSubValue {
  id: number,
  isActive: boolean,
  iconFileUri: IFileUri | null,
  uomText: ITranslate | null,
  property: IMarketplaceProperty | null,
}

export interface IMarketplaceTilesLocation {
  id: number,
  isMetroOrDistrict: boolean,
  values: IMarketplaceTilesLocationValue[]
  isActive: boolean,
}

export interface IMarketplaceTilesRange {
  prefix: ITranslate | null,
  suffix: ITranslate | null,
  isActive: boolean,
  virtualProperty: {
    id: 0,
    type: 'MinMaxVirtualProperty',
    property: IMarketplaceProperty | null,
  },
  uomText: ITranslate | null,
}
export interface IMarketplaceTilesProjectTimeline {
  isActive: boolean,
  prefix: ITranslate | null,
  suffix: ITranslate | null,
  property: IMarketplaceProperty | null,
}

export interface IMarketplaceTilesVirtualSubProperty {
  id: number,
  type: 'MinVirtualProperty',
  func: 'min' | 'max',
  property: IMarketplaceProperty | null,
  prefix: ITranslate | null,
  uomText: ITranslate | null,
}

export interface IMarketplaceTilesVirtualProperty {
  type: 'GroupingVirtualProperty',
  property: IMarketplaceProperty | null,
  subProperties: IMarketplaceTilesVirtualSubProperty[]
}
export interface IMarketplaceTilesUnitsBreakdown {
  id: number,
  isActive: boolean,
  prefix: ITranslate | null,
  suffix: ITranslate | null,
  virtualProperty: IMarketplaceTilesVirtualProperty,
}

export interface IMarketplaceTilesAvailabilityCounter {
  prefix: ITranslate | null,
  suffix: ITranslate | null,
  isActive: boolean,
}

export interface IMarketplaceComplexTileConfig {
  id: number,
  complexOrderings: IMarketplaceTilesConfigComplex[],
  header: IMarketplaceTilesConfigHeader,
  location: IMarketplaceTilesLocation,
  minMaxRange: IMarketplaceTilesRange,
  projectTimeline: IMarketplaceTilesProjectTimeline,
  unitsBreakdown: IMarketplaceTilesUnitsBreakdown,
  availabilityCounter: IMarketplaceTilesAvailabilityCounter
}

export interface IMarketplaceTilesConfig {
  complexTileConfig: IMarketplaceComplexTileConfig
}
