import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { range } from 'lodash';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Skeleton from '@mui/material/Skeleton';
import { getFloorItemType } from 'src/store/view-2d/selectors';
import { useOpenFilter } from 'src/hooks/app';
import { EFloorType } from 'src/store/view-2d/reducer';
import { CrossTableContent } from 'src/components/CrosstableContent';
import { AppartmentSidebar } from 'src/components/CrosstableContent/components/AppartmentSidebar';
import { TypeView2d } from 'src/components/TypeView2d';
import { SelectFloorItemType } from 'src/components/SelectFloorItemType';
import { ZoomTable } from 'src/components/CrosstableContent/components/ZoomTable';
import {
  useTranslations,
  useGetComplexStatuses,
  useBuildType,
  useLanguageState,
  useGetAuthData,
  useIsMobile,
  useTypeDisplayedSpinner, useGetComplex,
} from 'src/hooks';
import { locals } from 'src/constants/locals';
import useWindowDimensions from 'src/hooks/use-window-dimensions';
import { CrosstableContentTable } from 'src/components/CrosstableContentTable';
import { getUserData } from 'src/store/requests/user/selector';
import { TypeOfRole } from 'src/typings/user';
import { ButtonApp } from 'src/components/ApartmentPageV2/Button';
import { useTable } from 'src/hooks/table/useTable';
import { usePlacementTypeViews } from 'src/hooks/table/usePlacementTypeViews';
import Popover from '@mui/material/Popover';
import { IVisibleConfig } from 'src/store/type-displayed-spinner/types';
import { ReportIcon } from 'src/components/ReportIcon';
import { ExportButton } from 'src/typings/table';
import { getIsOpenAppartSidebar, getCrmData, getComplexId } from 'src/store/app/selectors';
import { getBitrixProject } from 'src/api';
import { CRMNames } from 'src/typings/app';
import isEmpty from 'lodash/isEmpty';
import { TypesOfSpinner } from 'src/typings/types-of-spinner';
import { AppHeaderLogo } from 'src/components/ApartmentPageV2/AppHeader/ComponentLogo';
import { requestSelectors } from 'src/store/requests/selectors';
import { IFilterAllData } from 'src/typings/filter';
import { useParams } from 'react-router-dom';
import { useControlCrossTable, useCrossTableState } from './hooks';
import { useStyles } from './styles';

export const CrossTableContainer: React.FC = () => {
  const complexId = useSelector(getComplexId);
  const { complex } = useGetComplex(complexId ? String(complexId) : null, false);
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();
  const styles = useStyles({});
  const { getTranslations } = useTranslations();
  const floorItemType = useSelector(getFloorItemType);
  const { isCrossTable } = useBuildType();
  const { currentAppLanguage } = useLanguageState();
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const {
    refCrossTable,
    measuredRef,
  } = useControlCrossTable();
  const { isOpen } = useOpenFilter();
  const { isMobileScreen } = useIsMobile();
  const { statuses, isLoading } = useGetComplexStatuses();
  const {
    floorList,
    view2dData,
    view2dDataList,
    view2dDataObject,
  } = useCrossTableState();
  const user = useSelector(getUserData);
  const crmData = useSelector(getCrmData);
  const { windowDimensions } = useWindowDimensions();
  const [zoomTableValue, setZoomTableValue] = useState<number | undefined>(undefined);
  const [listIsVisible, setListIsVisible] = useState<boolean>(true);
  const [visibleConfig, setVisibleConfig] = useState<IVisibleConfig>({
    detailedSubView: { isEnabled: false, sortOrder: 0 },
    simpleSubView: { isEnabled: false, sortOrder: 1 },
    listSubView: { isEnabled: false, sortOrder: 2 },
  });
  const [showSelector, setShowSelector] = useState<boolean>(false);
  const {
    exportExcelFile,
    getWorkbook,
    getExportPdfData,
    setExcelExportInProgress,
    applyAndDownloadReport,
    excelExportInProgress,
    pdfExportInProgress,
  } = useTable();
  const [isShowDealDataForManagers, setIsShowDealDataForManagers] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const { currentListSubView, currentView, enabledSubViewsCount } = usePlacementTypeViews();
  const listViewRoles = [TypeOfRole.superadmin, TypeOfRole.supervisor];
  const listSubView = 'listSubView';
  const managersList = [TypeOfRole.manager];
  const isOpenAppartSidebar = useSelector(getIsOpenAppartSidebar);
  const spinnerData = useSelector(requestSelectors.spinner.getSpinnerViewData);
  const { getSelectedViewId } = useTypeDisplayedSpinner();

  useEffect(() => {
    if (crmData) {
      if (crmData.crm === CRMNames.bitrix && user) {
        getBitrixProject(jwt, String(crmData.projectId))
          .then((res) => {
            if (managersList.includes(user.role?.['@id'] as TypeOfRole)) {
              setIsShowDealDataForManagers(res.data.frontConfig.dealConfig.isShowDealDataForManagers);
            } else {
              setIsShowDealDataForManagers(true);
            }
          });
      } else {
        setIsShowDealDataForManagers(true);
      }
    }
  }, [crmData, user]);

  const hideZoom = useMemo<boolean>(() => {
    if (isMobileScreen) {
      return isOpenAppartSidebar;
    }
    return false;
  }, [isMobileScreen, isOpenAppartSidebar]);

  useEffect(() => {
    if (isCrossTable && user && user.role) {
      setListIsVisible(listViewRoles.includes(user.role['@id']));
    }
  }, [user]);

  useEffect(() => {
    if (currentView && listIsVisible) {
      const tempConfig = {};
      currentView?.subViews?.forEach((sub) => {
        const name: string = sub.type.charAt(0).toLowerCase() + sub.type.slice(1);
        // @ts-ignore
        tempConfig[name] = { isEnabled: name === listSubView && !listIsVisible ? listIsVisible : sub.isEnabled, sortOrder: sub.sortOrder };
      });
      setVisibleConfig(tempConfig as IVisibleConfig);
      // @ts-ignore
      setShowSelector(Object.keys(tempConfig).filter((key) => tempConfig[key].isEnabled).length > 1);
    }
  }, [currentView, listIsVisible]);

  useEffect(() => {
    let value = 50;
    if (windowDimensions && windowDimensions.width >= 1920) {
      value = 60;
    }
    if (windowDimensions && windowDimensions.width >= 3000) {
      value = 70;
    }
    setZoomTableValue(value);
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (currentListSubView && enabledSubViewsCount === 1) {
      const activeBtn = currentListSubView.placementReportSetting.exportButtons.find((btn) => btn.isEnabled)?.type;
      switch (activeBtn) {
        case ExportButton.excel:
          exportExcelFileHandler(view2dDataObject);
          break;
        case ExportButton.pdf:
          getExportPdfData(view2dDataObject);
          break;
        default:
          break;
      }
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const exportExcelFileHandler = (data: {[key: string]: IFilterAllData}) => {
    const { workBook, workBookOptions } = getWorkbook();
    const sheet = workBook.addWorksheet('Report', workBookOptions);
    setExcelExportInProgress(true);
    if (complex) {
      exportExcelFile(data, sheet, workBook, undefined, complex)
        .then(() => {
          applyAndDownloadReport(workBook);
          setExcelExportInProgress(false);
        });
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (view2dData === null) {
    return (
      <div className={styles.noDisplayData}>
        <p>{getTranslations(locals.appTranslations.appNoDataToDisplay)}</p>
        <p>{getTranslations(locals.appTranslations.appPleaseStartFiltering)}</p>
      </div>
    );
  }

  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;

  return (
    <div className={`${styles.crossTableContainer} ${!isOpen && !isMobileScreen && styles.closedFilters}`}>
      {(!isMobileScreen || (isMobileScreen && !isOpenAppartSidebar)) && (
        <Box
          className={styles.crossTableContainerWrapper}
          mb={isCrossTable ? 4 : 0}
          ml={!isOpen && isCrossTable ? '-60px' : 0}
          pr={6}
          flexDirection={isSmallScreen ? 'column' : 'row'}
        >
          {((isCrossTable && !isOpen && (isEmpty(spinnerData?.complex) || (!isEmpty(spinnerData?.complex) && getSelectedViewId !== TypesOfSpinner.viewSpinner)))) && (
            <div className={styles.logo}>
              <AppHeaderLogo isVisible />
            </div>
          )}
          <Box display="flex" flexDirection="row" gap="20px" height="44px" justifyContent="center" alignItems="center">
            {showSelector && currentView && (
              <Box py={1}>
                <SelectFloorItemType
                  lang={currentAppLanguage}
                  placementsReport={currentView}
                  visibleConfig={visibleConfig}
                />
              </Box>
            )}
            {floorItemType === EFloorType.mini && (
              <Box py={1}>
                <TypeView2d />
              </Box>
            )}
            {floorItemType === EFloorType.list && (
              <div>
                {currentListSubView?.placementReportSetting.exportButtons.some((b) => b.isEnabled) && (
                  <>
                    <ButtonApp
                      startIcon={enabledSubViewsCount === 1 ? <ReportIcon isLoading={excelExportInProgress || pdfExportInProgress} /> : null}
                      color="primary"
                      text={
                        currentListSubView?.placementReportSetting.downloadOrChooseExportPlacementsReportName.translates?.[currentAppLanguage]
                        || currentListSubView?.placementReportSetting.downloadOrChooseExportPlacementsReportName.systemName
                      }
                      onClick={(e) => handleClick(e)}
                      className={styles.exportBtn}
                      isCapitalize
                      variant="contained"
                    />
                    <Popover
                      id={popoverId}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                      slotProps={{
                        paper: {
                          sx: {
                            overflow: 'hidden',
                          },
                        },
                      }}
                    >
                      <Box display="flex" flexDirection="column">
                        {currentListSubView?.placementReportSetting.exportButtons.map((button) => {
                          return button.isEnabled && (
                            <Box height="40px" key={button.id}>
                              <ButtonApp
                                startIcon={<ReportIcon isLoading={button.type === ExportButton.excel ? excelExportInProgress : pdfExportInProgress} />}
                                color="secondary"
                                style={{
                                  padding: '8px 16px',
                                }}
                                disabled={button.type === ExportButton.excel ? excelExportInProgress : pdfExportInProgress}
                                onClick={() => (button.type === ExportButton.pdf ? getExportPdfData(view2dDataObject) : exportExcelFileHandler(view2dDataObject))}
                              >
                                {(currentAppLanguage && button.name.translates?.[currentAppLanguage]) || button.name.systemName}
                              </ButtonApp>
                            </Box>
                          );
                        })}
                      </Box>
                    </Popover>
                  </>
                )}
              </div>
            )}
          </Box>
        </Box>
      )}
      {isLoading && (
        <Box
          display="flex"
          justifyContent="flex-end"
          pr={6}
          flexWrap="wrap"
          my={1}
        >
          {range(4).map((item) => (
            <Box key={item} display="flex" mr={2}>
              <Skeleton variant="rectangular" width={20} height={20} />
              <Box ml={1} width="50px">
                <Skeleton variant="text" />
              </Box>
            </Box>
          ))}

        </Box>
      )}
      {(!!statuses.length && floorItemType !== EFloorType.list && (!isMobileScreen || (isMobileScreen && !isOpenAppartSidebar))) && (
      <Box
        className={styles.statusList}
        pr={6}
      >

        {statuses.map((status) => (
          <Box key={status.id} display="flex" alignItems="center" mr={2}>
            <Box
              width="20px"
              minWidth="20px"
              height="20px"
              borderRadius="4px"
              mr={1}
              bgcolor={isCrossTable ? (status.crosstableColor || status.color) : status.color}
            />
            <Box
              className={styles.statusListItem}
            >
              {isCrossTable
                ? (status.crosstable_name && status.crosstable_name[currentAppLanguage])
                : (status.module_name && status.module_name[currentAppLanguage])}
            </Box>
          </Box>
        ))}
      </Box>
      )}
      <div
        ref={measuredRef}
        className={styles.crossTable}
        // style={{ maxHeight: crossTableHeight || 'none', overflow: crossTableHeight ? 'unset' : 'hidden' }}
      >
        {
          refCrossTable
          && Boolean(floorList.length)
          && view2dData !== null
          && floorItemType !== EFloorType.list && (
            <>
              <CrossTableContent
                view2dData={view2dData}
                floorList={floorList}
              />
            </>
          )
        }
        {floorItemType === EFloorType.list && (
          <CrosstableContentTable
            view2dDataList={view2dDataList}
          />
        )}
        {isOpenAppartSidebar && (
          <AppartmentSidebar showDealDetails={isShowDealDataForManagers} />
        )}
      </div>
      {floorItemType === EFloorType.mini && (
        <div className={`${styles.zoom} ${hideZoom ? 'hidden' : ''}`}>
          <ZoomTable setCurrentValue={zoomTableValue} />
        </div>
      )}
    </div>
  );
};
