import React, { forwardRef, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { IApartmentRequest } from 'src/components/ApartmentPageV2/ApartmentTitle/index';
import { ApartmentTitleButton } from 'src/components/ApartmentPageV2/ApartmentTitle/ApartmentTitleButton';
import { useStyles as headerStyles } from 'src/components/ApartmentPageV2/AppHeader/styles';
import { ButtonApp } from 'src/components/ApartmentPageV2/Button';
import {
  useBuildType, useFavoriteListState, UseNumberFormat,
} from 'src/hooks';
import { useGetParamsData } from 'src/containers/MainPageApartmentsV2/hooks';
import { CreatePdf } from 'src/components/ApartmentPageV2/TabComponent/components/CreatePdf';
import { useStyles } from 'src/components/ApartmentPageV2/ApartmentTitle/styles';
import { getValueByPlacementSystemName } from 'src/helpers/placementSystemName';
import { SvgHeart } from 'src/components/ApartmentPageV2/LayoutApartmentItem/components/SvgHeart';
import { PDFDownload } from 'src/components/modals/PDFDownload';
import { useGetMappedPDFPaymentPlans } from 'src/hooks/useGetMappedPDFPaymentPlans';
import { UtilityButtonTypeEnum } from 'src/typings/viewConfig';
import { useDisplayApartmentIds } from 'src/hooks/useDisplayApartmentIds';
import { locals } from 'src/constants';

export const ApartmentTitleMobile: React.FC<IApartmentRequest> = forwardRef(({
  showTitle,
  viewConfig,
  showCallToActionBtn,
  showRedirectBtn,
  placeInfo,
  ...props
}, ref) => {
  const styles = useStyles({});
  const appHeaderStyles = headerStyles({});
  const { displayIds } = useDisplayApartmentIds();
  const {
    chooseData,
  } = useGetParamsData();
  const {
    isActive,
    toggleFavoriteItem,
  } = useFavoriteListState(displayIds[0]);
  const { isCrossTable } = useBuildType();
  const { numberSeparator, replaceFraction } = UseNumberFormat();
  const { mappedPDFPaymentPlans, getMappedPDFPaymentPlans } = useGetMappedPDFPaymentPlans();
  const [openPDFPaymentPlansModal, setOpenPDFPaymentPlansModal] = useState<boolean>(false);
  const [selectedPDFPaymentPlan, setSelectedPDFPaymentPlan] = useState<any>(null);

  useEffect(() => {
    if (viewConfig) {
      getMappedPDFPaymentPlans(displayIds[0], isCrossTable ? 'crosstable' : 'module');
    }
  }, [displayIds[0], viewConfig]);

  return (
    <Box
      ref={ref}
      className={styles.apartmentMobile}
    >
      {showTitle && (
        <>
          <Box
            className={styles.apartmentTitle}
            style={{
              color: viewConfig?.headerSection?.mainHeader.styles.fontColor,
              fontWeight: viewConfig?.headerSection?.mainHeader.styles.fontWeight,
            }}
          >
            { viewConfig?.headerSection?.mainHeader.propertySettings.map((setting, index) => {
              const value = getValueByPlacementSystemName(viewConfig?.placement, setting.placementProperty.systemName, { lang: viewConfig?.lang });
              return (
                <Box
                  key={setting.id}
                  dangerouslySetInnerHTML={{
                    __html: `${(index > 0) ? '&nbsp;<span>•</span>&nbsp;' : ''}
                    ${setting.prefix ? setting.prefix[viewConfig.lang] || '' : ''}
                    ${numberSeparator(replaceFraction(String(value)), setting.placementProperty.systemName)}
                    ${setting.suffix ? setting.suffix[viewConfig.lang] || '' : ''}`,
                  }}
                />
              );
            })}
          </Box>
          <Box
            className={styles.apartmentPriceHolder}
          >
            {viewConfig?.headerSection?.subHeader.propertySettings.map((setting) => {
              const value = getValueByPlacementSystemName(viewConfig?.placement, setting.placementProperty.systemName, { lang: viewConfig?.lang });
              return (
                <Box
                  key={setting.id}
                  style={{
                    color: setting.styles.fontColor,
                    fontWeight: setting.styles.fontWeight,
                    textDecorationLine: setting.styles.fontDecoration,
                  }}
                  className={styles.apartmentPrice}
                  dangerouslySetInnerHTML={{
                    __html: `${setting.prefix ? setting.prefix[viewConfig.lang] || '' : ''}
                    ${numberSeparator(replaceFraction(String(value)), setting.placementProperty.systemName)}
                    ${setting.suffix ? setting.suffix[viewConfig.lang] || '' : ''}`,
                  }}
                />
              );
            })}
          </Box>
        </>
      )}
      <Box className={styles.buttonsHolder}>
        <div className={`${styles.buttonMobile} ${styles.pdfButton}`}>
          <CreatePdf
            id={displayIds[0]}
            placement={chooseData}
            isPaymentPlanBtn
            selectedPaymentPlans={selectedPDFPaymentPlan}
            openPaymentPlansModal={(state) => {
              setOpenPDFPaymentPlansModal(state);
            }}
            isLoading={(loading) => {
              if (!loading) {
                setSelectedPDFPaymentPlan(null);
              }
            }}
          />
        </div>
        <ButtonApp
          startIcon={<SvgHeart width="24" height="24" />}
          className={`${styles.buttonMobile} ${styles.favoriteButton} ${isActive ? 'favoriteButtonActive' : ''}`}
          onClick={toggleFavoriteItem}
        />
        <div
          className={styles.buttonMobileWrapper}
        >
          {viewConfig?.headerSection && showCallToActionBtn && (
            <ApartmentTitleButton
              title={locals.appTranslations.appRequestACall}
              sx={{
                backgroundColor: viewConfig?.headerSection?.callToAction.styles.backgroundColor,
                color: viewConfig?.headerSection?.callToAction.styles.fontColor,
                fontWeight: viewConfig?.headerSection?.callToAction.styles.fontWeight,
              }}
            />
          )}
          {viewConfig?.headerSection && showRedirectBtn && (
            <ApartmentTitleButton
              title={viewConfig?.headerSection?.unitPageRedirect.name?.translates?.[viewConfig?.lang]
                || viewConfig?.headerSection?.unitPageRedirect.name?.systemName || 'More details'}
              sx={{
                backgroundColor: viewConfig?.headerSection?.unitPageRedirect.styles.backgroundColor,
                color: viewConfig?.headerSection?.unitPageRedirect.styles.fontColor,
                fontWeight: viewConfig?.headerSection?.unitPageRedirect.styles.fontWeight,
              }}
              onClickEvent={() => {
                if (viewConfig?.applicationProperties) {
                  const foundProp = viewConfig.placementProperties
                    .find((prop) => prop.systemName === viewConfig.headerSection.unitPageRedirect.placementProperty?.systemName);
                  // @ts-ignore
                  if (foundProp?.oldSystemName && placeInfo?.[foundProp.oldSystemName]) {
                    // @ts-ignore
                    window.open(placeInfo?.[foundProp.oldSystemName], '_blank');
                  }
                }
              }}
            />
          )}
        </div>
      </Box>
      {Boolean(mappedPDFPaymentPlans.length) && (
        <PDFDownload
          open={openPDFPaymentPlansModal}
          onClose={() => setOpenPDFPaymentPlansModal(false)}
          isLoading={selectedPDFPaymentPlan !== null}
          plansList={mappedPDFPaymentPlans}
          onSelectPlan={setSelectedPDFPaymentPlan}
        />
      )}
    </Box>
  );
});
