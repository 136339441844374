import React from 'react';
import { MarketplaceHeader } from 'src/components/Marketplace/components/MarketplaceHeader';
import { MarketplaceFilterHeader } from 'src/components/Marketplace/components/MarketplaceFilterHeader';
import { MarketplaceFilterOptions } from 'src/components/Marketplace/components/MarketplaceFilterOptions';
import { MarketplaceFilterResults } from 'src/components/Marketplace/components/MarketplaceFilterResults';
import { useSelector } from 'react-redux';
import { getMarketplaceIsLoading } from 'src/store/marketplace/selectors';
import { useStyles } from './styles';

export const Marketplace: React.FC = () => {
  const isLoading = useSelector(getMarketplaceIsLoading);
  const styles = useStyles();
  return (
    <div className={`${styles.wrapper} ${isLoading ? 'loading' : ''}`}>
      <MarketplaceHeader />
      <MarketplaceFilterHeader />
      <MarketplaceFilterOptions />
      <MarketplaceFilterResults />
    </div>
  );
};
