import { getPlacementProperties, getPlacementTypesByComplex } from 'src/api';
import { useBuildType } from 'src/hooks/_use_build_type';
import { useQuery } from '@tanstack/react-query';

import { useEffect, useState } from 'react';
import { IPlacementProperty, IPropertySettings } from 'src/typings/viewConfig';
import { useGetAuthData } from 'src/hooks/use-get-auth-data';
import { useGetTypeOfProperty } from 'src/hooks/use-get-type-of-property';
import { useLanguageState } from 'src/hooks/useLanguageState';
import { IFrontConfigBase, ISubView, IView } from 'src/store/type-displayed-spinner/types';
import { IFrontConfigTypeEnum } from 'src/typings/table';
import { useSelector } from 'react-redux';
import { getComplexId } from '../../store/app/selectors';
import { QUERY_KEYS } from '../../constants/queryKeys';

export const usePlacementTypeViews = () => {
  const { getJWT } = useGetAuthData();
  const jwt = getJWT();
  const { typeOfProperty } = useGetTypeOfProperty();
  const { currentAppLanguage } = useLanguageState();
  const { isCrossTable } = useBuildType();
  const viewType = isCrossTable ? IFrontConfigTypeEnum.adminPanel : IFrontConfigTypeEnum.website;
  const [currentConfig, setCurrentConfig] = useState<IFrontConfigBase>();
  const [currentView, setCurrentView] = useState<IView | undefined>();
  const [currentListSubView, setCurrentListSubView] = useState<ISubView | undefined>();
  const [enabledSubViewsCount, setEnabledSubViewsCount] = useState<number>();
  const [currentPropertySettings, setCurrentPropertySettings] = useState<IPropertySettings[] | undefined>();
  const [complexPlacementProperties, setComplexPlacementProperties] = useState<IPlacementProperty[]>();
  const complexId = useSelector(getComplexId);

  const { data: placementTypes, isLoading: placementTypesLoading, error: placementTypesError } = useQuery({
    queryKey: [QUERY_KEYS.PLACEMENT_TYPES, complexId],
    queryFn: async () => {
      const { data } = await getPlacementTypesByComplex();
      return data['hydra:member'];
    },
    enabled: !!typeOfProperty && !!complexId,
  });

  useEffect(() => {
    if (placementTypes) {
      const current = placementTypes.find((item) => item.title[currentAppLanguage] === typeOfProperty);
      setCurrentConfig(current?.frontConfig[viewType]);
    }
  }, [placementTypes]);

  const { data: placementProperties, isLoading: placementPropertiesLoading, error: placementPropertiesError } = useQuery({
    queryKey: [QUERY_KEYS.PLACEMENT_PROPERTIES, complexId],
    queryFn: async () => {
      const { data } = await getPlacementProperties(jwt);
      return data['hydra:member'];
    },
    enabled: !!currentPropertySettings && !!jwt && !!complexId,
  });

  useEffect(() => {
    if (placementProperties) {
      setComplexPlacementProperties(placementProperties);
    }
  }, [placementProperties]);

  useEffect(() => {
    if (currentConfig) {
      const currentViewTemp = getCrosstableView();
      setCurrentView(currentViewTemp);
      const currentSubViewTemp = getListSubView();
      setCurrentListSubView(currentSubViewTemp);
      setEnabledSubViewsCount(currentSubViewTemp?.placementReportSetting.exportButtons.filter((button) => button.isEnabled).length);
      setCurrentPropertySettings(currentSubViewTemp?.propertySettings);
    }
  }, [currentConfig]);

  const getCrosstableView = (): IView | undefined => {
    return currentConfig?.views.find((view) => view.type === 'Crosstable');
  };

  const getListSubView = (): ISubView | undefined => {
    return getCrosstableView()?.subViews?.find((sub) => sub.type === 'ListSubView');
  };

  return {
    currentConfig,
    currentView,
    currentListSubView,
    currentPropertySettings,
    complexPlacementProperties,
    viewType,
    enabledSubViewsCount,
    isLoading: placementPropertiesLoading || placementTypesLoading,
    error: placementPropertiesError || placementTypesError,
  };
};
