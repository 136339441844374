/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import PhotoSizeSelectActualOutlinedIcon from '@mui/icons-material/PhotoSizeSelectActualOutlined';
import { requestSelectors } from 'src/store/requests/selectors';
import { TypeOfRole } from 'src/typings/user';
import { IComplex } from 'src/typings/complexes';
import { useStyles } from './styles';

export const ComplexItem: React.FC<{complex: IComplex}> = ({ complex }) => {
  const styles = useStyles({});
  const history = useHistory();
  const user = useSelector(requestSelectors.user.getUserData);
  const {
    id,
    language,
    developers,
    logoFileUri,
    previewFileUri,
    title,
  } = complex;

  const changeId = () => {
    history.push(`/${id}/`);
  };

  const goToEditComplex = () => {
    history.push(`/profile/complexes/${id}`);
  };

  return (
    <Box position="relative" className={styles.complexItemWrapper}>
      {user?.role?.['@id'] === TypeOfRole.manager ? null : (
        <IconButton
          color="primary"
          aria-label="edit button"
          component="div"
          className={styles.editBtn}
          onClick={goToEditComplex}
          size="large"
        >
          <EditRoundedIcon />
        </IconButton>
      )}
      <Box
        borderRadius="5px"
        pt="85%"
        mb={1}
        position="relative"
        overflow="hidden"
        className={styles.previewWrap}
        style={{
          backgroundImage: `url(${previewFileUri?.fileUrl || ''})`,
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
        }}
        onClick={changeId}
      >

        {!previewFileUri?.fileUrl && (
          <Skeleton className={styles.preview} variant="rectangular" width="100%" />
        )}
      </Box>
      <Box mb={3}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Box className={styles.logoWrapper}>
              {logoFileUri ? (
                <img src={logoFileUri.fileUrl} className={styles.logo} alt="" />
              ) : (
                <PhotoSizeSelectActualOutlinedIcon fontSize="inherit" />
              )}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className={styles.logoWrapper}>
              {(developers[0] && developers[0].logoFileUri?.fileUrl) ? (
                <img src={developers[0].logoFileUri?.fileUrl} className={styles.logo} alt="" />
              ) : (
                <PhotoSizeSelectActualOutlinedIcon fontSize="inherit" />
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box onClick={changeId} className={styles.title}>
        {title[language]}
      </Box>
    </Box>
  );
};
