import { useEffect, useState } from 'react';

import { IFilterAllData, ListOfKeysFilterAllData } from 'src/typings/filter';
import _ from 'lodash';
import { IFillFloorsData } from '../types';

export const useSectionGenerator = (
  floorList: string[],
  sectionData: IFilterAllData[],
) => {
  const [isSetNewData, setNewData] = useState<boolean>(false);
  const [fillFloors, setFillFloors] = useState<IFillFloorsData | null>(null);

  useEffect(() => {
    setFillFloors(floorList.reduce((acc, cur) => {
      return {
        ...acc,
        [cur]: [],
      };
    }, {} as IFillFloorsData));
    setNewData(true);
  }, [floorList]);

  useEffect(() => {
    if (isSetNewData && sectionData) {
      const combineData: IFillFloorsData = sectionData.reduce((acc, cur) => {
        const data = { ...acc };
        if (cur[ListOfKeysFilterAllData.placementFloor]) {
          cur[ListOfKeysFilterAllData.placementFloor].forEach((floor, index) => {
            if (data[floor]) {
              if (index) {
                data[floor] = [...data[floor], null];
              } else {
                data[floor] = [...data[floor], cur];
              }
            }
          });
        }

        return data;
      }, fillFloors as IFillFloorsData);

      const result = {};
      Object.keys(combineData).forEach((key) => {
        const floorArr = combineData[key];
        if (floorArr && floorArr.every((item) => item === null || _.isFinite(Number(item[ListOfKeysFilterAllData.placementAdditionalCondition])))) {
          const arrResult = Array(floorArr.length).fill(null);

          floorArr.forEach((item) => {
            if (item && _.isFinite(Number(item[ListOfKeysFilterAllData.placementAdditionalCondition]))) {
              const resultIndex = Number(item[ListOfKeysFilterAllData.placementAdditionalCondition]);
              arrResult[resultIndex - 1] = item;
            }
          });
          // @ts-ignore
          result[key] = arrResult;
        } else {
          // @ts-ignore
          result[key] = floorArr;
        }
      });
      setFillFloors(result);
      setNewData(false);
    }
  }, [fillFloors, sectionData, isSetNewData]);

  return {
    fillFloors,
  };
};
