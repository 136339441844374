import { IAction } from 'src/typings';
import {
  IGeneralUnitInfo,
  IMarketplace, IMarketplaceRangeRequestFilter,
  IMarketplaceRequestFilter, IViewTile, IViewTileType, IWhereConditions, IWhereConditionsRange,
} from 'src/components/profileComponents/Marketplace/types';
import {
  IMarketplaceFilterResponseFilter,
} from 'src/typings/marketplace';
import { IComplex } from 'src/typings/complexes';
import { IPlacement } from 'src/store/placement/reducer';
import { replaceObjectProperty } from 'src/components/Marketplace/helpers/replaceObjectProperty';
import _ from 'lodash';
import { ActionTypes } from './actions';

export interface IMarketplaceReducer {
  currentMarketplace: IMarketplace | undefined;
  language: string | undefined;
  filtersInitial: IMarketplaceFilterResponseFilter[];
  filters: IMarketplaceFilterResponseFilter[];
  filterWhereConditions: IWhereConditions[];
  activeFilter: number | undefined;
  viewTile: IViewTile;
  generalUnitInfo: IGeneralUnitInfo;
  complexesTile: IComplex[];
  placementsTile: IPlacement[];
  linkParams: IMarketplaceRequestFilter[];
  isLoading: boolean;
}

export const initialState: IMarketplaceReducer = {
  currentMarketplace: undefined,
  language: undefined,
  filtersInitial: [],
  filters: [],
  filterWhereConditions: [],
  activeFilter: undefined,
  viewTile: {
    type: IViewTileType.Complexes,
    pageNumber: 0,
  },
  generalUnitInfo: {
    complexesTotalCount: 0,
    placementsTotalCount: 0,
  },
  complexesTile: [],
  placementsTile: [],
  linkParams: [],
  isLoading: false,
};

export const marketplaceReducer = (
  state = initialState,
  { type, payload }: IAction,
): IMarketplaceReducer => {
  switch (type) {
    case ActionTypes.SET_MARKETPLACE: {
      return {
        ...state,
        currentMarketplace: payload,
      };
    }
    case ActionTypes.SET_MARKETPLACE_LANGUAGE: {
      return {
        ...state,
        language: payload,
      };
    }
    case ActionTypes.SET_MARKETPLACE_FILTERS_INITIAL: {
      return {
        ...state,
        filtersInitial: payload,
      };
    }
    case ActionTypes.SET_MARKETPLACE_FILTERS: {
      return {
        ...state,
        filters: payload,
      };
    }
    case ActionTypes.SET_MARKETPLACE_RESET_ALL_FILTERS_WHERE_CONDITIONS: {
      return {
        ...state,
        filterWhereConditions: [],
      };
    }
    case ActionTypes.SET_MARKETPLACE_RESET_FILTERS_WHERE_CONDITIONS: {
      let data = [...state.filterWhereConditions];
      data = data.filter((item) => !payload.includes(item.propertyName));
      return {
        ...state,
        filterWhereConditions: data,
      };
    }
    case ActionTypes.SET_MARKETPLACE_FILTERS_WHERE_CONDITIONS: {
      const foundCondition = [{ ..._.omit<IWhereConditions>((payload[0] as IMarketplaceRequestFilter), 'oldSystemName') }];
      const find = [...state.filterWhereConditions]
        .filter((item) => {
          foundCondition
            .find((p) => {
              if ('equals' in p) {
                return JSON.stringify(p) === JSON.stringify(item);
              }
              if (('min' && 'max') in p) {
                return p.propertyName === item.propertyName;
              }
              return false;
            });
          if (!foundCondition) return false;
          const isEqual = JSON.stringify(item) === JSON.stringify(foundCondition[0]);
          const isEqualPropertyName = JSON.stringify(item.propertyName) === JSON.stringify(foundCondition[0].propertyName);
          const isRangePropertyName = JSON.stringify(item.propertyName) === JSON.stringify(foundCondition[0].propertyName)
              && (item as IWhereConditionsRange).min !== undefined
              && (item as IWhereConditionsRange).max !== undefined
              && item as IWhereConditionsRange;
          return isEqual
              || (isEqual && isEqualPropertyName)
              || (!isEqual && isRangePropertyName);
        });

      let data = [...state.filterWhereConditions];
      let linkParams = [...state.linkParams];
      const deleteItem = (item: IWhereConditions[]) => {
        const temp = [...state.filterWhereConditions];
        const tempLinkParams = [...state.linkParams];
        item.forEach((request) => {
          const index = temp.indexOf(request);
          if ((request as IWhereConditionsRange).min !== undefined && (request as IWhereConditionsRange).max !== undefined) {
            temp.splice(index, 1, payload[0]);
            tempLinkParams.splice(index, 1, payload[0]);
          } else {
            temp.splice(index, 1);
            tempLinkParams.splice(index, 1);
          }
        });
        return {
          whereConditions: temp,
          linkParams: tempLinkParams,
        };
      };
      if (find.length) {
        data = deleteItem(find).whereConditions;
        linkParams = deleteItem(find).linkParams;
      } else {
        data = [...state.filterWhereConditions, ...payload];
        linkParams = [...state.linkParams, ...payload];
      }
      return {
        ...state,
        filterWhereConditions: replaceObjectProperty([...data], ['oldSystemName']),
        linkParams,
      };
    }
    case ActionTypes.SET_MARKETPLACE_COMPLEXES_TILE: {
      return {
        ...state,
        complexesTile: payload,
      };
    }
    case ActionTypes.SET_MARKETPLACE_PLACEMENTS_TILE: {
      return {
        ...state,
        placementsTile: payload,
      };
    }
    case ActionTypes.SET_MARKETPLACE_ACTIVE_FILTER: {
      return {
        ...state,
        activeFilter: payload,
      };
    }

    case ActionTypes.SET_MARKETPLACE_VIEW_TILE: {
      return {
        ...state,
        viewTile: payload,
      };
    }

    case ActionTypes.SET_MARKETPLACE_GENERAL_UNIT_INFO: {
      return {
        ...state,
        generalUnitInfo: payload,
      };
    }

    case ActionTypes.SET_MARKETPLACE_IS_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }

    default:
      return state;
  }
};
